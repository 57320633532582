body {
  font-family: "Montserrat",Sans-serif;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-width: 320px;
  background: #eef2f8;
  color: #495463
}

img {
  max-width: 100%
}

h1,h2,h3,h4,h5,h6,p,ul:not([class]),ol,table {
  margin-bottom: 12px
}

h1:last-child,h2:last-child,h3:last-child,h4:last-child,h5:last-child,h6:last-child,p:last-child,ul:not([class]):last-child,ol:last-child,table:last-child {
  margin-bottom: 0
}

h1,h2,h3,h4,h5,h6,label {
  font-weight: 400;
  line-height: 1.3
}

h1,h2,h4,h5 {
  color: #342d6e
}

h1,.h1 {
  font-size: 1.37em
}

h2,.h2 {
  font-size: 1.3em
}

h3,.h3 {
  font-size: 1.2em
}

h4,.h4 {
  font-size: 1.15em
}

h5,.h5 {
  font-size: 1.07em
}

h6,.h6 {
  font-size: .93em
}

p {
  font-size: 1em
}

p.lead {
  font-size: 1.1em;
  line-height: 1.5
}

p.lead-lg {
  font-size: 1.15em;
  line-height: 1.5
}

p.large {
  font-size: 1.1em
}

@media (min-width: 576px) {
  h1,.h1 {
      font-size:1.714em
  }

  h2,.h2 {
      font-size: 1.62em
  }

  h3,.h3 {
      font-size: 1.52em
  }

  h4,.h4 {
      font-size: 1.29em
  }

  p.lead {
      font-size: 1.15em
  }

  p.lead-lg {
      font-size: 1.25em
  }

  p.large {
      font-size: 1.2em
  }
}

@media (min-width: 768px) {
  body {
      font-size:15px
  }
}

h1 span,h2 span,h3 span,h4 span,h5 span,h6 span,p span {
  color: #00d285
}

ul,ol {
  padding: 0px;
  margin: 0px
}

ul li,ol li {
  list-style: none
}

.relative {
  position: relative
}

p+h1,p+h2,p+h3,p+h4,p+h5,p+h4,ul+h1,ul+h2,ul+h3,ul+h4,ul+h5,ul+h4,ol+h1,ol+h2,ol+h3,ol+h4,ol+h5,ol+h4,table+h1,table+h2,table+h3,table+h4,table+h5,table+h4 {
  margin-top: 30px
}

ul+p:not([class]),ul+ul:not([class]),ul+ol:not([class]),ol+ol:not([class]),ol+ul:not([class]),ul+table:not([class]),ol+table:not([class]) {
  margin-top: 35px
}

/* b,strong {
  font-weight: 500
} */

blockquote {
  font-size: 1.3em;
  background: #eef2f8;
  padding: 20px 30px;
  font-style: italic
}

a {
  outline: 0;
  transition: all 0.5s;
  color: #00d285
}

a:link,a:visited {
  text-decoration: none
}

a:hover,a:focus,a:active {
  outline: 0;
  color: #342d6e
}

p a {
  color: #00d285
}

p a:hover,p a:focus {
  color: #342d6e;
  box-shadow: 0 1px 0 currentColor
}

.datepicker-dropdown:before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
  border-bottom: 6px solid #b1becc
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-top: 6px solid #b1becc
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 7px
}

.datepicker-switch {
  padding: 6px 0;
  font-weight: 500;
  transition: all .4s
}

.datepicker.dropdown-menu {
  border: none;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  padding: 10px;
  border: 1px solid #b1becc;
  min-width: 250px
}

.datepicker.dropdown-menu td {
  padding: 5px;
  min-width: 32px;
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto",sans-serif
}

.datepicker table {
  width: 100%
}

.datepicker table thead tr th {
  padding: 6px 0;
  font-size: 13px;
  font-weight: 500;
  transition: all .4s;
  color: #758698
}

.datepicker table thead tr th:hover {
  background: #eaf3fc !important
}

.datepicker table thead tr th.dow {
  color: #495463;
  font-size: 11px;
  padding: 2px 0;
  text-transform: uppercase
}

.datepicker table tfoot tr th {
  padding: 6px 0;
  font-weight: 500;
  transition: all .4s;
  color: #3c3c3c;
  text-transform: uppercase;
  font-size: 11px
}

.datepicker table tfoot tr th:hover {
  background: #eaf3fc !important
}

.datepicker table tr th {
  transition: all .4s
}

.datepicker table tr td {
  transition: all .4s;
  color: #8599c6
}

.datepicker table tr td.day:hover {
  background: #eaf3fc
}

.datepicker table tr td.old,.datepicker table tr td.new {
  color: rgba(133,153,198,0.4)
}

.datepicker table tr td.old.year,.datepicker table tr td.new.year {
  color: inherit
}

.datepicker table tr td.today,.datepicker table tr td.today.range {
  background: rgba(52,45,110,0.3);
  color: #495463
}

.datepicker table tr td.range {
  background: #eaf3fc
}

.datepicker table tr td.range-start {
  border-radius: 4px 0 0 4px
}

.datepicker table tr td.range-end {
  border-radius: 0 4px 4px 0
}

.datepicker table tr td.range-start.range-end {
  border-radius: 4px
}

.datepicker table tr td span {
  transition: all .4s
}

.datepicker table tr td span:hover,.datepicker table tr td span.focused {
  background: #eaf3fc
}

.datepicker table tr td.today:hover:hover,.datepicker table tr td.today.range:hover:hover,.datepicker table tr td.range:hover,.datepicker table tr td.selected,.datepicker table tr td.selected.disabled,.datepicker table tr td.selected.disabled:hover,.datepicker table tr td.selected:hover,.datepicker table tr td.selected:hover:hover,.datepicker table tr td.active.active,.datepicker table tr td.active.active:hover:hover,.datepicker table tr td span.active.active,.datepicker table tr td span.active.active:hover:hover {
  background: #00d285;
  color: #fff
}

.ui-timepicker {
  text-align: left
}

.ui-timepicker-container {
  font-family: "Roboto",sans-serif;
  font-size: 1em;
  border: 1px solid #b1becc;
  border-top: none;
  border-radius: 0 0 4px 4px
}

.ui-timepicker-container .ui-state-hover {
  background: rgba(230,239,251,0.3);
  border: none;
  font-weight: 400;
  border-radius: 4px
}

.ui-timepicker-container li a {
  padding: 3px 12px;
  font-size: 13px;
  color: #495463 !important;
  transition: none;
  border: none;
  cursor: pointer;
  font-weight: 400
}

.modal .has-timepicker {
  z-index: 1099
}

.has-timepicker .time-picker:focus {
  border-radius: 4px 4px 0 0
}

.input-daterange input {
  text-align: left
}

.input-daterange input:last-child {
  border-radius: 4px
}

.date-picker-range {
  position: relative
}

.date-picker,.time-picker {
  font-size: 12px
}

@media (min-width: 480px) {
  .date-picker,.time-picker {
      font-size:14px
  }

  .datepicker.dropdown-menu {
      min-width: 270px
  }
}

.dz-clickable {
  border: 1px dashed #b1becc;
  background: #eef2f8;
  border-radius: 4px;
  padding: 25px 0 20px;
  text-align: center
}

.dz-sm {
  padding: 40px 0 20px
}

.dz-message {
  padding-bottom: 15px
}

.dz-message span {
  display: block;
  color: rgba(117,134,152,0.6)
}

.dz-message-text {
  font-size: 13px
}

.dz-message-or {
  font-size: 16px;
  margin-bottom: 4px;
  text-transform: uppercase
}

.dz-sm .dz-message {
  padding-bottom: 20px
}

.dz-preview {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  width: 120px;
  display: inline-block
}

.dz-image img {
  border-radius: 4px;
  border: 1px solid #d3e0f3
}

.dz-filename {
  font-size: 13px
}

.dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 10px;
  top: 55px;
  left: 50%;
  width: 80px;
  transform: translateX(-50%);
  background: rgba(255,255,255,0.9);
  border-radius: 5px;
  overflow: hidden;
  transition: all .4s
}

.dz-complete .dz-progress {
  opacity: 0
}

.dz-upload {
  background: #333;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  transition: all .3s ease-in-out
}

.dz-error-message,.dz-success-message {
  font-size: 13px
}

.dz-error-mark,.dz-success-mark {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: none
}

.dz-error-mark svg,.dz-success-mark svg {
  height: 40px !important;
  width: 40px !important
}

.dz-error-mark svg g,.dz-success-mark svg g {
  stroke-opacity: .7;
  stroke-width: 2
}

.dz-error-message {
  color: #ff6868
}

.dz-error-mark g {
  stroke: rgba(255,104,104,0.7) !important
}

.dz-error .dz-error-mark {
  display: block
}

.dz-success-message {
  color: #00d285
}

.dz-success-mark g {
  stroke: rgba(0,210,133,0.7) !important
}

.dz-success .dz-success-mark {
  display: block
}

#toast-container {
  position: fixed;
  z-index: 999999;
  margin-top: 22px;
  margin-bottom: 16px
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%
}

.toast-top-left {
  top: 0;
  left: 16px
}

.toast-top-right {
  top: 0;
  right: 16px
}

.toast-bottom-right {
  right: 16px;
  bottom: 0
}

.toast-bottom-left {
  bottom: 0;
  left: 16px
}

.toast-top-center>div,.toast-bottom-center>div {
  width: 650px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto
}

.toast-top-full-width>div,.toast-bottom-full-width>div {
  width: 96%;
  margin-left: auto;
  margin-right: auto
}

.toast {
  background: #fff;
  color: #fff;
  overflow: hidden;
  margin: 0 0 8px;
  padding: 15px 51px 15px 15px;
  width: 300px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 18px 2px rgba(73,84,99,0.25);
  background: #090d1c;
  position: relative
}

.toast-close-button {
  cursor: pointer;
  position: absolute;
  text-align: left;
  right: 15px;
  text-indent: -9999em;
  overflow: hidden;
  background: none;
  border-radius: 50%;
  border: none;
  height: 36px;
  width: 36px;
  transition: all .3s
}

.toast-close-button:after {
  position: absolute;
  content: '\e646';
  font-family: 'themify';
  top: 0;
  right: -1px;
  text-indent: 0;
  display: block;
  font-size: 13px;
  line-height: 38px;
  height: 36px;
  width: 36px;
  text-align: center;
  color: #fff;
  transition: all .3s
}

.toast-close-button:hover,.toast-close-button:focus {
  background: rgba(238,242,248,0.15);
  box-shadow: none;
  outline: none
}

.toast-message {
  position: relative;
  flex-grow: 1;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-height: 30px
}

.toast-message-icon {
  display: inline-block;
  font-size: 14px;
  margin-right: 15px;
  margin-left: -10px;
  text-align: center;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  flex-shrink: 0
}

.toast-message-icon.ti-alert:before {
  position: relative;
  top: -2px
}

.toast-info .toast-message-icon {
  background: #1babfe
}

.toast-warning .toast-message-icon {
  background: #ffc100
}

.toast-success .toast-message-icon {
  background: #00d285
}

.toast-error .toast-message-icon {
  background: #ff6868
}

@media (min-width: 576px) {
  .toast-message {
      font-size:14px;
      line-height: 18px
  }
}

.swal-footer {
  text-align: center;
  padding: 13px 16px 40px
}

.swal-text {
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  line-height: 1.8
}

.swal-icon:first-child {
  margin-top: 45px
}

.swal-icon--success__ring {
  border-color: rgba(0,210,133,0.4)
}

.swal-icon--success__line {
  background: #00d285
}

.swal-icon--info {
  border-color: rgba(27,171,254,0.4)
}

.swal-icon--info:before,.swal-icon--info:after {
  background: #1babfe
}

.swal-icon--warning {
  border-color: rgba(255,193,0,0.2)
}

.swal-icon--warning__body,.swal-icon--warning__dot {
  background: #ffc100
}

.swal-icon--error {
  border-color: rgba(255,104,104,0.4)
}

.swal-icon--error__line {
  background: #ff6868
}

.swal-title {
  color: #342d6e;
  font-size: 24px
}

.swal-content {
  padding: 0 45px
}

.swal-content__input {
  border-radius: 4px;
  border: 1px solid #d2dde9;
  width: 100%;
  padding: 10px 6px;
  line-height: 20px;
  font-size: .9em;
  color: rgba(73,84,99,0.7);
  transition: all .4s
}

.swal-content__input::-webkit-input-placeholder {
  color: #758698
}

.swal-content__input::-moz-placeholder {
  color: #758698
}

.swal-content__input:-ms-input-placeholder {
  color: #758698
}

.swal-content__input:-moz-placeholder {
  color: #758698
}

.swal-content__input:focus {
  box-shadow: none;
  outline: none;
  border-color: #b1becc
}

.swal-button {
  line-height: 24px;
  padding: 9px 24px;
  transition: all .4s ease;
  background: #00d285
}

.swal-button:not([disabled]):hover {
  background: #3f2bfe
}

.swal-button--confirm {
  background: #00d285
}

.swal-button--confirm:not([disabled]):hover {
  background: #00b975
}

.swal-button--confirm.danger {
  background: #ff6868
}

.swal-button--confirm.danger:not([disabled]):hover {
  background: #ff4f4f
}

.swal-button--cancel {
  color: #fff;
  background: #758698
}

.swal-button--cancel:not([disabled]):hover {
  background: #68798b
}

.swal-button:focus {
  box-shadow: none
}

@-webkit-keyframes pulseWarning {
  0% {
      border-color: rgba(255,193,0,0.2)
  }

  to {
      border-color: rgba(255,193,0,0.7)
  }
}

@keyframes pulseWarning {
  0% {
      border-color: rgba(255,193,0,0.2)
  }

  to {
      border-color: rgba(255,193,0,0.7)
  }
}

table.dataTable {
  width: 100%;
  overflow-x: scroll
}

table.dataTable th:before,table.dataTable th:after {
  display: none !important
}

table.dataTable th>span {
  position: relative;
  display: inline-block
}

table.dataTable th>span:before,table.dataTable th>span:after {
  position: absolute;
  top: 0;
  opacity: 0.3
}

table.dataTable th>span:before {
  content: '\2191';
  right: -20px
}

table.dataTable th>span:after {
  content: '\2193';
  right: -13px
}

table.dataTable th.sorting_asc span:before {
  opacity: 1
}

table.dataTable th.sorting_desc span:after {
  opacity: 1
}

table.dataTable.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.dataTables_filter label {
  margin-bottom: 0
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: center
}

div.dataTables_wrapper div.dataTables_filter label {
  width: 100%;
  position: relative
}

div.dataTables_wrapper div.dataTables_filter label:before {
  position: absolute;
  height: 36px;
  width: 30px;
  text-align: center;
  line-height: 36px;
  font-family: themify;
  content: '\e610';
  color: #abbbd9;
  font-size: 15px
}

div.dataTables_wrapper div.dataTables_filter input {
  width: 100%;
  padding: 8px 30px;
  line-height: 20px;
  font-size: 13px;
  border: none;
  background: transparent;
  height: auto
}

div.dataTables_wrapper div.dataTables_filter input:focus {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none
}

div.dataTables_wrapper div.dataTables_filter input:-moz-placeholder {
  opacity: 1;
  color: #abbbd9
}

div.dataTables_wrapper div.dataTables_filter input:-ms-input-placeholder {
  opacity: 1;
  color: #abbbd9
}

div.dataTables_wrapper div.dataTables_filter input::-webkit-input-placeholder {
  opacity: 1;
  color: #abbbd9
}

div.dataTables_wrapper div.dataTables_length {
  text-align: center
}

div.dataTables_wrapper div.dataTables_length label {
  color: #8599c7
}

div.dataTables_wrapper div.dataTables_length select {
  margin-left: 10px
}

div.dataTables_wrapper div.dataTables_info {
  color: #23406c;
  padding: 8px 0 0;
  text-align: left;
  font-size: 11px
}

div.dataTables_wrapper div.dataTables_paginate {
  padding: 20px 0 0
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: flex-start
}

@media (min-width: 576px) {
  div.dataTables_wrapper div.dataTables_filter {
      text-align:left
  }

  div.dataTables_wrapper div.dataTables_filter input {
      text-align: left
  }

  div.dataTables_wrapper div.dataTables_length {
      text-align: right
  }
}

@media (min-width: 768px) {
  div.dataTables_wrapper div.dataTables_info {
      text-align:right;
      padding: 22px 0 0
  }
}

.data-table {
  width: 100%
}

.data-table th.dataTables_empty,.data-table td.dataTables_empty {
  background: #fff;
  border-radius: 4px !important;
  padding: 45px 0 15px
}

.data-table-fullwidth .table-wrap {
  width: 100%;
  overflow-x: auto
}

.data-table-fullwidth .data-col {
  min-width: 100px
}

.data-table-area {
  position: relative
}

.data-table-filter {
  position: absolute;
  right: 0;
  top: 3px;
  z-index: 11
}

.data-col {
  padding: 14px 15px 14px 0;
  border-bottom: 1px solid #e6effb
}

.data-table-plain .data-col {
  padding: 14px 12px 14px 0
}

.data-table:not(.dt-init) .data-item:last-child .data-col {
  border-bottom: none;
  padding-bottom: 5px
}

.data-col:last-child {
  padding-right: 0
}

.data-col .lead {
  font-size: 12px;
  font-weight: 600;
  color: #495463;
  letter-spacing: 0.01em;
  line-height: 1.2;
  display: block;
  margin-top: 0
}

.data-col .sub {
  font-size: 10px;
  font-weight: 400;
  color: #758698;
  letter-spacing: 0.01em;
  line-height: 1.3;
  padding-top: 7px;
  display: block
}

.data-col .sub-s2 {
  font-size: 12px;
  color: #495463;
  padding-top: 0
}

.data-col .sub-s2.sub-time {
  font-size: 0.8em
}

.data-col .sub:first-child {
  padding-top: 0
}

.data-col .sub [data-toggle="tooltip"] {
  color: #758698;
  font-size: 11px
}

.data-col.dt-status {
  text-align: center
}

.data-col-wd-sm {
  width: 100px
}

.data-col-wd-md {
  width: 120px
}

.data-col-wd-lg {
  width: 180px
}

.data-col .text-wrap {
  width: 70px
}

.data-item {
  background: #fff;
  margin-bottom: 3px;
  border-radius: 4px;
  position: relative
}

.data-head .data-col {
  padding: 0 10px 0 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #00d285;
  text-transform: uppercase;
  border-bottom: none
}

.data-state {
  height: 30px;
  width: 30px;
  line-height: 28px;
  flex-shrink: 0;
  display: inline-block
}

.data-state:not(:last-child) {
  margin-right: 12px
}

.data-state:after {
  display: block;
  font-family: 'themify';
  color: #00d285;
  height: 100%;
  width: 100%;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid
}

.data-state-sm {
  height: 24px;
  width: 24px;
  line-height: 23px
}

.data-state-sm:after {
  font-size: 12px
}

.data-state-approved:after {
  content: '\e64c';
  color: #00d285;
  border-color: #00d285
}

.data-state-pending:after {
  content: '\e6c5';
  color: #ffc100;
  border-color: #ffc100
}

.data-state-canceled:after {
  content: '\e646';
  color: #ff6868;
  border-color: #ff6868
}

.data-state-progress:after {
  content: '\e6c6';
  color: #1babfe;
  border-color: #1babfe
}

.data-state-missing:after {
  content: '\e69c';
  color: #b1becc;
  border-color: #b1becc
}

.data-details {
  border-radius: 4px;
  padding: 18px 20px;
  border: 1px solid #d2dde9
}

.data-details>div {
  flex-grow: 1;
  margin-bottom: 18px
}

.data-details>div:last-child {
  margin-bottom: 0
}

.data-details-title {
  font-size: 14px;
  font-weight: 600;
  color: #758698;
  line-height: 20px;
  display: block
}

.data-details-info {
  font-size: 14px;
  font-weight: 400;
  color: #495463;
  line-height: 20px;
  display: block;
  margin-top: 6px
}

.data-details-info.large {
  font-size: 20px
}

.data-details-list {
  border-radius: 4px;
  border: 1px solid #d2dde9
}

.data-details-list li {
  display: block
}

.data-details-list li:last-child .data-details-des {
  border-bottom: none
}

.data-details-head {
  font-size: 13px;
  font-weight: 500;
  color: #758698;
  line-height: 20px;
  padding: 15px 20px 2px;
  width: 100%
}

.data-details-des {
  font-size: 14px;
  color: #495463;
  font-weight: 400;
  line-height: 20px;
  padding: 2px 20px 15px;
  flex-grow: 1;
  border-bottom: 1px solid #d2dde9;
  display: flex;
  justify-content: space-between
}

.data-details-des .ti:not([data-toggle="tooltip"]),.data-details-des [class*=fa]:not([data-toggle="tooltip"]) {
  color: #00d285
}

.data-details-des span:last-child:not(:first-child) {
  font-size: 12px;
  color: #758698
}

.data-details-des small {
  color: #758698
}

.data-details-des span,.data-details-des strong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.data-details-docs {
  border-top: 1px solid #d2dde9;
  margin-top: 12px
}

.data-details-docs-title {
  color: #495463;
  display: block;
  padding-bottom: 6px;
  font-weight: 400
}

.data-details-docs>li {
  flex-grow: 1;
  border-bottom: 1px solid #d2dde9;
  padding: 20px
}

.data-details-docs>li:last-child {
  border-bottom: none
}

.data-details-alt {
  border-radius: 4px;
  border: 1px solid #d2dde9;
  font-weight: 400
}

.data-details-alt li {
  line-height: 1.35;
  padding: 15px 20px;
  border-bottom: 1px solid #d2dde9
}

.data-details-alt li:last-child {
  border-bottom: none
}

.data-details-alt li div {
  padding: 3px 0
}

.data-details-date {
  display: block;
  padding-bottom: 4px
}

.data-doc-list {
  display: flex;
  margin: 0 -5px
}

.data-doc-list>div {
  margin: 0 5px
}

.data-doc-item {
  height: 54px;
  width: 70px;
  border-radius: 3px;
  overflow: hidden;
  position: relative
}

.data-doc-item:hover .data-doc-actions {
  opacity: 1
}

.data-doc-item-lg {
  width: 180px;
  height: 140px
}

.data-doc-item-lg .kyc-file {
  font-size: 110px;
  line-height: 140px
}

.data-doc-image {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.data-doc-image img {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  max-width: initial
}

.data-doc-actions {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all .3s
}

.data-doc-actions li {
  margin: 3px
}

.data-doc-actions li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 31px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  background: rgba(118,104,254,0.7)
}

.data-doc-actions li a:hover {
  background: rgba(118,104,254,0.9)
}

.data-vr-list {
  display: inline-flex;
  align-items: center
}

.data-vr-list li {
  display: inline-flex;
  align-items: center;
  margin-right: 18px;
  font-size: 12px;
  font-weight: 500
}

.data-vr-list li:last-child {
  margin-right: 0
}

.data-vr-list .data-state {
  margin-right: 9px
}

.data-action-list {
  display: inline-flex
}

.data-action-list li {
  padding: 0 5px
}

.data-action-list li:first-child {
  padding-left: 0
}

.data-action-list li:last-child {
  padding-right: 0
}

.data-action-dropdown {
  position: relative
}

@media (min-width: 576px) {
  .data-details-list>li {
      display:flex;
      align-items: center
  }

  .data-details-docs {
      width: calc(100% - 190px);
      border-top: none;
      margin-top: 0
  }

  .data-details-docs>li {
      border-left: 1px solid #d2dde9
  }

  .data-details-head {
      width: 190px;
      padding: 14px 20px
  }

  .data-details-des {
      border-top: none;
      border-left: 1px solid #d2dde9;
      width: calc(100% - 190px);
      padding: 14px 20px
  }

  .data-col .text-wrap {
      width: 140px
  }

  .data-col-wd-sm {
      width: 140px
  }

  .data-col-wd-md {
      width: 180px
  }

  .data-col-wd-lg {
      width: 220px
  }
}

@media (min-width: 768px) {
  .data-item .lead {
      font-size:14px;
      font-weight: 700;
      letter-spacing: 0.02em
  }

  .data-item .sub {
      font-size: 12px;
      letter-spacing: 0.02em;
      padding-top: 7px
  }

  .data-item .sub-s2 {
      font-size: 13px;
      color: #495463
  }

  .data-details-info {
      font-size: 13px
  }

  .data-details>div {
      margin-bottom: 0
  }

  .data-details-head {
      font-size: 14px
  }

  .data-details-docs>li {
      width: 100%
  }

  .data-col .text-wrap {
      width: 150px
  }

  .data-col-wd-sm {
      width: 120px
  }

  .data-col-wd-md {
      width: 160px
  }

  .data-col-wd-lg {
      width: 200px
  }
}

@media (min-width: 992px) {
  .data-details-date {
      padding-bottom:0
  }

  .data-details-docs {
      display: flex
  }

  .data-details-docs>li {
      width: 33%;
      border: none;
      border-left: 1px solid #d2dde9
  }

  .data-col .text-wrap {
      width: 190px
  }

  .data-col-wd-sm {
      width: 140px
  }

  .data-col-wd-md {
      width: 180px
  }

  .data-col-wd-lg {
      width: 220px
  }
}

.pagination {
  flex-wrap: wrap;
  margin: -8px
}

.pagination li {
  margin: 4px
}

.pagination li:last-child {
  margin-right: 0
}

.pagination li a,.pagination li span {
  display: inline-block;
  height: 30px;
  min-width: 30px;
  text-align: center;
  line-height: 14px;
  padding: 8px 0;
  border-radius: 4px;
  background: #eef2f8;
  border: none;
  color: #495463;
  font-weight: 500;
  font-size: 12px
}

.pagination li a .ti,.pagination li span .ti {
  position: relative;
  top: 1px
}

.pagination li.next a,.pagination li.previous a {
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400
}

.pagination li.disabled a.page-link {
  background: #f7fafd;
  color: #92a0ae
}

.pagination li a:hover,.pagination li a:focus {
  color: #fff;
  background: #00d285;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.07)
}

.pagination li.active span {
  color: #fff;
  background: #00d285
}

@media (min-width: 360px) {
  .pagination li a,.pagination li span {
      height:36px;
      min-width: 36px;
      line-height: 20px;
      padding: 8px 0
  }
}

@media (max-width: 767px) {
  .pagination {
      margin:-6px
  }

  .pagination li {
      margin: 3px
  }

  .pagination li.next,.pagination li.previous {
      order: 2
  }
}

.kyc-list .badge {
  min-width: 80px
}

.dt-docs a {
  font-size: 0.8em
}

.user-list .dt-email,.user-list .dt-verify,.user-list .dt-login,.user-list .dt-status-text,.user-list .dt-status-md,.user-tnx .dt-amount,.user-tnx .dt-usd-amount,.user-tnx .dt-base-amount,.user-tnx .dt-account,.user-tnx .dt-type-text,.user-tnx .dt-type-md,.kyc-list .dt-doc-type,.kyc-list .dt-doc-front,.kyc-list .dt-doc-back,.kyc-list .dt-status-sm,.kyc-list .dt-sbdate,.admin-tnx .dt-amount,.admin-tnx .dt-usd-amount,.admin-tnx .dt-base-amount,.admin-tnx .dt-account,.admin-tnx .dt-type-text,.admin-tnx .dt-type-md,.dt-hide,.dt-mb,.dt-xs,.dt-sm,.dt-md,.dt-lg,.dt-xl,.dt-xs-all,.dt-sm-all,.dt-md-all,.dt-lg-all,.dt-xl-all {
  display: none
}

@media (min-width: 420px) {
  .user-list .dt-status-sm,.user-tnx .dt-type-sm,.kyc-list .dt-status-sm,.admin-tnx .dt-type-sm {
      display:none
  }

  .user-list .dt-status-md,.user-tnx .dt-type-text,.user-tnx .dt-type-md,.kyc-list .dt-status-text,.kyc-list .dt-status-md,.admin-tnx .dt-type-text,.admin-tnx .dt-type-md {
      display: inline-block
  }

  .dt-xs {
      display: table-cell
  }
}

@media (min-width: 576px) {
  .user-tnx .dt-amount,.admin-tnx .dt-amount,.kyc-list .dt-doc-front,.dt-sm {
      display:table-cell
  }
}

@media (min-width: 768px) {
  .user-list .dt-verify,.kyc-list .dt-doc-back,.kyc-list .dt-sbdate,.dt-md,.dt-mb {
      display:table-cell
  }
}

@media (min-width: 992px) {
  .user-list .dt-email,.user-tnx .dt-account,.user-tnx .dt-usd-amount,.user-tnx .dt-base-amount,.admin-tnx .dt-account,.admin-tnx .dt-usd-amount,.admin-tnx .dt-base-amount,.kyc-list .dt-doc-type,.dt-lg {
      display:table-cell
  }
}

@media (min-width: 1200px) {
  .user-list .dt-login,.dt-xl {
      display:table-cell
  }
}

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative
}

.scroll-wrapper>.scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  position: relative !important;
  top: 0;
  width: auto !important
}

.scroll-wrapper>.scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0
}

.scroll-element {
  display: none
}

.scroll-element,.scroll-element div {
  box-sizing: content-box
}

.scroll-element.scroll-x.scroll-scrollx_visible,.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block
}

.scroll-element .scroll-bar,.scroll-element .scroll-arrow {
  cursor: default
}

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999
}

.scroll-textarea>.scroll-content {
  overflow: hidden !important
}

.scroll-textarea>.scroll-content>textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important
}

.scroll-textarea>.scroll-content>textarea::-webkit-scrollbar {
  height: 0;
  width: 0
}

.scrollbar-inner>.scroll-element,.scrollbar-inner>.scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10
}

.scrollbar-inner>.scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%
}

.scrollbar-inner>.scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%
}

.scrollbar-inner>.scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 8px
}

.scrollbar-inner>.scroll-element .scroll-element_outer {
  overflow: hidden
}

.scrollbar-inner>.scroll-element .scroll-element_outer,.scrollbar-inner>.scroll-element .scroll-element_track,.scrollbar-inner>.scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px
}

.scrollbar-inner>.scroll-element .scroll-element_track,.scrollbar-inner>.scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4
}

.scrollbar-inner>.scroll-element .scroll-element_track {
  background-color: #e0e0e0
}

.scrollbar-inner>.scroll-element .scroll-bar {
  background-color: #c2c2c2
}

.scrollbar-inner>.scroll-element:hover .scroll-bar {
  background-color: #919191
}

.scrollbar-inner>.scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191
}

.scrollbar-inner>.scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px
}

.scrollbar-inner>.scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px
}

.scrollbar-inner>.scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px
}

.scrollbar-inner>.scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px
}

.btn {
  position: relative;
  color: #fff;
  font-weight: 500;
  padding: 8px 20px;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  min-width: 160px;
  border: 1px solid;
  display: inline-block;
  transition: all .4s ease
}

.btn:hover,.btn:focus,.btn:active {
  box-shadow: none !important;
  outline: none !important
}

.btn:disabled {
  opacity: .4
}

.btn .ti,.btn [class*=fa-] {
  line-height: 24px
}

.btn span {
  display: inline-block
}

.btn .ti+span,.btn [class*=fa-]+span {
  margin-left: 10px
}

.btn span+.ti,.btn [class*=fa-]+span {
  margin-left: 10px
}

.btn-icon,.btn-auto {
  min-width: 0
}

.btn-icon-only {
  display: inline-flex;
  align-items: center
}

.btn-icon-only .ti,.btn-icon-only [class*=fa-] {
  font-size: 16px;
  line-height: inherit;
  margin-top: -1px;
  margin-bottom: -1px
}

.btn-between {
  display: inline-flex;
  justify-content: space-between
}

.btn-between .ti {
  margin-left: 20px
}

.btn-progress:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 2px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
  content: '';
  animation: spinCenter 800ms linear infinite
}

.btn-lg {
  padding: 10px 20px
}

.btn-xl {
  padding: 15px 20px
}

.btn-sm {
  padding: 7px 8px;
  line-height: 18px
}

.btn-sm-min {
  min-width: 120px
}

.btn-sm .ti,.btn-sm [class*=fa-] {
  line-height: 18px
}

.btn-sm .ti {
  position: relative;
  top: 1px
}

.btn-sm.btn-icon {
  padding: 7px 10px
}

.btn-xs {
  padding: 3px 10px 4px;
  font-size: 11px;
  line-height: 19px;
  text-transform: none;
  letter-spacing: 0.025em
}

.btn-xs .ti,.btn-xs [class*=fa-] {
  font-size: 14px;
  line-height: 1;
  vertical-align: middle
}

.btn-xs .ti+span,.btn-xs [class*=fa-]+span {
  margin-left: 12px
}

.btn-xs span+.ti,.btn-xs span+[class*=fa-] {
  margin-left: 12px
}

.btn-xs.btn-icon {
  padding: 4px 8px 4px
}

.btn-xs.btn-icon [class*=fa-] {
  font-size: 11px
}

.btn-circle {
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  padding: 6px;
  text-align: center
}

.btn-circle.btn-sm {
  width: 36px;
  height: 36px;
  font-size: 12px;
  padding: 4px
}

.btn-circle.btn-xs {
  width: 30px;
  height: 30px;
  padding: 2px
}

.btn-circle .ti,.btn-circle [class*=fa-] {
  position: relative;
  top: 2px;
  margin-right: 0
}

.btn-block {
  display: block;
  width: 100%
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  box-shadow: 0px 2px 18px 2px rgba(59,89,152,0.25)
}

.btn-facebook:hover,.btn-facebook:focus,.btn-facebook:active {
  color: #fff;
  background: #344e86;
  border-color: #344e86
}

.btn-google {
  background: #d85040;
  border-color: #d85040;
  box-shadow: 0px 2px 18px 2px rgba(216,80,64,0.25)
}

.btn-google:hover,.btn-google:focus,.btn-google:active {
  color: #fff;
  background: #d33d2b;
  border-color: #d33d2b
}

.btn-light {
  background: #758698;
  border-color: #758698
}

.btn-light:disabled,.btn-light:hover,.btn-light:focus,.btn-light:active,.btn-light.active {
  color: #fff;
  background: #68798b;
  border-color: #68798b
}

.btn-light-alt {
  color: #495463;
  background: #e6effb;
  border-color: #e6effb
}

.btn-light-alt:disabled,.btn-light-alt:hover,.btn-light-alt:focus,.btn-light-alt:active,.btn-light-alt.active {
  color: #fff;
  background: #77869b;
  border-color: #77869b
}

.btn-dark {
  background: #495463;
  border-color: #495463
}

.btn-dark:disabled,.btn-dark:hover,.btn-dark:focus,.btn-dark:active,.btn-dark.active {
  color: #fff;
  background: #3e4854;
  border-color: #3e4854
}

.btn-dark-alt {
  color: #495463;
  background: #e1e4e9;
  border-color: #e1e4e9
}

.btn-dark-alt:disabled,.btn-dark-alt:hover,.btn-dark-alt:focus,.btn-dark-alt:active,.btn-dark-alt.active {
  color: #fff;
  background: #495463;
  border-color: #495463
}

.btn-lighter {
  color: #495463;
  background: #e6effb;
  border-color: #e6effb
}

.btn-lighter:disabled,.btn-lighter:hover,.btn-lighter:focus,.btn-lighter:active,.btn-lighter.active {
  color: #495463;
  background: #d9e7f9;
  border-color: #d9e7f9
}

.btn-lighter-alt {
  color: #758698;
  background: #e6effb;
  border-color: #e6effb
}

.btn-lighter-alt:disabled,.btn-lighter-alt:hover,.btn-lighter-alt:focus,.btn-lighter-alt:active,.btn-lighter-alt.active {
  color: #fff;
  background: #647486;
  border-color: #647486
}

.btn-warning {
  background: #ffc100;
  border-color: #ffc100
}

.btn-warning:disabled,.btn-warning:hover,.btn-warning:focus,.btn-warning:active {
  color: #fff;
  background: #e6ae00;
  border-color: #e6ae00
}

.btn-warning-alt {
  color: #cc9a00;
  background: #fff8e0;
  border-color: #fff8e0
}

.btn-warning-alt:disabled,.btn-warning-alt:hover,.btn-warning-alt:focus,.btn-warning-alt:active,.btn-warning-alt.active {
  color: #fff;
  background: #ffc100;
  border-color: #ffc100
}

.btn-primary {
  background: #00d285;
  border-color: #00d285
}

.btn-primary:disabled,.btn-primary:hover,.btn-primary:focus,.btn-primary:active {
  color: #fff;
  background: #00d285;
  border-color: #00d285
}

.btn-primary-alt {
  color: #1a03fd;
  background: #fff;
  border: #fff
}

.btn-primary-alt:disabled,.btn-primary-alt:hover,.btn-primary-alt:focus,.btn-primary-alt:active,.btn-primary-alt.active {
  color: #fff;
  background: #00d285;
  border-color: #00d285
}

.btn-secondary {
  background: #342d6e;
  border-color: #342d6e
}

.btn-secondary:disabled,.btn-secondary:hover,.btn-secondary:focus,.btn-secondary:active {
  color: #fff;
  background: #2b265c;
  border-color: #2b265c
}

.btn-secondary-alt {
  color: #120f26;
  background: #c3bfe5;
  border-color: #c3bfe5
}

.btn-secondary-alt:disabled,.btn-secondary-alt:hover,.btn-secondary-alt:focus,.btn-secondary-alt:active,.btn-secondary-alt.active {
  color: #fff;
  background: #342d6e;
  border-color: #342d6e
}

.btn-info {
  background: #1babfe;
  border-color: #1babfe
}

.btn-info:disabled,.btn-info:hover,.btn-info:focus,.btn-info:active {
  color: #fff;
  background: #02a2fe;
  border-color: #02a2fe
}

.btn-info-alt {
  color: #1babfe;
  background: #cdecff;
  border-color: #cdecff
}

.btn-info-alt:disabled,.btn-info-alt:hover,.btn-info-alt:focus,.btn-info-alt:active,.btn-info-alt.active {
  color: #fff;
  background: #1babfe;
  border-color: #1babfe
}

.btn-success {
  background: #00d285;
  border-color: #00d285
}

.btn-success:disabled,.btn-success:hover,.btn-success:focus,.btn-success:active {
  color: #fff;
  background: #00b975;
  border-color: #00b975
}

.btn-success-alt {
  color: #00b975;
  background: #e1fff4;
  border-color: #e1fff4
}

.btn-success-alt:disabled,.btn-success-alt:hover,.btn-success-alt:focus,.btn-success-alt:active,.btn-success-alt.active {
  color: #fff;
  background: #00d285;
  border-color: #00d285
}

.btn-danger {
  background: #ff6868;
  border-color: #ff6868
}

.btn-danger:disabled,.btn-danger:hover,.btn-danger:focus,.btn-danger:active {
  color: #fff;
  background: #ff4f4f;
  border-color: #ff4f4f
}

.btn-danger-alt {
  color: #ff6868;
  background: #ffd8d8;
  border-color: #ffd8d8
}

.btn-danger-alt:disabled,.btn-danger-alt:hover,.btn-danger-alt:focus,.btn-danger-alt:active,.btn-danger-alt.active {
  color: #fff;
  background: #ff6868;
  border-color: #ff6868
}

.btn-outline {
  background: transparent;
  box-shadow: none
}

.btn-outline-alt {
  border-width: 2px
}

.btn-outline:hover,.btn-outline:focus,.btn-outline:active {
  background: transparent !important
}

.btn-outline.btn-dark {
  color: #495463;
  border-color: #d2dde9
}

.btn-outline.btn-dark:hover,.btn-outline.btn-dark:focus,.btn-outline.btn-dark:active {
  color: #495463 !important;
  border-color: #b1becc
}

.btn-outline.btn-dark.active {
  background: #b1becc !important;
  border-color: #b1becc
}

.btn-outline.btn-light {
  color: #758698;
  border-color: #c9cfd6
}

.btn-outline.btn-light:hover,.btn-outline.btn-light:focus,.btn-outline.btn-light:active {
  color: #68798b !important;
  border-color: #92a0ae
}

.btn-outline.btn-light.active {
  background: #495463 !important;
  border-color: #5f6d80
}

.btn-outline.btn-lighter {
  color: #495463;
  border-color: #e6effb
}

.btn-outline.btn-lighter:hover,.btn-outline.btn-lighter:focus,.btn-outline.btn-lighter:active {
  color: #3e4854 !important;
  border-color: #bad3f4
}

.btn-outline.btn-lighter.active {
  background: #495463 !important;
  border-color: #333b46
}

.btn-outline.btn-warning {
  color: #ffc100
}

.btn-outline.btn-warning:hover,.btn-outline.btn-warning:focus,.btn-outline.btn-warning:active {
  color: #cc9a00 !important;
  border-color: #cc9a00
}

.btn-outline.btn-warning.active {
  background: #ffc100 !important;
  border-color: #cc9a00
}

.btn-outline.btn-primary {
  color: #00d285
}

.btn-outline.btn-primary:hover,.btn-outline.btn-primary:focus,.btn-outline.btn-primary:active {
  color: #513ffe !important;
  border-color: #513ffe
}

.btn-outline.btn-primary.active {
  background: #00d285 !important;
  border-color: #513ffe
}

.btn-outline.btn-secondary {
  color: #342d6e;
  border-color: #342d6e
}

.btn-outline.btn-secondary:hover,.btn-outline.btn-secondary:focus,.btn-outline.btn-secondary:active {
  color: #493f99 !important;
  border-color: #493f99
}

.btn-outline.btn-secondary.active {
  background: #342d6e !important;
  border-color: #493f99
}

.btn-outline.btn-info {
  color: #1babfe
}

.btn-outline.btn-info:hover,.btn-outline.btn-info:focus,.btn-outline.btn-info:active {
  color: #02a2fe !important;
  border-color: #02a2fe
}

.btn-outline.btn-info.active {
  background: #1babfe !important;
  border-color: #02a2fe
}

.btn-outline.btn-success {
  color: #00d285
}

.btn-outline.btn-success:hover,.btn-outline.btn-success:focus,.btn-outline.btn-success:active {
  color: #00b975 !important;
  border-color: #00b975
}

.btn-outline.btn-success.active {
  background: #00d285 !important;
  border-color: #00b975
}

.btn-outline.btn-danger {
  color: #ff6868
}

.btn-outline.btn-danger:hover,.btn-outline.btn-danger:focus,.btn-outline.btn-danger:active {
  color: #ff4f4f !important;
  border-color: #ff4f4f
}

.btn-outline.btn-danger.active {
  background: #ff6868 !important;
  border-color: #ff4f4f
}

.btn-outline.btn-facebook .fab {
  color: #3b5998
}

.btn-outline.btn-google .fab {
  color: #d85040
}

.btn-outline.active {
  color: #fff !important
}

.btn-outline.active:focus {
  color: #fff !important
}

.btn.btn-simple {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  min-width: inherit
}

.btn.btn-simple:hover,.btn.btn-simple:focus,.btn.btn-simple:active {
  color: currentColor;
  opacity: 0.7
}

.btn-absolute {
  border: none;
  background-color: transparent;
  position: absolute;
  padding: 0 15px;
  color: #00d285
}

.btn-absolute-right {
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

.btn-grp {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: -7px;
  margin-right: -7px
}

.btn-grp-between {
  display: flex;
  justify-content: space-between
}

.btn-grp>li {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px
}

.btn-grp.no-gutters {
  margin-left: 0;
  margin-right: 0
}

.btn-grp.no-gutters>li {
  padding-left: 0;
  padding-right: 0
}

.btn-grp.no-gutters>li a {
  border-radius: 0
}

.btn-grp.no-gutters>li:first-child .btn {
  border-radius: 4px 0 0 4px
}

.btn-grp.no-gutters>li:last-child .btn {
  border-radius: 0 4px 4px 0
}

@media (min-width: 480px) {
  .btn {
      padding:8px 20px;
      font-size: 14px
  }

  .btn-lg {
      padding: 10px 20px
  }

  .btn-sm {
      padding: 7px 18px
  }

  .btn-sm-s2 {
      padding: 7px 13px
  }

  .btn-xs {
      padding: 4px 11px 4px;
      font-size: 10px
  }

  .btn-xs.btn-icon {
      padding: 4px 8px 4px
  }

  .btn-xl {
      padding: 18px 30px
  }

  .btn-circle {
      padding-left: 5px;
      padding-right: 5px
  }

  .btn-circle.btn-icon {
      padding: 2px
  }

  .btn-circle.btn-sm {
      padding-left: 9px;
      padding-right: 9px
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .btn-xl {
      padding:18px 20px
  }
}

@keyframes spinCenter {
  0% {
      transform: translate(-50%, -50%) rotate(0deg)
  }

  100% {
      transform: translate(-50%, -50%) rotate(360deg)
  }
}

.link {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  font-family: "Roboto",sans-serif;
  font-weight: 600;
  transition: all .2s
}

.link:hover {
  font-family: #342d6e
}

.link .ti {
  font-size: 14px;
  margin-right: 8px
}

.link-thin {
  font-weight: 400
}

.link-ucap {
  text-transform: uppercase;
  letter-spacing: 0.1em
}

.link-dim {
  opacity: .7
}

.link-dim:hover {
  opacity: 1
}

.link-light {
  color: #758698
}

.link-light:hover,.link-light:focus,.link-light:active {
  color: #5d6d7d
}

.link-primary {
  color: #00d285
}

.link-primary:hover,.link-primary:focus,.link-primary:active {
  color: #4835fe
}

.link-danger {
  color: #ff6868
}

.link-danger:hover,.link-danger:focus,.link-danger:active {
  color: #ff3535
}

.link-underline {
  box-shadow: 0 1px 0 currentColor;
  line-height: 1.1
}

.link span+.icon {
  margin-left: 6px
}

.input-item {
  position: relative;
  padding-bottom: 20px
}

.input-item-sm {
  padding-bottom: 6px
}

.input-item-label {
  font-size: 14px;
  font-weight: 500;
  color: #495463;
  line-height: 1.1;
  margin-bottom: 12px;
  display: inline-block
}

.input-sub-label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  color: #6e81a9;
  white-space: nowrap
}

.input-wrap {
  position: relative
}

.input-wrap-switch {
  height: 42px;
  display: flex
}

.input-bordered {
  border-radius: 4px;
  border: 1px solid #d2dde9;
  width: 100%;
  padding: 10px 5px;
  line-height: 20px;
  font-size: .9em;
  color: rgba(73,84,99,0.7);
  transition: all .4s
}
.input-bordered.high-light {
  background: #e6effb;
  border: 2px solid #e6effb;
}

.input-bordered::-webkit-input-placeholder {
  color: #758698
}

.input-bordered::-moz-placeholder {
  color: #758698
}

.input-bordered:-ms-input-placeholder {
  color: #758698
}

.input-bordered:-moz-placeholder {
  color: #758698
}

.input-bordered:focus {
  box-shadow: 0 1px 5px rgba(73,84,99,0.1);
  outline: none;
  border-color: #b1becc;
  color: #495463
}

.input-bordered:disabled {
  background: rgba(230,239,251,0.2)
}

.input-bordered ~ .error {
  color: #ff6868;
  margin-bottom: 0;
  position: relative;
  top: 4px
}

.input-bordered.input-has-hint {
  padding-right: 40px
}

.validate-modern .input-bordered ~ .error {
  position: absolute;
  right: -10px;
  top: -15px;
  background: #ff6868 !important;
  color: #fff !important;
  font-size: 11px;
  line-height: 18px;
  padding: 2px 10px;
  border-radius: 2px
}

.validate-modern .input-bordered ~ .error:after {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid #ff6868;
  border-bottom: 5px solid transparent;
  bottom: -5px;
  left: 3px
}

.input-solid {
  border-radius: 4px;
  border: none;
  width: 100%;
  padding: 10px 15px;
  line-height: 20px;
  font-size: .9em;
  color: #6e81a9;
  background: #eef2f8;
  border: 1px solid #eef2f8;
  transition: all .4s
}

.input-solid::-webkit-input-placeholder {
  color: #6e81a9
}

.input-solid::-moz-placeholder {
  color: #6e81a9
}

.input-solid:-ms-input-placeholder {
  color: #6e81a9
}

.input-solid:-moz-placeholder {
  color: #6e81a9
}

.input-solid-sm {
  padding: 8px 12px
}

.input-solid-sm.date-picker {
  padding: 7px 12px
}

.input-grp .input-solid-sm {
  padding: 3px 12px
}

.input-solid:focus {
  box-shadow: 0 1px 5px rgba(73,84,99,0.1);
  outline: none;
  border-color: rgba(177,190,204,0.5);
  color: #495463
}

.validate-modern .input-solid ~ .error {
  position: absolute;
  right: -10px;
  top: -15px;
  background: #ff6868 !important;
  color: #fff !important;
  font-size: 11px;
  line-height: 18px;
  padding: 2px 10px;
  border-radius: 2px
}

.validate-modern .input-solid ~ .error:after {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid #ff6868;
  border-bottom: 5px solid transparent;
  bottom: -5px;
  left: 3px
}

.input-textarea {
  height: 136px;
  display: block;
  resize: none
}

.input-textarea-sm {
  height: 80px
}

.input-file {
  opacity: 0;
  height: 42px
}

.input-file-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  width: 44px;
  line-height: 44px;
  text-align: center
}

.input-file ~ label {
  position: absolute;
  top: 0;
  left: 0;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #d2dde9;
  width: 100%;
  padding: 10px 15px;
  line-height: 20px;
  font-size: 14px;
  color: #495463;
  transition: all .4s
}

.input-switch {
  display: none
}

.input-switch ~ label:not(.error) {
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 70px;
  min-height: 30px;
  min-width: 50px;
  border-radius: 12px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  position: relative;
  color: #495463
}

.input-switch ~ label:not(.error).no-text {
  padding-left: 56px
}

.input-switch ~ label:not(.error):before,.input-switch ~ label:not(.error):after {
  position: absolute;
  content: '';
  transition: all .4s
}

.input-switch ~ label:not(.error):before {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 56px;
  border-radius: 15px;
  background: #d2dde9
}

.input-switch ~ label:not(.error):after {
  left: 29px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff
}

.input-switch ~ label:not(.error) span {
  transition: all .4s;
  opacity: 1
}

.input-switch ~ label:not(.error) span.over {
  position: absolute;
  left: 70px
}

.input-switch ~ label:not(.error) span:last-child {
  opacity: 0
}

.input-switch-left ~ label:not(.error) span.over {
  left: auto;
  right: calc(100% + 10px)
}

.input-switch:disabled ~ label {
  opacity: .5
}

.input-switch:checked ~ label:before {
  background: #00d285
}

.input-switch:checked ~ label:after {
  left: 3px
}

.input-switch:checked ~ label span {
  opacity: 0
}

.input-switch:checked ~ label span:last-child {
  opacity: 1
}

.input-switch-alt ~ label {
  padding-right: 70px;
  padding-left: 0
}

.input-switch-alt ~ label:before {
  left: auto;
  right: 0
}

.input-switch-alt ~ label:after {
  left: auto;
  right: 3px
}

.input-switch-alt ~ label span {
  text-align: right
}

.input-switch-alt ~ label span.over {
  left: auto;
  right: 70px
}

.input-switch-alt:checked ~ label:after {
  left: auto;
  right: 29px
}

.input-switch-sm ~ label:not(.error) {
  min-height: 24px;
  line-height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 62px
}

.input-switch-sm ~ label:not(.error).no-text {
  padding-left: 50px
}

.input-switch-sm ~ label:not(.error):before {
  height: 24px;
  width: 50px;
  border-radius: 12px
}

.input-switch-sm ~ label:not(.error):after {
  left: 29px;
  height: 18px;
  width: 18px
}

.input-switch-sm:checked ~ label:after {
  left: auto;
  left: 3px
}

.input-switch-sm.input-switch-alt ~ label {
  padding-right: 60px
}

.input-switch-sm.input-switch-alt ~ label:before {
  left: auto;
  right: 0
}

.input-switch-sm.input-switch-alt ~ label:after {
  left: auto;
  right: 3px
}

.input-switch-sm.input-switch-alt ~ label span {
  text-align: right
}

.input-switch-sm.input-switch-alt ~ label span.over {
  left: auto;
  right: 60px
}

.input-switch-sm.input-switch-alt:checked ~ label:after {
  left: auto;
  right: 25px
}

.input-switch-middle {
  height: 44px;
  display: flex;
  align-items: center
}

.input-checkbox,.input-radio {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px
}

.input-checkbox ~ label:not(.error),.input-radio ~ label:not(.error) {
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 0;
  color: #495463;
  padding-left: 42px;
  position: relative;
  cursor: pointer;
  transition: all .4s;
  text-transform: initial;
  display: block
}

.input-checkbox ~ label:not(.error):before,.input-checkbox ~ label:not(.error):after,.input-radio ~ label:not(.error):before,.input-radio ~ label:not(.error):after {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  transition: all .4s
}

.input-checkbox ~ label:not(.error):before,.input-radio ~ label:not(.error):before {
  content: '';
  border: 2px solid #d2dde9
}

.input-checkbox ~ label:not(.error):after,.input-radio ~ label:not(.error):after {
  line-height: 30px;
  text-align: center;
  font-family: themify;
  content: '\e64c';
  font-size: 14px;
  font-weight: 900;
  color: #fff;
  background: #00d285;
  opacity: 0
}

.input-checkbox:disabled ~ label:not(.error),.input-radio:disabled ~ label:not(.error) {
  opacity: .5
}

.input-checkbox:checked ~ label:not(.error):after,.input-radio:checked ~ label:not(.error):after {
  opacity: 1
}

.input-checkbox ~ .error,.input-radio ~ .error {
  position: absolute;
  left: 1px;
  color: #fff;
  font-size: 12px;
  bottom: 100%;
  background: #ff6868;
  padding: 5px 10px;
  z-index: 1;
  border-radius: 2px;
  white-space: nowrap
}

.input-checkbox ~ .error:before,.input-radio ~ .error:before {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ff6868;
  bottom: -6px;
  left: 8px
}

.validate-modern .input-checkbox ~ .error,.validate-modern .input-radio ~ .error {
  position: absolute;
  left: -8px;
  top: -26px;
  bottom: auto;
  background: #ff6868 !important;
  color: #fff !important;
  font-size: 11px;
  line-height: 18px;
  padding: 2px 10px;
  border-radius: 2px;
  transform: translate(0, 0)
}

.validate-modern .input-checkbox ~ .error:after,.validate-modern .input-radio ~ .error:after {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid #ff6868;
  border-bottom: 5px solid transparent;
  bottom: -5px;
  left: 3px
}

.validate-modern .input-checkbox ~ .error:before,.validate-modern .input-radio ~ .error:before {
  display: none
}

.input-checkbox-sm ~ label:not(.error),.input-radio-sm ~ label:not(.error) {
  font-size: 12px;
  line-height: 20px;
  padding-left: 30px
}

.input-checkbox-sm ~ label:not(.error):before,.input-checkbox-sm ~ label:not(.error):after,.input-radio-sm ~ label:not(.error):before,.input-radio-sm ~ label:not(.error):after {
  height: 20px;
  width: 20px
}

.input-checkbox-sm ~ label:not(.error):after,.input-radio-sm ~ label:not(.error):after {
  line-height: 20px;
  font-size: 10px
}

.input-checkbox-sm ~ .error,.input-radio-sm ~ .error {
  left: -3px
}

.input-checkbox-md ~ label:not(.error),.input-radio-md ~ label:not(.error) {
  font-size: 14px;
  line-height: 24px;
  padding-left: 36px
}

.input-checkbox-md ~ label:not(.error):before,.input-checkbox-md ~ label:not(.error):after,.input-radio-md ~ label:not(.error):before,.input-radio-md ~ label:not(.error):after {
  height: 24px;
  width: 24px
}

.input-checkbox-md ~ label:not(.error):after,.input-radio-md ~ label:not(.error):after {
  line-height: 24px;
  font-size: 10px
}

.input-checkbox-md ~ .error,.input-radio-md ~ .error {
  left: -2px
}

.input-radio ~ label:not(.error):before,.input-radio ~ label:not(.error):after {
  border-radius: 50%
}

.input-radio ~ label:not(.error):after {
  content: '';
  height: 16px;
  width: 16px;
  top: 7px;
  left: 7px
}

.input-radio:checked ~ label:not(.error):before {
  border-color: #00d285
}

.input-radio-md ~ label:not(.error):after {
  height: 14px;
  width: 14px;
  top: 5px;
  left: 5px
}

.input-radio-sm ~ label:not(.error):after {
  height: 12px;
  width: 12px;
  top: 4px;
  left: 4px
}

.input-radio-flat {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px
}

.input-radio-flat ~ label:not(.error) {
  font-size: 12px;
  border: 1px solid #eef2f8;
  line-height: 14px;
  padding: 10px 18px 8px;
  border-radius: 2px;
  cursor: pointer;
  transition: all .3s;
  min-width: 62px
}

.input-radio-flat:checked ~ label:not(.error) {
  color: #fff;
  background: #00d285;
  border-color: #00d285;
  cursor: default
}

.input-radio-list {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -3px
}

.input-radio-list li {
  padding: 3px
}

.input-with-hint {
  padding-right: 90px
}

.input-hint {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  color: #758698;
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
  z-index: 1;
  padding: 0 10px;
  background: #fff
}

.input-hint span {
  color: #00d285
}

.input-hint-lg {
  font-size: 18px
}

.input-hint-sap {
  border-left: 1px solid #d2dde9
}

.input-note {
  font-size: 12px;
  line-height: 1.4em;
  color: #758698;
  font-weight: 400;
  display: block;
  padding: 8px 0 0
}

.input-note span {
  color: #00d285
}

.input-note-icon {
  padding-left: 15px;
  position: relative
}

.input-note [class*=fa] {
  position: absolute;
  left: 0;
  top: 7.2px
}

.input-note-danger {
  color: #ff6868
}

.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  opacity: .5;
  line-height: 1;
  font-size: 12px
}

.input-icon-lg {
  font-size: 13px;
  line-height: 15px;
  left: 12px
}

.input-icon-left {
  left: 12px
}

.input-icon-left ~ .input-solid,.input-icon-left+.input-bordered {
  padding-left: 35px
}

.input-icon-right {
  right: 12px;
  left: auto
}

.input-icon-right ~ .input-solid,.input-icon-right+.input-bordered {
  padding-right: 30px
}

.input[type="checkbox"]+label.error {
  color: white !important
}

.checkbox-list li {
  padding-right: 35px
}

.checkbox-list li .input-item {
  padding-bottom: 15px
}

.checkbox-list li:last-child {
  padding-right: 0 !important
}

@media (min-width: 414px) {
  .checkbox-list-c4 li,.checkbox-list-c5 li {
      padding-right:10px;
      width: 50%
  }
}

@media (min-width: 768px) {
  .checkbox-list-c4 li,.checkbox-list-c5 li {
      width:33.33%
  }
}

@media (min-width: 992px) {
  .checkbox-list-c4 li {
      width:25%
  }

  .checkbox-list-c5 li {
      width: 20%
  }
}

@media (min-width: 576px) {
  .input-icon-lg {
      font-size:18px;
      line-height: 15px;
      left: 18px
  }

  .input-icon-left ~ .input-solid,.input-icon-left+.input-bordered {
      padding-left: 40px
  }

  .input-icon-right {
      right: 12px;
      left: auto
  }

  .input-icon-right ~ .input-solid,.input-icon-right+.input-bordered {
      padding-right: 40px
  }
}

.simple-switch {
  flex-shrink: 0;
  min-height: 24px;
  width: 50px;
  border-radius: 12px;
  display: inline-block;
  background: #d2dde9;
  position: relative
}

.simple-switch:after {
  position: absolute;
  content: '';
  transition: all .4s;
  left: 3px;
  top: 3px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #fff
}

.simple-switch.active {
  background: #00d285
}

.simple-switch.active:after {
  left: calc(100% - 21px)
}

.steps ul {
  display: flex
}

.steps ul li {
  flex-grow: 1
}

.steps .current-info {
  display: none
}

.actions ul {
  display: flex;
  margin: -10px;
  padding-top: 20px
}

.actions ul li {
  padding: 10px
}

.actions ul li:first-child {
  order: 1
}

.actions ul li a {
  position: relative;
  color: #fff;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  border: 1px solid;
  transition: all .4s ease;
  border-color: #00d285;
  background: #00d285
}

.actions ul li.disabled {
  display: none
}

.wizard-simple .steps {
  margin-bottom: 20px
}

.wizard-simple .steps ul li {
  position: relative;
  padding-bottom: 5px
}

.wizard-simple .steps ul li h5 {
  border: none;
  padding: 0 0 6px 0;
  letter-spacing: 0.02em;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  color: #758698
}

.wizard-simple .steps ul li .number {
  font-size: 13px;
  color: #758698;
  font-weight: 700
}

.wizard-simple .steps ul li:after {
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  bottom: 0;
  background: #00d285;
  content: '';
  transition: all .4s
}

.wizard-simple .steps ul li.done:after,.wizard-simple .steps ul li.current:after {
  width: 100%
}

.wizard-simple .steps ul li.done h5,.wizard-simple .steps ul li.done .number,.wizard-simple .steps ul li.current h5,.wizard-simple .steps ul li.current .number {
  color: #00d285
}

.wizard-simple .steps ul li.current ~ .done:after {
  width: 0
}

.wizard-simple .steps ul li.current ~ .done h5,.wizard-simple .steps ul li.current ~ .done .number {
  color: #758698
}

.wizard-simple .steps .current-info {
  display: none
}

.wizard-kyc .steps {
  margin-bottom: 30px
}

.wizard-kyc .steps ul {
  margin: 0 -15px
}

.wizard-kyc .steps ul li {
  position: relative;
  padding: 0 15px
}

.wizard-kyc .steps ul li .number {
  display: none
}

.wizard-kyc .steps ul li .step-number {
  font-size: 18px;
  transition: all .4s
}

.wizard-kyc .steps ul li .step-head {
  display: block
}

.wizard-kyc .steps ul li .step-head-text h4 {
  color: #342d6e;
  padding: 7px 0;
  font-size: 1.19em
}

.wizard-kyc .steps ul li .step-head-text p {
  color: #758698;
  font-size: 0.9em;
  line-height: 1.5
}

.wizard-kyc .steps ul li.done .step-number,.wizard-kyc .steps ul li.current .step-number {
  color: #fff;
  border-color: #00d285;
  background: #00d285
}

.wizard-kyc .steps ul li.current ~ .done .step-number {
  color: #758698;
  border-color: #b1becc;
  background: none
}

.wizard-kyc .steps .current-info {
  display: none
}

select {
  width: 100%;
  line-height: 20px;
  padding: 10px 20px 10px 15px;
  border: none;
  border-radius: 4px;
  height: 42px !important;
  font-size: 14px;
  color: #6e81a9;
  background: transparent;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

select:focus {
  outline: none
}

select.select-sm {
  height: 37px
}

select ~ .error {
  color: #ff6868;
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  z-index: 11;
  font-size: 11px
}

.validate-modern select ~ .error {
  position: absolute;
  right: -10px;
  top: -15px;
  bottom: auto;
  background: #ff6868;
  color: #fff;
  font-size: 11px;
  line-height: 18px;
  padding: 2px 10px;
  border-radius: 2px;
  z-index: 11
}

.validate-modern select ~ .error:after {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid #ff6868;
  border-bottom: 5px solid transparent;
  bottom: -5px;
  left: 3px
}

.error+.select2-container {
  margin-bottom: 25px
}

.validate-modern .error+.select2-container {
  margin-bottom: 0
}

.select-wrapper {
  position: relative
}

.select-wrapper:after {
  position: absolute;
  height: 42px;
  width: 44px;
  line-height: 44px;
  text-align: center;
  top: 50%;
  right: 0;
  font-family: 'themify';
  transform: translateY(-50%);
  content: '\e64b';
  font-size: 10px;
  color: #758698
}

.select2-container .select2-selection--single .select2-selection__rendered,.select2-container .select2-selection--multiple .select2-selection__rendered {
  line-height: 20px;
  font-size: .9em;
  padding: 10px 40px 10px 15px;
  font-weight: 400
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 2px 8px 6px 8px;
  display: block;
  vertical-align: top
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  margin-top: 4px;
  float: left
}

.select2-container .select2-selection--multiple .select2-search__field {
  padding-left: 7px
}

.select2-container .select2-search--inline:first-child {
  width: 100%
}

.select2-container--flat .select2-selection--single .select2-selection__rendered,.select2-container--flat .select2-selection--multiple .select2-selection__rendered {
  color: #495463
}

.select-sm ~ .select2-container .select2-selection--single .select2-selection__rendered,.select-sm ~ .select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 8px 32px 8px 12px
}

.select-xs ~ .select2-container .select2-selection--single .select2-selection__rendered,.select-xs ~ .select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 5px 26px 5px 12px
}

.select2-container .select2-selection--single {
  height: 42px
}

.select2-container .select2-selection--multiple {
  min-height: 42px
}

.select-sm ~ .select2-container .select2-selection--single,.select-sm ~ .select2-container .select2-selection--multiple {
  height: 36px
}

.select-xs ~ .select2-container .select2-selection--single,.select-xs ~ .select2-container .select2-selection--multiple {
  height: 30px
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered,.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 40px
}

.select-sm ~ .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered,.select-sm ~ .select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__rendered {
  padding-right: 12px;
  padding-left: 32px
}

.select-xs ~ .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered,.select-xs ~ .select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__rendered {
  padding-right: 12px;
  padding-left: 28px
}

.select2-container--flat .select2-results__option--highlighted[aria-selected] {
  background: rgba(230,239,251,0.7);
  color: #495463
}

.select2-container--flat .select2-results__option[aria-selected=true] {
  background: rgba(230,239,251,0.7);
  color: #495463
}

.select2-results__option {
  padding: 8px 15px;
  border-bottom: 1px solid #eef2f8;
  font-size: .9em;
  line-height: 1.2;
  transition: all .5s ease
}

.select2-results__option:last-child {
  border-bottom: none
}

.select2-container--flat .select2-selection--single,.select2-container--flat .select2-selection--multiple {
  background: #fff;
  color: #495463;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all .2s;
  box-shadow: 0px 2px 18px 2px rgba(73,84,99,0.25)
}

.select2-container--flat .select2-selection--single:focus,.select2-container--flat .select2-selection--multiple:focus {
  outline: none
}

.select-bordered ~ .select2-container--flat .select2-selection--single,.select-bordered ~ .select2-container--flat .select2-selection--multiple {
  box-shadow: none;
  border-color: #d2dde9
}

.select-bordered ~ .select2-container--flat.select2-container--open .select2-selection--single,.select-bordered ~ .select2-container--flat.select2-container--open .select2-selection--multiple {
  border-color: #b1becc
}

.select2-container--open.select2-container--below .select2-selection--single,.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 4px 4px 0 0
}

.select2-container--open.select2-container--above .select2-selection--single,.select2-container--open.select2-container--above .select2-selection--multiple {
  border-radius: 0 0 4px 4px
}

.select2-selection--single .select2-selection__arrow,.select2-selection--multiple .select2-selection__arrow {
  position: absolute;
  height: 44px;
  width: 40px;
  top: 0;
  right: 0;
  transition: all .3s ease
}

.select2-selection--single .select2-selection__arrow:after,.select2-selection--multiple .select2-selection__arrow:after {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: 'themify';
  transform: translate(-50%, -50%);
  content: '\e64b';
  font-size: 10px;
  color: #758698
}

.select2-selection--single .select2-selection__arrow b,.select2-selection--multiple .select2-selection__arrow b {
  display: none
}

.select-sm ~ .select2-container .select2-selection--single .select2-selection__arrow,.select-sm ~ .select2-container .select2-selection--multiple .select2-selection__arrow {
  height: 37px;
  width: 37px
}

.select-sm ~ .select2-container .select2-selection--single .select2-selection__arrow:after,.select-sm ~ .select2-container .select2-selection--multiple .select2-selection__arrow:after {
  font-size: 12px
}

.select-xs ~ .select2-container .select2-selection--single .select2-selection__arrow,.select-xs ~ .select2-container .select2-selection--multiple .select2-selection__arrow {
  height: 30px;
  width: 30px
}

.select-xs ~ .select2-container .select2-selection--single .select2-selection__arrow:after,.select-xs ~ .select2-container .select2-selection--multiple .select2-selection__arrow:after {
  font-size: 12px
}

.select2-container--flat.select2-container--open .select2-selection--single .select2-selection__arrow,.select2-container--flat.select2-container--open .select2-selection--multiple .select2-selection__arrow {
  transform: rotate(-180deg)
}

.select2-container--flat .select2-results>.select2-results__options {
  max-height: 250px;
  overflow: auto;
  background: #fff
}

.select2-dropdown--below .select2-results__options {
  border-radius: 0 0 4px 4px
}

.select2-dropdown--above .select2-results__options {
  border-radius: 4px 4px 0 0
}

.select2-dropdown.select2-dropdown--below {
  border-radius: 0 0 4px 4px
}

.select2-dropdown.select2-dropdown--above {
  border-radius: 4px 4px 0 0
}

.select2-search--dropdown {
  display: none;
  background: #fff;
  padding: 8px 15px
}

.search-on .select2-search--dropdown {
  display: block;
  border-bottom: 1px solid #e6effb
}

.select2-search--inline {
  padding-top: 8px
}

.select2-search--inline:first-child input {
  min-width: 100%
}

.select2-search--inline input {
  margin-top: 0 !important;
  min-width: 20px;
  transition: none;
  outline: none
}

.select2-dropdown {
  border-radius: 0;
  border: none;
  background: transparent;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03)
}

.bordered .select2-dropdown {
  border: 1px solid #b1becc
}

.bordered .select2-dropdown.select2-dropdown--above {
  border-bottom: none
}

.bordered .select2-dropdown.select2-dropdown--below {
  border-top: none
}

.select2-selection__choice {
  display: inline-block;
  padding: 4px 8px 4px 2px;
  background: #e6effb;
  border-radius: 3px;
  margin-right: 6px
}

.select2-selection__choice__remove {
  padding: 2px 6px
}

.select2-selection__choice__remove:hover {
  color: #ff6868
}

.select-block ~ .select2-container.select2 {
  width: 100% !important
}

.select2-search__field {
  border-radius: 3px;
  border: 1px solid #d2dde9;
  width: 100%;
  padding: 10px 15px;
  line-height: 20px;
  font-size: .9em;
  color: rgba(73,84,99,0.7);
  transition: border-color .4s, color .4s;
  outline: 0
}

.select2-search--dropdown .select2-search__field {
  padding: 5px 6px
}

.document-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px
}

.document-item {
  width: 100%
}

.document-type {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px
}

.document-type-icon {
  position: relative;
  width: 30px;
  flex-shrink: 0
}

.document-type-icon img {
  min-width: 100%;
  transition: all .4s
}

.document-type-icon img:last-child:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}

.document-type ~ label {
  position: relative;
  display: block;
  border: 2px solid #e6effb;
  border-radius: 4px;
  color: #758698;
  padding: 8px 42px 8px 14px;
  font-size: 11px;
  transition: all .4s;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0
}

.document-type ~ label:after {
  position: absolute;
  right: 14px;
  top: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 9px;
  border-radius: 50%;
  transform: translateY(-50%);
  font-family: 'Font Awesome';
  content: '\f00c';
  font-weight: 700;
  color: #fff;
  background: #00d285;
  text-align: center;
  opacity: 0
}

.document-type ~ label span {
  padding-left: 15px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05em;
  transition: all .4s
}

.document-type:checked ~ label {
  border-color: #00d285
}

.document-type:checked ~ label:after {
  opacity: 1
}

.document-type:checked ~ label span {
  color: #495463
}

.document-type:checked ~ label .document-type-icon img:last-child:not(:first-child) {
  opacity: 1
}

@media (min-width: 414px) {
  .document-list {
      margin-left:-5px;
      margin-right: -5px
  }

  .document-item {
      padding-left: 5px;
      padding-right: 5px;
      width: 50%
  }

  .document-item:last-child:not(:nth-child(even)) {
      width: 100%
  }

  .document-type ~ label span {
      padding-left: 8px
  }
}

@media (min-width: 768px) {
  .document-item {
      width:auto !important
  }

  .document-type-icon {
      width: 34px
  }

  .document-type ~ label {
      padding-right: 46px;
      font-size: 12px
  }
}

@media (min-width: 1200px) {
  .document-list {
      margin-left:-10px;
      margin-right: -10px
  }

  .document-item {
      padding-left: 10px;
      padding-right: 10px
  }

  .document-type-icon {
      width: 40px
  }

  .document-type ~ label {
      padding: 10px 48px 10px 16px;
      font-size: 13px
  }
}

.doc-upload+.doc-upload {
  margin-top: 30px
}

.doc-upload.show {
  display: block
}

.doc-upload.hide {
  display: none
}

input,textarea {
  background: none
}

.page-nav-wrap {
  border: 1px solid #e6effb;
  border-radius: 3px;
  margin-bottom: 30px
}

.page-nav-wrap>div:first-child {
  border-radius: 3px 3px 0 0
}

.page-nav-wrap>div:last-child {
  border-radius: 0 0 3px 3px
}

.page-nav-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 20px
}

.page-nav li a {
  font-size: 14px;
  color: #758698;
  font-weight: 500
}

.page-nav li a:hover,.page-nav li a:focus {
  color: #495463
}

.page-nav li:first-child a {
  padding-left: 0
}

.page-nav li.active a {
  color: #00d285
}

.input-transparent {
  background: transparent;
  border: none
}

.input-transparent:focus {
  box-shadow: none !important
}

.input-grp {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  padding: 2px;
  border-radius: 3px;
  border: 1px solid #e6effb
}

.input-radio-select {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px
}

.input-radio-select ~ label:not(.error) {
  height: 26px;
  padding: 0 12px;
  background: #eef2f8;
  line-height: 26px;
  margin-bottom: 0;
  border-radius: 3px;
  font-weight: 700;
  font-size: 11px;
  color: #495463;
  text-transform: uppercase;
  transition: all .4s;
  cursor: pointer
}

.input-radio-select:checked ~ label {
  background: #495463;
  color: #fff
}

.input-radio-wrap {
  display: inline-flex
}

.input-item-label-s2 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 8px
}

.search-adv-wrap {
  border-top: 1px solid #e6effb;
  padding: 16px 20px 22px;
  background: rgba(230,239,251,0.4)
}

.search-adv-result {
  border-top: 1px solid #e6effb;
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center
}

.search-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  color: #758698;
  margin-right: 14px
}

.search-info span {
  color: #495463
}

.search-opt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 14px 0 -6px
}

.search-opt li {
  padding: 6px
}

.search-opt li a:not(.link) {
  padding: 6px 9px;
  background: #f9fcff;
  border-radius: 3px;
  border: 1px solid #e6effb;
  font-size: 12px;
  color: #495463;
  position: relative;
  padding-right: 44px;
  transition: border-color .3s
}

.search-opt li a:not(.link):after {
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'Font Awesome';
  font-weight: 900;
  content: "\f00d";
  font-size: 12px;
  color: #495463;
  width: 28px;
  height: 100%;
  text-align: center;
  line-height: 27px;
  border-left: 1px solid #e6effb
}

.search-opt li a:not(.link):hover {
  border-color: #d2dde9
}

.search-opt li a.link {
  font-weight: normal
}

.search-type {
  display: flex;
  flex-wrap: wrap
}

.search-type>li {
  padding: 2px
}

.search .input-icon {
  margin-top: 2px
}

.pagination-bar {
  padding-top: 30px;
  border-top: 1px solid #d2dde9
}

.pagination-btn li a {
  font-weight: 400;
  color: #495463
}

.pagination-info {
  display: flex;
  align-items: center;
  position: relative
}

.pagination-info-text {
  font-size: 13px;
  color: #758698
}

.pagination-info-text+select {
  color: #758698
}

.date-hide-show {
  display: none
}

@media (min-width: 410px) {
  .input-item-middle {
      padding:6px 0
  }
}

@media (max-width: 991px) {
  .page-nav {
      margin-top:-5px;
      margin-bottom: 8px
  }

  .page-nav li a {
      padding: 6px 12px
  }

  .search .input-icon-left {
      left: 0
  }

  .search .input-icon-left+.input-solid {
      padding-left: 22px
  }
}

@media (max-width: 575px) {
  .input-grp .input-wrap {
      margin-top:6px
  }

  .input-grp>* {
      width: 100%
  }

  .search {
      margin: 5px 0 10px
  }

  .search-type {
      margin: 2px 5px 8px 10px
  }

  .page-nav {
      margin-bottom: 0
  }

  .page-nav li a {
      font-size: 12px;
      padding: 6px 8px;
      letter-spacing: -0.03em
  }

  .page-nav-bar {
      padding-bottom: 17px
  }
}

.alert {
  position: relative;
  font-weight: 400;
  color: #fff;
  border: none
}

.alert a,.alert .alert-link {
  color: inherit;
  font-weight: inherit;
  box-shadow: 0 1px 0 currentColor;
  transition: box-shadow .3s
}

.alert a:hover,.alert .alert-link:hover {
  box-shadow: 0 0 0
}

.alert .close {
  color: inherit;
  transition: all .4s;
  height: 30px;
  width: 30px;
  padding: 0;
  background: none;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  right: 10px;
  text-shadow: none;
  opacity: .7
}

.alert .close:not(:disabled):not(.disabled):focus,.alert .close:not(:disabled):not(.disabled):hover,.alert .close:hover,.alert .close:focus {
  color: currentColor;
  box-shadow: none;
  outline: none;
  opacity: 1
}

.alert .close:after {
  position: absolute;
  font-family: 'themify';
  content: '\e646';
  top: 0;
  left: 0;
  color: currentColor;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  width: 100%
}

.alert .close span {
  display: none
}

.alert-xs {
  padding: 5px 12px;
  font-size: 11px;
  line-height: 18px;
  text-transform: none;
  letter-spacing: 0.025em
}

.alert-center {
  text-align: center
}

.alert-primary {
  color: #1a03fd;
  background: #fff
}

.alert-primary .close {
  background: #d2cdff
}

.alert-primary-alt {
  background: #00d285
}

.alert-primary-alt .close {
  background: #311cfd
}

.alert-secondary {
  color: #120f26;
  background: #c3bfe5
}

.alert-secondary .close {
  background: #8a82cc
}

.alert-secondary-alt {
  background: #342d6e
}

.alert-secondary-alt .close {
  background: #1a1738
}

.alert-success {
  color: #00b975;
  background: #cdffed
}

.alert-success .close {
  background: #06ffa4
}

.alert-success-alt {
  background: #00d285
}

.alert-success-alt .close {
  background: #009f65
}

.alert-danger {
  color: #ff6868;
  background: #ffeded
}

.alert-danger .close {
  background: #ffcece
}

.alert-danger-alt {
  background: #ff6868
}

.alert-danger-alt .close {
  background: #e80000
}

.alert-info {
  color: #1babfe;
  background: #cdecff
}

.alert-info .close {
  background: #81d0fe
}

.alert-info-alt {
  background: #1babfe
}

.alert-info-alt .close {
  background: #0171b2
}

.alert-warning {
  color: #cc9a00;
  background: #fff3cc
}

.alert-warning .close {
  background: #ffd44d
}

.alert-warning-alt {
  background: #ffc100
}

.alert-warning-alt .close {
  background: #cc9a00
}

.alert-light {
  color: #758698;
  background: #e7eaed
}

.alert-light .close {
  background: #afb9c4
}

.alert-light-alt {
  background: #758698
}

.alert-light-alt .close {
  background: #475360
}

.alert-dark {
  color: #495463;
  background: #e1e4e9
}

.alert-dark .close {
  background: #9aa6b5
}

.alert-dark-alt {
  background: #495463
}

.alert-dark-alt .close {
  background: #1e2228
}

.alert-box {
  text-align: center;
  border-radius: 4px;
  padding: 15px;
  display: block;
  border: 1px solid;
  margin-bottom: 30px
}

.alert-txt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 12px
}

.alert-txt .ti,.alert-txt .iconfont,.alert-txt [class*=fa-] {
  margin-right: 10px;
  line-height: 36px;
  width: 36px;
  border-radius: 50%;
  text-align: center;
  background: #f2b2a7;
  color: #af4038;
  flex-shrink: 0
}

@media (min-width: 768px) {
  .alert-box {
      padding:20px;
      display: flex;
      justify-content: space-between;
      align-items: center
  }

  .alert-txt {
      padding-bottom: 0;
      font-size: 14px;
      justify-content: flex-start
  }
}

.note {
  padding: 15px 15px 15px 45px;
  border-radius: 4px;
  position: relative;
  line-height: 1.4
}

.note-no-icon {
  padding: 15px
}

.note-no-icon.note-lg {
  padding: 15px 25px
}

.note [class*=fa] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 11px;
  width: 44px;
  text-align: center;
  line-height: inherit
}

.note p,.note .note-text {
  font-size: 11px !important;
  line-height: inherit;
  display: block
}

.note-plane {
  padding: 0 0 0 18px;
  background: transparent !important
}

.note-plane [class*=fa] {
  top: 0;
  line-height: 14px;
  transform: translateY(1px);
  width: 18px;
  text-align: left
}

.note-light p,.note-light .note-text {
  color: #495463
}

.note-light [class*=fa] {
  color: #00d285
}

.note-light-alt p,.note-light-alt .note-text {
  color: #758698
}

.note-light-alt [class*=fa] {
  color: #758698
}

.note-info {
  background: #e6f6ff
}

.note-info p,.note-info .note-text {
  color: rgba(27,171,254,0.9) !important
}

.note-info [class*=fa] {
  color: #1babfe
}

.note-success {
  background: rgba(0,210,133,0.15)
}

.note-success p,.note-success .note-text {
  color: rgba(0,210,133,0.9) !important
}

.note-success [class*=fa] {
  color: #00d285
}

.note-danger {
  background: rgba(255,104,104,0.05)
}

.note-danger p,.note-danger .note-text {
  color: rgba(255,104,104,0.9) !important
}

.note-danger [class*=fa] {
  color: rgba(255,104,104,0.9)
}

.notes {
  font-size: 12px;
  color: #758698
}

.notes li:not(:first-child),.notes .note li:not(:first-child) {
  margin-top: 7px
}

@media (min-width: 576px) {
  .note p,.note .note-text {
      font-size:12px !important
  }
}

@media (min-width: 992px) {
  .note-md p,.note-md .note-text {
      font-size:14px !important
  }

  .note-md [class*=fa] {
      font-size: 12px
  }
}

.modal-dialog {
  margin: 35px 16px;
  min-width: 280px
}

.modal-dialog-bottom {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - (.5rem * 2))
}

.modal.fade .modal-dialog-bottom {
  -webkit-transform: translate(0, 25%);
  transform: translate(0, 25%)
}

.modal.show .modal-dialog-bottom {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0)
}

.modal-content {
  position: relative;
  border-radius: 8px;
  padding: 6px 0;
  box-shadow: 0px 10px 55px 0px rgba(0,0,0,0.2);
  border: 0
}

.modal-close {
  position: absolute;
  top: -14px;
  right: -14px;
  display: inline-block
}

.modal-close .ti {
  font-size: 13px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  color: #495463;
  text-shadow: none;
  display: block;
  transition: all .4s;
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.3)
}

.modal .more-tigger {
  width: 32px;
  height: 32px
}

.modal .more-tigger .ti {
  line-height: 32px
}

.modal-backdrop {
  background: #181e2b
}

@media (min-width: 576px) {
  .modal-dialog {
      margin:35px auto
  }

  .modal-dialog-sm {
      min-width: 500px
  }

  .modal-dialog-md {
      min-width: 90%
  }

  .modal-dialog-lg {
      min-width: 540px
  }

  .modal-dialog-bottom {
      min-height: calc(100% - (1.75rem * 2))
  }
}

@media (min-width: 768px) {
  .modal-dialog-md {
      min-width:620px
  }

  .modal-dialog-lg {
      min-width: 740px
  }

  .modal-close {
      top: -22px;
      right: -22px
  }

  .modal-close .ti {
      font-size: 20px;
      height: 44px;
      width: 44px;
      line-height: 44px
  }
}

@media (min-width: 992px) {
  .modal-dialog-lg {
      min-width:880px
  }
}

.popup-header {
  padding: 10px 18px 25px;
  border-bottom: 1px solid #e6effb
}

.popup-body {
  padding: 15px 20px 16px;
  font-size: 13px
}

.popup-body-innr {
  padding-left: 18px;
  padding-right: 18px
}

.popup-body-full {
  padding-left: 0;
  padding-right: 0
}

.popup-body-lg {
  padding: 14px 18px 7px
}

.popup-body p {
  font-weight: 400
}

.popup-body .lead strong,.popup-body .lead span {
  color: #00d285
}

.popup-body form:last-child {
  margin-bottom: 0
}

.popup-body .copy-address {
  font-size: 13px
}

.popup-title {
  color: #342d6e;
  font-weight: 600;
  padding-right: 40px;
  margin-bottom: 12px;
  font-size: 1.3em
}

.popup-title .ti {
  font-size: 70%;
  color: #6e81a9
}

.popup-title small {
  color: #758698
}

.popup-title-action {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: 2px;
  transform: translateY(-50%)
}

.popup-subtitle {
  text-transform: uppercase;
  font-weight: 500;
  color: #6e81a9;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  font-size: 12px;
  margin-bottom: 10px
}

.popup-subtitle-s2 {
  color: #495463;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500
}

.popup-footer {
  padding: 15px 18px 0
}

@media (min-width: 480px) {
  .popup-title-action {
      margin-top:0
  }

  .popup-header {
      padding: 30px 40px 25px
  }

  .popup-body {
      padding: 34px 45px 38px
  }

  .popup-body-innr {
      padding-left: 40px;
      padding-right: 40px
  }

  .popup-body-lg {
      padding: 40px 55px
  }

  .popup-body-md {
      padding: 30px 30px 34px
  }

  .popup-footer {
      padding: 30px 40px
  }
}

@media (min-width: 768px) {
  .popup-body {
      font-size:14px
  }

  .popup-body-lg {
      padding: 40px 55px
  }

  .popup-body .copy-address {
      font-size: 13px
  }
}

.nav-tabs {
  border-bottom: none;
  margin: 0 -15px;
  margin-bottom: 10px
}

.nav-tabs .nav-link {
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #758698;
  padding: 6px 15px
}

.nav-tabs .nav-link.active {
  color: #00d285
}

.nav-tabs-line {
  margin: 0;
  margin-bottom: 20px;
  border-bottom: 2px solid #d3e0f3
}

.nav-tabs-line.nav-tabs-vr {
  border-bottom: none
}

.nav-tabs-line .nav-item {
  padding: 0 12px 0 0;
  margin-bottom: 0
}

.nav-tabs-line .nav-item:last-child {
  padding: 0
}

.nav-tabs-line .nav-link {
  border: none;
  padding: 6px 3px;
  letter-spacing: 0.02em;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: #758698;
  position: relative
}

.nav-tabs-line .nav-link:after {
  position: absolute;
  left: 0;
  bottom: -2px;
  content: '';
  width: 0;
  height: 2px;
  background: #00d285;
  transition: all .4s
}

.nav-tabs-line .nav-link.active {
  cursor: default;
  color: #00d285
}

.nav-tabs-line .nav-link.active:after {
  width: 100%
}

.nav-tabs-bordered {
  display: block;
  margin: 0 -7px;
  border-bottom: 0;
  display: flex
}

.nav-tabs-bordered .nav-item {
  margin-bottom: 0;
  flex-grow: 1;
  padding: 0 7px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  width: 100%
}

.nav-tabs-bordered .nav-link {
  text-align: center;
  display: block;
  border: 2px solid #d2dde9;
  border-radius: 4px;
  color: #758698;
  padding: 8px 18px;
  font-size: 11px;
  height: 100%
}

.nav-tabs-bordered .nav-link span {
  font-size: 12px;
  display: inline-block;
  margin-left: 15px;
  color: #758698
}

.nav-tabs-bordered .nav-link.active {
  border: 2px solid #00d285;
  color: #00d285
}

.nav-tabs-bordered .nav-link.active span {
  color: #495463
}

.nav-tabs-icon {
  position: relative;
  width: 44px;
  flex-shrink: 0
}

.nav-tabs-icon img {
  transition: all .4s;
  width: 100%
}

.nav-tabs-icon img:first-of-type {
  opacity: 1
}

.nav-tabs-icon img:last-of-type {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}

.active>.nav-tabs-icon img:first-of-type {
  opacity: 0
}

.active>.nav-tabs-icon img:last-of-type {
  opacity: 1
}

.nav-tabs-vr {
  border-top: 1px solid #f8fafe;
  margin: 0
}

.nav-tabs-vr .nav-item {
  width: 100%;
  padding: 0;
  margin-bottom: 0
}

.nav-tabs-vr.rad .nav-item {
  overflow: hidden
}

.nav-tabs-vr.rad .nav-item:first-child {
  border-radius: 4px 4px 0 0
}

.nav-tabs-vr.rad .nav-item:last-child {
  border-radius: 0 0 4px 4px
}

.nav-tabs-vr .nav-link {
  border-radius: 0;
  padding: 10px 20px;
  border-bottom: 1px solid #f8fafe
}

.nav-tabs-vr .nav-link:hover,.nav-tabs-vr .nav-link.active {
  border-bottom: 1px solid #f8fafe
}

@media (min-width: 480px) {
  .nav-tabs-line .nav-item {
      padding:0 30px 0 0
  }

  .nav-tabs-line .nav-link {
      font-size: 13px
  }

  .nav-tabs-vr .nav-item {
      padding: 0
  }

  .nav-tabs-bordered .nav-item {
      width: auto
  }
}

@media (min-width: 576px) {
  .nav-tabs .nav-item {
      width:auto
  }

  .nav-tabs .nav-link {
      font-size: 13px
  }

  .nav-tabs-bordered .nav-item {
      padding: 0 15px;
      margin-bottom: 25px
  }

  .nav-tabs-bordered .nav-link {
      font-size: 14px
  }

  .nav-tabs-vr .nav-item {
      padding: 0;
      width: 100%
  }
}

@media (min-width: 768px) {
  .nav-tabs-line .nav-item {
      padding:0 40px 0 0
  }

  .nav-tabs-line .nav-link {
      font-size: 14px
  }

  .nav-tabs-vr .nav-item {
      padding: 0
  }
}

@media (min-width: 992px) {
  .nav-tabs .nav-link {
      font-size:14px
  }
}

[class*=collapse-icon] {
  position: relative
}

[class*=collapse-icon]:after {
  position: absolute;
  top: 50%;
  content: '\f107';
  font-family: 'Font Awesome';
  font-weight: 700;
  transform: translateY(-50%) rotate(-180deg);
  transition: all .4s;
  color: #758698
}

[class*=collapse-icon].collapsed:after {
  transform: translateY(-50%) rotate(0deg)
}

[class*=collapse-icon].active:after {
  transform: translateY(-50%) rotate(0deg)
}

.collapse-icon-left:after {
  left: -14px
}

.collapse-icon-right:after {
  right: 0
}

@media (min-width: 480px) {
  .collapse-icon-left:after {
      left:-22px
  }
}

@media (min-width: 1200px) {
  .collapse-icon-left:after {
      opacity:0
  }

  .collapse-icon-left:hover:after {
      opacity: 1
  }
}

.accordion-item {
  padding-bottom: 5px
}

.accordion-item:last-child {
  padding-bottom: 0
}

.accordion-heading {
  cursor: pointer;
  color: #342d6e;
  font-weight: 500;
  transition: all .4s;
  margin-bottom: 8px;
  padding-left: 20px;
  font-size: 1em;
  position: relative
}

.accordion-heading:before,.accordion-heading:after {
  position: absolute;
  content: ''
}

.accordion-heading:before {
  left: 0;
  top: 10px;
  height: 1px;
  width: 11px;
  background: #758698
}

.accordion-heading:after {
  left: 5px;
  top: 5px;
  height: 11px;
  width: 1px;
  background: #758698;
  transform: scaleX(1) scaleY(0);
  transition: all .3s ease
}

.accordion-heading.collapsed {
  color: #758698
}

.accordion-heading.collapsed:after {
  transform: scaleX(1) scaleY(1)
}

.accordion-heading:hover {
  color: #342d6e
}

.accordion-content {
  padding: 0 0 15px 20px
}

.accordion-s2 .accordion-heading {
  padding-left: 0;
  padding-right: 20px
}

.accordion-s2 .accordion-heading:before {
  left: auto;
  right: 0
}

.accordion-s2 .accordion-heading:after {
  left: auto;
  right: 5px
}

.accordion-s2 .accordion-content {
  padding: 0 20px 15px 0
}

.bg-primary {
  background: #00d285 !important
}

.bg-secondary {
  background: #342d6e !important
}

.bg-alternet {
  background: #FFE969 !important
}

.bg-default {
  background: #697382 !important
}

.bg-info {
  background: #1babfe !important
}

.bg-success {
  background: #00d285 !important
}

.bg-warning {
  background: #ffc100 !important
}

.bg-danger {
  background: #ff6868 !important
}

.bg-purple {
  background: #bc69fb !important
}

.bg-light {
  background: #f7fafd !important
}

.bg-light-alt {
  background: #e6effb !important
}

.bg-lighter {
  background: #f9fcff !important
}

.bg-white {
  background: #fff !important
}

.text-primary {
  color: #00d285 !important
}

.text-secondary {
  color: #342d6e !important
}

.text-alternet {
  color: #FFE969 !important
}

.text-default {
  color: #697382 !important
}

.text-info {
  color: #1babfe !important
}

.text-success {
  color: #00d285 !important
}

.text-warning {
  color: #ffc100 !important
}

.text-danger {
  color: #ff6868 !important
}

.text-purple {
  color: #bc69fb !important
}

.text-light {
  color: #758698 !important
}

.text-exlight {
  color: #6e81a9 !important
}

.text-dark {
  color: #495463 !important
}

.text-head {
  color: #342d6e !important
}

.text-ht {
  color: #00d285 !important
}

.badge {
  padding: 2px 8px;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  border-radius: 3px;
  font-weight: 500;
  color: #fff;
  border: 1px solid;
  min-width: 60px;
  vertical-align: middle
}

.badge-sq {
  height: 19px;
  width: 19px;
  min-width: 0;
  padding-left: 2px;
  padding-right: 2px
}

.badge-sm {
  padding: 4px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  min-width: 90px
}

.badge-md {
  padding: 6px 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  min-width: 90px
}

.badge-md.badge-sq {
  height: 30px;
  width: 30px;
  min-width: 0;
  padding: 6px 0
}

.badge-lg {
  padding: 6px 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  min-width: 120px
}

.badge-xl {
  padding: 9px 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  min-width: 120px
}

.badge-xs {
  padding: 1px 5px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  min-width: 48px
}

.badge-xs.round {
  border-radius: 9px
}

.badge-xs.badge-sq {
  height: 16px;
  width: 16px;
  min-width: 0;
  padding-left: 2px;
  padding-right: 2px
}

.badge-block {
  width: 100%
}

.badge-auto {
  min-width: 0
}

.badge-circle {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 5px 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400
}

.badge-circle.badge-sm {
  height: 20px;
  width: 20px;
  font-size: 11px;
  line-height: 12px
}

.badge.badge-dim {
  color: #495463
}

.badge-primary {
  background: #00d285;
  border-color: #00d285
}

.badge-primary.badge-dim {
  background: #fff;
  border-color: #e9e7ff
}

.badge-light {
  background: #758698;
  border-color: #758698;
  color: #fff
}

.badge-light.badge-dim {
  background: #eaecef;
  border-color: #cdd3d9
}

.badge-lighter {
  color: #495463;
  background: #e6effb;
  border-color: #e6effb
}

.badge-lighter.badge-dim {
  color: #495463;
  border-color: #a4c5f0
}

.badge-dark {
  color: #fff;
  background: #495463;
  border-color: #495463
}

.badge-dark.badge-dim {
  background: #dee2e7;
  border-color: #b2bac6
}

.badge-secondary {
  background: #342d6e;
  border-color: #342d6e
}

.badge-secondary.badge-dim {
  background: #cdcae9;
  border-color: #aca6db
}

.badge-success {
  background: #00d285;
  border-color: #00d285
}

.badge-success.badge-dim {
  background: #c8ffeb;
  border-color: #53ffc0
}

.badge-info {
  background: #1babfe;
  border-color: #1babfe
}

.badge-info.badge-dim {
  background: #f0faff;
  border-color: #cdecff
}

.badge-warning {
  background: #ffc100;
  border-color: #ffc100
}

.badge-warning.badge-dim {
  background: #fff5d6;
  border-color: #ffe080
}

.badge-danger {
  background: #ff6868;
  border-color: #ff6868
}

.badge-danger.badge-dim {
  background: #ffe8e8;
  border-color: #ffb5b5
}

.badge-purple {
  background: #bc69fb;
  border-color: #bc69fb
}

.badge-purple.badge-dim {
  background: #f3e5fe;
  border-color: #ddb4fd
}

.badge-disabled {
  color: #495463;
  background: #d2dde9;
  border-color: #d2dde9
}

.badge-disabled.badge-dim {
  color: #5f6d80;
  background: #f4f7fa;
  border-color: #e3eaf1
}

.badge-outline {
  background: none;
  color: #495463
}

.status {
  padding: 5px 20px;
  line-height: 22px;
  font-size: 14px;
  display: inline-block;
  border-radius: 4px
}

.status-primary {
  color: #00d285;
  background: rgba(118,104,254,0.15)
}

.status-secondary {
  color: #342d6e;
  background: rgba(52,45,110,0.15)
}

.status-success {
  color: #00d285;
  background: rgba(0,210,133,0.15)
}

.status-info {
  color: #1babfe;
  background: rgba(27,171,254,0.15)
}

.status-warning {
  color: #ffc100;
  background: rgba(255,193,0,0.15)
}

.status-danger {
  color: #ff6868;
  background: rgba(255,104,104,0.15)
}

.status-purple {
  color: #bc69fb;
  background: rgba(188,105,251,0.15)
}

.icon-90deg {
  transform: rotate(-90deg);
  display: inline-block
}

.hr {
  border: 0;
  display: block;
  width: 100%;
  border-top: 1px solid #d2dde9;
  margin: 35px 0
}

.hr:first-child {
  margin-top: 0
}

.hr:last-child {
  margin-bottom: 0
}

.hr.hr2 {
  margin-top: 15px
}

.hr+.card-head {
  margin-top: -5px
}

.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.code-block {
  background-image: linear-gradient(#fff 50%, #f9fafc 50%);
  background-size: 56px 56px;
  border: 1px solid #eef2f8;
  display: block;
  line-height: 28px;
  padding: 0 15px
}

.code-block+.code-block {
  margin-top: 28px
}

.table {
  border-spacing: 0;
  border-collapse: separate
}

.table td {
  padding: 8px 0;
  border: none;
  white-space: nowrap;
  vertical-align: middle
}

.table td .lead {
  font-size: 14px;
  font-weight: 600;
  color: #495463;
  letter-spacing: 0.01em;
  line-height: 1;
  margin-top: 0;
  margin-right: 4px
}

.table td .sub {
  font-size: 12px;
  font-weight: 400;
  color: #758698;
  letter-spacing: 0.01em;
  line-height: 1;
  padding-top: 7px
}

.table td .sub-s2 {
  font-size: 13px;
  color: #495463;
  padding-top: 0
}

.table td .sub:first-child {
  padding-top: 0
}

.table td>*:not(.data-state) {
  padding-right: 5px
}

.table thead th {
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 700;
  color: #00d285
}

.table th {
  padding: 5px 0
}

.table td>span {
  padding-right: 5px
}

.table.table-flat {
  font-size: 0.9em
}

.table.table-flat td,.table.table-flat th {
  padding: 5px 0;
  line-height: 1.4;
  border-bottom: 1px dashed #e6effb
}

.table.table-flat tr:last-child td,.table.table-flat tr:last-child th {
  border-bottom: 0 none
}

.table.tnx-table td {
  padding: 10px 0
}

.table.table-plain td {
  padding: 10px 0
}

.table.table-plain th {
  padding: 5px 0
}

.table.table-lg td {
  padding-top: 10px;
  padding-bottom: 10px
}

.table.table-lg th {
  padding-top: 5px;
  padding-bottom: 10px
}

.table.table-even-odd td {
  padding: 15px 20px
}

.table-even-odd tr th,.table-even-odd tr td {
  padding: 15px 20px
}

.table-even-odd tr td {
  background: #fff
}

.table-even-odd tr:nth-child(even) td {
  background: #f8fafe
}

.table-even-odd tr td:first-child {
  border-left: 1px solid #e6effb
}

.table-even-odd tr td:last-child {
  border-right: 1px solid #e6effb
}

.table-even-odd tr:last-child td {
  border-bottom: 1px solid #e6effb
}

.table-even-odd tr:last-child td:first-child {
  border-bottom-left-radius: 4px
}

.table-even-odd tr:last-child td:last-child {
  border-bottom-right-radius: 4px
}

.table-even-odd thead tr th {
  background: #e6effb
}

.table-even-odd thead tr th:first-child {
  border-top-left-radius: 4px
}

.table-even-odd thead tr th:last-child {
  border-top-right-radius: 4px
}

.table-bordered {
  border: none
}

.table-bordered-plain thead th {
  border-bottom: 1px solid #eff5fc
}

.table-bordered-plain tbody td,.table-bordered-plain tbody th {
  border-bottom: 1px solid #eff5fc
}

.table-bordered th,.table-bordered td {
  border: 1px solid #eff5fc;
  border-top: 0;
  padding-left: 20px;
  padding-right: 20px
}

.table-bordered th:not(:last-child),.table-bordered td:not(:last-child) {
  border-right: none
}

.table-bordered thead th {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #eff5fc
}

.table-bordered thead th:not(:last-child) {
  border-right: none
}

.table-borderless tr {
  border-bottom: none
}

.table-transparent tr {
  background: transparent
}

.table-transparent td,.table-transparent th {
  padding: 15px 20px
}

.table-faq h5,.table-page h5 {
  color: #495463
}

.table-faq p,.table-page p {
  color: #758698
}

@media (min-width: 576px) {
  .table-transparent td,.table-transparent th {
      padding:15px 30px
  }
}

.table th {
  border-top: none
}

.table-plain {
  width: 100%
}

.table-plain-row {
  display: flex;
  flex-wrap: wrap
}

.table-plain-cell {
  width: 50%;
  padding: 8px 15px 8px 0
}

.table-plain-3clm .table-plain-cell {
  width: 100%
}

.table-plain-cell.head {
  font-weight: 500
}

@media (min-width: 768px) {
  .table-plain-4clm .table-plain-cell {
      width:25%
  }

  .table-plain-3clm .table-plain-cell {
      width: 33.33%
  }

  .table.table-flat {
      font-size: 0.85em
  }
}

.card {
  border-radius: 4px;
  margin-bottom: 15px;
  border: none;
  background: #fff;
  transition: background .4s, border-color .4s, color .4s;
  vertical-align: top
}

.card-shadow {
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.05)
}

.card-shadow:hover {
  box-shadow: 0px 15px 50px 0px rgba(0,0,0,0.09)
}

.card-full-height {
  height: calc(100% - 15px)
}

.card-gradient-pri-sec {
  background-image: linear-gradient(45deg, #00d285 0%, #00d285 100%)
}

.card-innr {
  position: relative;
  padding: 15px 18px;
  border-color: #e6effb !important
}

.card-innr>.card-title {
  padding-bottom: 10px
}

.card-innr>div:last-child:not(.input-item):not(.row):not(.step-head):not(.tile-footer):not(.tab-content):not(.progress-bar):not(.chart-tokensale):not(:only-child) {
  margin-bottom: 5px
}

.account-info .card-innr>div:last-child:not(.input-item):not(.row):not(.step-head):not(.tile-footer):not(.tab-content):not(.progress-bar):not(.chart-tokensale):not(:only-child) {
  margin-bottom: 0
}

.card-innr-fix {
  padding-bottom: 20px
}

.card-innr-fix-x {
  padding-top: 20px;
  padding-bottom: 20px
}

.card-innr-split {
  padding: 0
}

.card-innr-split>div {
  padding: 15px 18px;
  width: 100%
}

.card-title {
  color: #342d6e;
  font-size: 1.3em;
  font-weight: 500;
  margin: 0
}

.card-title-lg {
  font-size: 1.5em
}

.card-title-md {
  font-size: 1.2em
}

.card-title-sm {
  font-size: 1.05em
}

.card-title:last-child {
  margin-bottom: 0
}

.card-title-text {
  width: 100%
}

.card-title-text:not(:first-child) {
  margin-top: 4px
}

.card-title+.btn-grp {
  margin-top: -5px
}

.card-title>.badge {
  margin-left: 6px
}

.card-title-s2 {
  width: 100%
}

.card-title-s2 span {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 90%;
  letter-spacing: 0.1em
}

.card-sub-title {
  text-transform: uppercase;
  color: #758698;
  letter-spacing: 0.07em;
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px
}

.card-sub-title-s2 {
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #00d285
}

.card-head {
  padding-bottom: 18px
}

.card-head.has-aside {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center
}

.card-head:last-child {
  padding-bottom: 0
}

.card-head+.card-bordered {
  margin-top: 4px
}

.card-opt {
  position: relative
}

.card-footer {
  background: #fff
}

.card .content:not(:first-child) {
  margin-top: 10px
}

.card-token {
  background-image: linear-gradient(45deg, #00d285 0%, #00d285 100%);
  color: #fff
}

.card-token .card-sub-title {
  color: #FFE969;
  margin-bottom: 4px
}

.card-token .card-sub-title-s2 {
  color: #fff
}

.card.kyc-form-steps,.card.card-woa {
  transition: none
}

.card-calc .note p {
  font-size: 11px !important
}

.card-text-light {
  color: #fff
}

.card-text-light .card-title,.card-text-light .card-sub-title {
  color: #fff
}

.card-text-light .card-opt>a {
  color: #fff !important
}

.card-text-light .card-opt>a:after {
  border-top-color: #fff
}

.card-text-light p {
  color: #fff
}

.card-dark {
  background: #181e2b
}

.card-primary {
  background: #00d285
}

.card-secondary {
  background: #342d6e
}

.card-success {
  background: #00d285
}

.card-warning {
  background: #ffc100
}

.card-info {
  background: #1babfe
}

.card-danger {
  background: #ff6868
}

.card-navs .card-innr {
  padding-top: 14px;
  padding-bottom: 14px
}

.card-timeline .card-innr {
  height: calc(100% - 32px)
}

.card-bordered {
  border: 1px solid #e6effb;
  padding: 25px;
  border-radius: 4px
}

.card-data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px
}

.card-data-text {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: #495463
}

.card-data-text span {
  font-size: 12px;
  font-weight: 500;
  display: block;
  text-transform: uppercase;
  margin-top: 12px;
  color: #6e81a9
}

.card-dark .card-data-text span {
  color: #b9d2f2
}

.card-dark .card-data-text {
  color: #fff
}

.card-data-text-s2 {
  color: #6e81a9;
  font-size: 16px;
  font-weight: 500
}

.card-data-text-s2 span {
  font-size: 13px;
  font-weight: 400
}

.card-dark .card-data-text-s2 {
  color: #b9d2f2
}

.card-action {
  border-top: 1px solid #eef2f8
}

.card-action-s1 {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.card-action-info {
  text-align: right;
  font-size: 11px;
  line-height: 16px;
  color: #758698
}

.card-action-info span {
  text-transform: uppercase;
  display: block;
  color: #495463
}

@media (min-width: 410px) {
  .card-token .token-balance-list li {
      min-width:25%
  }
}

@media (min-width: 576px) {
  .card {
      margin-bottom:30px
  }

  .card-title {
      font-size: 1.4em
  }

  .card-title-lg {
      font-size: 1.5em
  }

  .card-title-sm {
      font-size: 1.09em;
      padding-top: 2px
  }

  .card-title-md {
      font-size: 1.25em
  }

  .card-innr {
      padding: 20px 25px
  }

  .card-innr-fix {
      padding-bottom: 25px
  }

  .card-innr-fix-x {
      padding-top: 25px;
      padding-bottom: 25px
  }

  .card-innr-fix2 {
      padding-bottom: 10px
  }

  .card-innr-split {
      padding: 0;
      display: flex
  }

  .card-innr-split>div {
      padding: 20px 25px
  }

  .card-action:not(:last-child) {
      border-right: 1px solid #eef2f8
  }

  .card-full-height {
      height: calc(100% - 30px)
  }

  .card-timeline .card-innr {
      height: calc(100% - 50px)
  }
}

@media (min-width: 992px) {
  .card-navs .card-innr {
      padding-top:25px;
      padding-bottom: 25px
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .card-token .token-balance-list li {
      min-width:84px
  }
}

@media (min-width: 1200px) {
  .card-token .token-balance-list li {
      min-width:33.33%
  }
}

.tile {
  padding: 18px 20px 18px;
  position: relative;
  height: calc(100% - 30px)
}

.tile-nav {
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px
}

.tile-nav+.token-balance {
  margin-top: -1px !important;
  padding-bottom: 2px
}

.tile-nav li {
  padding: 0 1px
}

.tile-nav li:last-child {
  padding-right: 0
}

.tile-nav li a {
  line-height: 17px;
  padding: 2px 7px 2px 9px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #6e81a9
}

.card-token .tile-nav li a {
  color: #fff
}

.tile-nav li a.active {
  color: #758698;
  background: rgba(117,134,152,0.15)
}

.card-token .tile-nav li a.active {
  color: #fff;
  background: rgba(255,255,255,0.15)
}

.tile-header {
  position: relative;
  margin-bottom: 9px
}

.tile-title {
  font-size: 12px;
  font-weight: 500;
  color: #6e81a9;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-bottom: 0
}

.tile-title-s2 {
  font-size: 16px;
  color: #495463;
  text-transform: capitalize;
  letter-spacing: normal
}

.card-gradient-pri-sec .tile-title {
  color: #84fff2
}

.tile-action {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 20px
}

.tile-action .toggle-tigger {
  padding: 5px;
  color: #758698
}

.tile-data {
  display: flex;
  align-items: center;
  margin-bottom: 8px
}

.tile-data-number {
  font-size: 24px;
  line-height: 1;
  color: #495463;
  font-weight: 500
}

.tile-data-status {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 3px 24px 3px 12px;
  border-radius: 12px;
  margin: 0 12px;
  position: relative
}

.tile-data-status:after {
  position: absolute;
  height: 0;
  width: 0;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent
}

.tile-data-active {
  padding: 3px 12px;
  color: #1babfe;
  background: rgba(27,171,254,0.15)
}

.tile-data-active:after {
  display: none
}

.tile-data-up {
  background: rgba(0,210,133,0.15);
  color: #00d285
}

.tile-data-up:after {
  border-bottom: 5px solid #00d285
}

.tile-data-down {
  background: rgba(255,104,104,0.15);
  color: #ff6868
}

.tile-data-down:after {
  border-top: 5px solid #ff6868
}

.tile-data-text {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: #495463
}

.tile-data-text span {
  font-size: 12px;
  font-weight: 400
}

.tile-data-text-s2 span {
  font-size: 14px;
  color: #758698
}

.tile-text-light .tile-data-text {
  color: #fff
}

.tile-data-s2 {
  justify-content: space-between;
  margin-bottom: 14px
}

.tile-recent {
  color: #6e81a9;
  line-height: 1
}

.tile-recent-number {
  font-size: 16px;
  display: inline-block;
  margin-right: 3px;
  font-weight: 700
}

.tile-recent-text {
  font-size: 13px;
  font-weight: 400
}

.tile-info-item {
  min-width: 80px
}

.tile-info-list {
  display: flex
}

.tile-info-lead {
  font-size: 16px;
  font-weight: 500;
  color: #00d285;
  display: block;
  line-height: 1
}

.tile-text-light .tile-info-lead {
  color: #fff
}

.tile-info-sub {
  font-size: 12px;
  color: #758698;
  font-weight: 400;
  line-height: 1
}

.tile-text-light .tile-info-sub {
  color: #fff
}

.tile-footer {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: baseline
}

@media (min-width: 576px) {
  .tile {
      padding:24px 30px 22px
  }

  .tile-nav {
      top: 18px;
      right: 25px
  }

  .tile-action {
      top: 18px;
      right: 18px
  }

  .tile-data-number {
      font-size: 32px
  }

  .tile-data-status {
      font-size: 14px;
      line-height: 20px;
      padding: 5px 28px 5px 14px;
      border-radius: 15px
  }

  .tile-data-status:after {
      right: 14px
  }

  .tile-data-active {
      padding: 5px 14px
  }

  .tile-data-text {
      font-size: 20px
  }

  .tile-recent-number {
      font-size: 18px
  }

  .tile-info-lead {
      font-size: 20px
  }

  .tile-footer {
      padding-top: 14px
  }
}

.progress-info {
  display: flex;
  justify-content: space-between
}

.progress-info li {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #495463;
  line-height: 1.4
}

.progress-info li span {
  color: #758698;
  display: block;
  font-size: 12px
}

.progress-bar {
  display: block;
  color: #758698;
  background: #b1becc;
  height: 6px;
  border-radius: 3px;
  margin: 12px 0 45px;
  position: relative
}

.progress-bar+span,.progress-bar+div {
  padding-top: 30px
}

.progress-hcap,.progress-scap,.progress-psale,.progress-percent {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 3px
}

.progress-hcap>div,.progress-scap>div,.progress-psale>div,.progress-percent>div {
  position: absolute;
  right: 0;
  transform: translateX(50%);
  width: 120px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  padding: 25px 0 0 0;
  text-transform: uppercase
}

.progress-hcap>div:after,.progress-scap>div:after,.progress-psale>div:after,.progress-percent>div:after {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  width: 2px;
  height: 20px;
  content: '';
  background: rgba(78,92,110,0.3)
}

.progress-hcap>div span,.progress-scap>div span,.progress-psale>div span,.progress-percent>div span {
  display: block;
  color: #495463
}

.progress-hcap {
  background: #d2dde9
}

.progress-scap {
  background: #8299d3
}

.progress-psale {
  background: #8299d3
}

.progress-percent {
  background: #00d285
}

.progress-percent:after {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  height: 18px;
  width: 18px;
  border: 3px solid #00d285;
  background: #fff;
  border-radius: 50%;
  transform: translate(50%, -50%)
}

.no-had-soft {
  margin-bottom: 10px
}

.list-check li {
  color: #495463;
  position: relative;
  padding-left: 20px
}

.list-check li:not(:last-child) {
  padding-bottom: 6px
}

.list-check li:before {
  position: absolute;
  left: 0;
  top: 2px;
  font-family: Font Awesome;
  content: '\f00c';
  font-weight: 700;
  color: #6e81a9;
  margin-right: 4px;
  font-size: 12px
}

.list-s1 {
  padding: 10px 0
}

.list-s1 li {
  padding: 10px 15px;
  margin: 8px 0;
  border-radius: 3px;
  border: 1px solid #e6effb
}

.list-s2 {
  padding: 10px 0
}

.list-s2 li {
  padding-top: 12px;
  padding-bottom: 12px
}

.list-s2 li:not(:last-child) {
  border-bottom: 1px solid #e6effb
}

.list-content {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.list-col2x,.list-col3x,.list-col4x,.list-col5x {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px
}

.list-col2x>li,.list-col3x>li,.list-col4x>li,.list-col5x>li {
  margin-left: 15px;
  margin-right: 15px;
  flex-shrink: 0
}

.list-col2x>li {
  width: calc(50% - 30px)
}

@media (min-width: 576px) {
  .list-st {
      font-size:0.9em
  }

  .list-col3x>li,.list-col4x>li,.list-col5x>li {
      width: calc(50% - 30px)
  }
}

@media (min-width: 768px) {
  .list-col3x>li {
      width:calc(33.33% - 30px)
  }

  .list-col4x>li,.list-col5x>li {
      width: calc(25% - 30px)
  }
}

@media (min-width: 992px) {
  .list-col5x>li {
      width:calc(20% - 30px)
  }
}

.status {
  border-radius: 4px;
  text-align: center;
  padding: 24px 10px;
  width: 100%
}

.status-icon {
  position: relative;
  height: 90px;
  width: 90px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 20px;
  border: 2px solid #b1becc
}

.status-icon>.ti {
  color: #b1becc;
  font-size: 36px;
  line-height: 86px
}

.status-icon-sm {
  position: absolute;
  right: -2px;
  bottom: -2px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #ffc7c7
}

.status-icon-sm>.ti {
  font-size: 12px;
  line-height: 22px;
  color: #ffc7c7;
  display: block
}

.status-text {
  display: block;
  font-size: 1.29em;
  line-height: 1.6;
  font-weight: 400;
  color: #758698;
  letter-spacing: -0.01em;
  padding-bottom: 13px
}

.status-text.large {
  font-size: 1.314em
}

.status .btn {
  margin-top: 20px
}

.status-empty .status-icon {
  border-color: #b1becc
}

.status-empty .status-icon>.ti {
  color: #b1becc
}

.status-thank .status-icon,.status-verified .status-icon {
  border-color: #06d388
}

.status-thank .status-icon>.ti,.status-verified .status-icon>.ti {
  color: #06d388
}

.status-thank .status-icon-sm,.status-verified .status-icon-sm {
  border-color: #06d388
}

.status-thank .status-icon-sm>.ti,.status-verified .status-icon-sm>.ti {
  color: #06d388
}

.status-verified .status-text {
  color: #06d388
}

.status-process .status-icon {
  border-color: #50b3ff
}

.status-process .status-icon>.ti {
  color: #50b3ff
}

.status-process .status-icon-sm {
  border-color: #50b3ff
}

.status-process .status-icon-sm>.ti {
  color: #50b3ff
}

.status-canceled .status-icon {
  border-color: #ffc7c7
}

.status-canceled .status-icon>.ti {
  color: #ffc7c7
}

.status-canceled .status-icon-sm {
  border-color: #ffc7c7
}

.status-canceled .status-icon-sm>.ti {
  color: #ffc7c7
}

.status-canceled .status-text {
  color: #ffc7c7
}

.status-warnning .status-icon {
  border-color: #ffd147
}

.status-warnning .status-icon>.ti {
  color: #ffd147
}

.status-warnning .status-icon-sm {
  border-color: #ffd147
}

.status-warnning .status-icon-sm>.ti {
  color: #ffd147
}

@media (min-width: 576px) {
  .status {
      padding:45px 40px
  }

  .status-text.large {
      font-size: 1.65em
  }

  .status-sm {
      padding: 20px
  }

  .status-lg {
      padding: 50px
  }
}

.copy-wrap {
  position: relative
}

.copy-wrap .copy-icon {
  position: absolute;
  top: 0;
  left: 14px;
  line-height: 20px;
  padding: 12px 0;
  font-size: 16px;
  text-align: center;
  color: #495463
}

.copy-wrap .copy-icon.ikon {
  left: 10px;
  font-size: 24px
}

.copy-trigger {
  position: absolute;
  right: 4px;
  top: 4px;
  height: 38px;
  width: 38px;
  line-height: 20px;
  padding: 10px 0;
  text-align: center;
  color: #758698;
  background: #e9eff9;
  border-radius: 4px;
  border: none;
  transition: all .4s;
  cursor: pointer
}

.copy-trigger:focus,.copy-trigger:hover {
  color: #fff;
  background: #00d285;
  outline: none
}

.copy-address {
  border: none;
  color: #495463;
  line-height: 20px;
  padding: 12px 50px 12px 40px;
  border-radius: 4px;
  border: 1px solid rgba(211,224,243,0.5);
  width: 100%;
  background: #fff
}

.copy-address:focus {
  outline: none;
  box-shadow: none
}

.copy-feedback {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  line-height: 20px;
  padding: 13px 0;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .05em;
  text-align: center;
  background: rgba(255,255,255,0.9);
  border-radius: 4px;
  border: 1px solid rgba(211,224,243,0.5);
  color: #00d285
}

.countdown-clock {
  display: flex;
  justify-content: space-between;
  margin: 0 -7px;
  max-width: 320px
}

.countdown-clock>div {
  border-radius: 4px;
  border: 1px solid #e6effb;
  margin: 0 7px;
  flex-grow: 1;
  text-align: center;
  padding: 10px 0
}

.countdown-time {
  font-size: 24px;
  color: #495463;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.1em
}

.countdown-text {
  display: block;
  font-weight: 500;
  font-size: 11px;
  color: #758698;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1
}

.card-dark .countdown-text {
  color: #b9d2f2
}

[data-toggle="tooltip"] {
  cursor: help
}

.tooltip-inner {
  background: #495463;
  font-size: 10px;
  color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.07)
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #495463 !important
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #495463 !important
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #495463 !important
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #495463 !important
}

.toggle-tigger {
  display: inline-block;
  cursor: pointer
}

.toggle-tigger .ti {
  display: inline-block
}

.toggle-tigger.rotate .ti {
  transform: rotate(90deg)
}

.toggle-tigger.dt-filter-text {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 19
}

.toggle-caret {
  position: relative;
  padding-right: 16px
}

.toggle-caret:after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  height: 0;
  width: 0;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  border-top: 5px solid #b1becc
}

.toggle-content,.toggle-class {
  display: none
}

.toggle-content.active,.toggle-class.active {
  display: block
}

.toggle-mobile {
  height: 36px;
  width: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center
}

.toggle-mobile-content {
  position: absolute
}

@media (min-width: 576px) {
  .toggle-content,.toggle-class {
      display:none
  }

  .toggle-content.active,.toggle-class.active {
      display: block
  }

  .toggle-mobile {
      display: none
  }

  .toggle-mobile-content {
      position: static;
      display: block
  }
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 4px;
  z-index: 999;
  box-shadow: 0px 0 35px 0px rgba(0,0,0,0.2)
}

.dropdown-content:after {
  position: absolute;
  content: '';
  top: -6px;
  left: 50%;
  margin-left: -7px;
  height: 0;
  width: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff
}

.dropdown-content-up {
  top: auto;
  bottom: calc(100% + 10px)
}

.dropdown-content-up:after {
  top: auto;
  bottom: -7px;
  border-bottom: none;
  border-top: 7px solid #fff
}

.dropdown-content-up-right {
  top: auto;
  bottom: calc(100% + 10px);
  left: auto;
  right: 0;
  transform: translateX(0)
}

.dropdown-content-up-right:after {
  top: auto;
  bottom: -7px;
  right: 16px;
  left: auto;
  border-bottom: none;
  border-top: 7px solid #fff
}

.dropdown-content-up-left {
  top: auto;
  bottom: -6px;
  left: auto;
  right: calc(100% + 5px);
  transform: translateX(0)
}

.dropdown-content-up-left:after {
  left: auto;
  top: auto;
  bottom: 20px;
  right: -14px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff
}

.dropdown-content-top {
  top: auto;
  bottom: calc(100% + 10px)
}

.dropdown-content-top:after {
  top: auto;
  bottom: -7px;
  border-bottom: none;
  border-top: 7px solid #fff
}

.dropdown-content-top-left {
  top: 0;
  left: auto;
  right: calc(100% + 10px);
  transform: translateX(0)
}

.dropdown-content-top-left:after {
  top: 8px;
  left: auto;
  right: -14px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff
}

.dropdown-content-center-left {
  top: 50%;
  left: auto;
  right: calc(100% + 10px);
  transform: translateX(0) translateY(-50%)
}

.dropdown-content-center-left:after {
  top: 50%;
  margin-top: -7px;
  left: auto;
  right: -14px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff
}

.dropdown-content-right {
  left: auto;
  transform: translateX(0);
  right: 0
}

.dropdown-content-right:after {
  left: 90%
}

.dropdown-content.toggle-datatable-filter {
  width: 165px
}

.dropdown-content-md {
  width: 150px
}

.dropdown-content-sm {
  min-width: 100px
}

.dropdown-arrow-left:after {
  left: 16px
}

.dropdown-arrow-right:after {
  left: auto;
  right: 16px
}

.dropdown-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.01em;
  padding: 10px 20px 8px 16px
}

.dropdown-list-s2 .dropdown-title {
  padding: 0
}

.dropdown-list {
  padding: 0 0 1px;
  border-top: 2px solid rgba(230,239,251,0.5)
}

.dropdown-list:last-child {
  border-radius: 0 0 4px 4px
}

.dropdown-list:first-child {
  border-top: 0
}

.dropdown-list li {
  width: 100%;
  border-bottom: 1px solid rgba(230,239,251,0.5)
}

.dropdown-list li:last-child {
  border-bottom: none
}

.dropdown-list li a {
  position: relative;
  text-align: left;
  padding: 6px 32px 6px 16px;
  white-space: nowrap;
  color: #495463;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center
}

.dropdown-list li a .ti,.dropdown-list li a [class*=fa-] {
  margin-right: 10px;
  font-size: 14px;
  width: 22px
}

.dropdown-list li a:after {
  position: absolute;
  content: '\f00c';
  font-family: 'Font Awesome';
  font-weight: 700;
  font-size: 12px;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #495463;
  background: #fff;
  display: none
}

.dropdown-list li a:hover {
  color: #00d285
}

.dropdown-list li.active a:after {
  display: block
}

.dropdown-list-s2 {
  padding: 12px 0
}

.dropdown-list-s2:not(:last-child) {
  border-bottom: 1px solid rgba(230,239,251,0.5)
}

.dropdown-list-s2 li {
  padding: 5px 20px;
  border-bottom: none
}

.dropdown-dt-filter-text {
  right: calc(100% + 42px)
}

.schedule-item {
  padding: 10px 0 10px
}

.schedule-item span:not([class]) {
  display: block
}

.schedule-item+.schedule-item {
  border-top: 1px solid #eef2f8;
  padding-top: 20px
}

.schedule-title {
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 4px
}

.schedule-title span {
  display: inline-block;
  margin-right: 12px
}

.schedule-bonus {
  padding: 8px;
  min-width: 110px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border-radius: 4px;
  color: #00d285;
  display: inline-block;
  border: 2px solid currentColor
}

.user-dropdown:after {
  border-bottom-color: #342d6e
}

.user-welcome {
  display: inline-block;
  margin-right: 10px;
  color: #fff
}

.user-thumb {
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 30px;
  color: #fff;
  border-radius: 50%;
  background: #00d285;
  text-align: center
}

.user-thumb:hover,.user-thumb:focus {
  color: #fff
}

.user-status {
  padding: 20px 25px;
  background: #342d6e;
  border-radius: 4px 4px 0 0;
  min-width: 280px
}

.user-status-title {
  font-size: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFE969;
  margin-bottom: 0
}

.user-status-balance {
  font-size: 24px;
  color: #fff;
  white-space: nowrap;
  line-height: 1;
  padding-top: 4px
}

.user-status-balance small {
  font-size: 16px
}

.user-links {
  padding: 12px 0
}

.user-links:last-child {
  border-radius: 0 0 4px 4px
}

.user-links li a {
  display: block;
  padding: 5px 15px
}

.user-links li a .ti {
  margin-right: 10px
}

.user-photo {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
  background: #00d285;
  text-align: center
}

.user-photo.bg-light,.user-photo.bg-lighter {
  color: #495463
}

.user-photo:hover,.user-photo:focus {
  color: #fff
}

.user-photo img {
  border-radius: 100%;
  vertical-align: baseline
}

.user-photo+.user-info {
  margin-left: 12px
}

.user-block {
  display: flex;
  align-items: center
}

.sap-text {
  padding: 15px 0;
  text-align: center
}

.sap-text span {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.1em;
  display: inline-block;
  text-transform: uppercase;
  padding: 0 12px;
  position: relative
}

.sap-text span:before,.sap-text span:after {
  position: absolute;
  content: '';
  height: 1px;
  width: 40px;
  background: #d2dde9;
  top: 50%;
  margin-top: -1px
}

.sap-text span:before {
  right: 100%
}

.sap-text span:after {
  left: 100%
}

.tnx-table.bdr-tl {
  border-top: 1px solid #e6effb
}

.tnx-type {
  width: 30px
}

.tnx-type-text,.tnx-type-md {
  display: none
}

@media (min-width: 420px) {
  .tnx-type-sm {
      display:none
  }

  .tnx-type-text,.tnx-type-md {
      display: inline-block
  }
}

@media (min-width: 992px) {
  .tnx-table.bdr-tl {
      border-left:1px solid #e6effb;
      border-top: 0
  }
}

.chart-tokensale {
  height: 210px;
  position: relative
}

.chart-tokensale-long {
  height: 230px
}

.chart-tokensale-s2 {
  height: 100%;
  width: 100%
}

.chart-phase {
  height: 190px;
  width: 190px;
  position: relative;
  margin-bottom: 20px
}

.chart-phase canvas {
  position: relative;
  z-index: 2
}

.chart-phase-s2 {
  width: 150px;
  height: 150px
}

.chart-statistics {
  height: 147px
}

@media (min-width: 420px) {
  .chart-phase {
      margin-bottom:0
  }
}

@media (max-width: 374px) {
  .chart-phase-s2 {
      margin-left:auto;
      margin-right: auto
  }
}

@media (min-width: 420px) and (max-width: 992px) {
  .chart-phase-s2 {
      height:120px;
      width: 120px
  }

  .chart-phase-s2 {
      margin-top: -20px
  }
}

@media (min-width: 992px) {
  .chart-phase-s2 {
      height:165px;
      width: 165px
  }
}

.error-text-large {
  font-size: 120px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 17px;
  background: -webkit-linear-gradient(#00d285, #342d6e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: .9
}

.error-content {
  position: relative
}

.error-content p {
  margin-bottom: 28px;
  color: #758698
}

@media (min-width: 576px) {
  .error-text-large {
      font-size:180px
  }
}

@media (min-width: 992px) {
  .error-text-large {
      font-size:250px
  }
}

.share-links {
  margin: 5px -10px;
  display: flex;
  align-items: center
}

.share-links:last-child {
  margin-bottom: 0
}

.share-links li {
  margin: 5px 10px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #758698
}

.share-links li a {
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  color: #495463;
  font-weight: 400
}

.share-links li a .fab {
  line-height: 24px;
  display: inline-block
}

.share-links li a:hover {
  color: #00d285
}

.lang-switch-btn {
  display: inline-block;
  color: #758698;
  padding: 3px 12px;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  border: 1px solid #b1becc;
  border-radius: 15px
}

.lang-switch-btn .ti {
  font-size: 10px;
  margin-left: 5px
}

.lang-switch-btn:hover {
  color: #758698
}

.lang-switch-btn:focus,.lang-switch-btn:active {
  color: #00d285;
  border-color: #00d285
}

.lang-list {
  padding: 10px 0
}

.lang-list li a {
  font-size: 12px;
  letter-spacing: 0.1em;
  padding: 0 20px;
  text-transform: uppercase;
  color: #758698
}

.lang-list li a:hover {
  color: #00d285
}

.footer-bar {
  margin-top: auto;
  padding: 20px 0
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  margin: 3px -15px
}

.footer-links li {
  padding: 2px 15px;
  font-size: 13px;
  color: #758698
}

.footer-links li a {
  color: #758698
}

.footer-links li a:hover {
  color: #00d285
}

.referral-form {
  margin: 20px 0
}

.referral-form:first-child {
  margin-top: 0
}

.referral-form:last-child {
  margin-bottom: 0
}

.account-info .card-title,.referral-info .card-title,.kyc-info .card-title {
  font-size: 1.3em
}

@media (min-width: 576px) {
  .account-info .card-title,.referral-info .card-title,.kyc-info .card-title {
      font-size:1.4em
  }
}

@media (min-width: 992px) {
  .account-info .card-title,.referral-info .card-title,.kyc-info .card-title {
      font-size:1.1em
  }
}

.kyc-alert {
  margin-top: 20px;
  padding-bottom: 4px
}

.kyc-status {
  margin-bottom: 20px
}

.kyc-form-steps {
  margin-bottom: 25px
}

.kyc-file.fa-file-pdf,.kyc-file.fa-image {
  font-size: 40px;
  line-height: 54px;
  display: block;
  text-align: center;
  opacity: 0.2
}

.kyc-option {
  border-bottom: 1px solid #e6effb
}

.kyc-option-head {
  padding-top: 18px;
  padding-bottom: 18px
}

.form-step-head {
  border-top: 1px solid #eef2f8;
  border-bottom: 1px solid #eef2f8
}

.form-step:first-child .form-step-head {
  border-top: 0
}

.form-step:last-child .form-step-head {
  border-bottom: 0
}

.form-step-final {
  border-top: 1px solid #eef2f8;
  padding-bottom: 5px
}

.form-step-fields {
  padding: 20px
}

.form-step.form-step1 .form-step-fields {
  padding-bottom: 1px
}

.form-step.form-step2 .form-step-fields {
  padding-bottom: 16px
}

.form-step.form-step3 .form-step-fields {
  padding-top: 15px;
  padding-bottom: 1px
}

.form-step .note-plane {
  margin-bottom: 10px
}

.form-step .note-plane [class*=fa] {
  transform: translateY(3px)
}

.form-step .note-plane p,.form-step .note-plane .note-text {
  font-size: 1em !important;
  line-height: 1.5
}

.form-step.form-step2 .note-plane {
  margin-bottom: 2px
}

.step-head {
  display: flex;
  align-items: center
}

.step-head-text h4 {
  margin-bottom: 0;
  color: #342d6e
}

.step-head-text p {
  font-size: 0.95em
}

.step-head h4,.step-head h3 {
  font-weight: 500
}

.step-number {
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  font-size: 16px;
  color: #758698;
  border: 2px solid #b1becc;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 4px;
  margin-bottom: 4px
}

@media (min-width: 576px) {
  .kyc-form-steps {
      margin-bottom:60px
  }

  .form-step-fields {
      padding: 30px
  }

  .form-step.form-step1 .form-step-fields {
      padding-bottom: 10px
  }

  .form-step.form-step2 .form-step-fields {
      padding-bottom: 25px
  }

  .form-step.form-step3 .form-step-fields {
      padding-top: 15px;
      padding-bottom: 5px
  }

  .step-head h4,.step-head h3 {
      font-weight: 400
  }

  .step-number {
      font-size: 20px;
      margin-right: 18px
  }
}

[data-simplebar="init"] {
  height: 100%
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.6);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s
}

.page-overlay.is-loading {
  opacity: 1;
  visibility: visible;
  z-index: 9999999
}

.spinner {
  margin: 0;
  top: 50%;
  position: fixed;
  left: 50%;
  margin-left: -35px;
  margin-top: -14px;
  width: 70px;
  text-align: center
}

.spinner .sp {
  width: 18px;
  height: 18px;
  background-color: #00d285;
  border-radius: 100%;
  display: inline-block;
  animation: spining 1.4s infinite ease-in-out both
}

.spinner .sp1 {
  animation-delay: -0.32s
}

.spinner .sp2 {
  animation-delay: -0.16s
}

@keyframes spining {
  0%, 80%, 100% {
      transform: scale(0)
  }

  40% {
      transform: scale(1)
  }
}

.refferal-statistics {
  border-radius: 4px;
  border: 1px solid #e6effb;
  padding: 20px 18px 0;
  margin-bottom: 30px
}

.refferal-statistics-item {
  padding-bottom: 10px
}

.refferal-statistics-item h6 {
  color: #00d285;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500
}

.refferal-statistics-item span {
  font-size: 20px;
  font-weight: 500;
  color: #495463
}

.copyright-text {
  font-size: 13px;
  color: #758698
}

.phase-status {
  display: flex;
  flex-wrap: wrap
}

.phase-status>li {
  position: relative;
  width: 50%;
  padding-left: 24px;
  padding-right: 15px;
  margin-bottom: 20px
}

.phase-status>li:before {
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  content: '';
  top: 0;
  left: 0
}

.phase-status>li:nth-child(1):before {
  background: #1babfe
}

.phase-status>li:nth-child(2):before {
  background: #00d285
}

.phase-status>li:nth-child(3):before {
  background: #bc69fb
}

.phase-status>li:nth-child(4):before {
  background: #ffc100
}

.phase-status-title {
  font-size: 14px;
  color: #495463;
  line-height: 18px;
  font-weight: 500
}

.phase-status-subtitle {
  padding-top: 3px;
  font-size: 13px;
  color: #6e81a9;
  line-height: 1.2
}

.phase-status-total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding-top: 15px
}

.phase-status-total span {
  display: block
}

.phase-status-total .lead {
  font-weight: 500;
  font-size: 20px;
  color: #495463;
  line-height: 1
}

.phase-status-total .sub {
  font-size: 12px;
  text-transform: uppercase;
  color: #758698
}

.phase-status-s2>li {
  width: 100%;
  margin-bottom: 12px
}

.phase-status-s2>li:nth-child(1):before {
  background: #1c76ff
}

.phase-status-s2>li:nth-child(2):before {
  background: #3845e8
}

.phase-status-s2>li:nth-child(3):before {
  background: #7e7bff
}

.phase-status-s2>li:nth-child(4):before {
  background: #3fbafa
}

@media (min-width: 375px) {
  .phase-block {
      display:flex;
      align-items: flex-start
  }

  .phase-block-chart {
      order: 13
  }
}

@media (min-width: 420px) and (max-width: 576px) {
  .phase-status>li {
      position:relative;
      width: 100%;
      padding-right: 0;
      margin-bottom: 10px
  }
}

@media (max-width: 992px) {
  .phase-status-s2>li {
      width:50%
  }
}

@media (min-width: 375px) and (max-width: 419px) {
  .phase-status-s2>li {
      width:100%
  }
}

.icon-preview {
  text-align: center;
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #e6effb
}

.icon-wrap [class*="ikon-"] {
  font-size: 40px
}

.icon-class {
  padding-top: 15px
}

.socials li {
  display: inline-block;
  padding: 0 2px
}

.socials li a {
  font-size: 13px;
  display: block;
  text-align: center;
  width: 32px;
  line-height: 30px;
  border-radius: 50%;
  color: #758698;
  border: 1px solid #d2dde9
}

.socials li a em {
  vertical-align: middle
}

.socials li a:hover {
  color: #fff;
  background-color: #758698;
  border-color: #758698
}

.knob-wrap {
  position: relative;
  text-align: center
}

.knob-value {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.3rem;
  transform: translate(-50%, -50%);
  color: #758698
}

.total-wg {
  margin: -12px
}

.total-wg li {
  padding: 12px
}

.total-block+.total-block,.total-block+.list {
  margin-top: 10px
}

.total-block+.total-block-sm {
  margin-top: 5px
}

.total-block+.total-block-md {
  margin-top: 15px
}

.total-block+.total-block-lg {
  margin-top: 20px
}

.total-block:last-child {
  margin-bottom: -5px !important
}

.dropdown-content .total-block {
  padding: 18px
}

.total-title,.total-title-sm,.total-title-xs,.total-title-lead {
  margin-bottom: 6px;
  color: #758698
}

.total-title+.total-wg,.total-title-sm+.total-wg,.total-title-xs+.total-wg,.total-title-lead+.total-wg {
  margin-top: 0px
}

.total-title-sm {
  font-size: 0.9rem;
  font-weight: 500
}

.total-title-xs {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 400
}

.total-title-xs.ucap {
  font-weight: 500
}

.total-title-lead {
  font-size: 1.4em;
  line-height: 1.4;
  font-weight: 400;
  color: #495463
}

.total-title.ucap {
  letter-spacing: 0.12em;
  font-weight: 500
}

.total-amount,.total-amount-lead,.total-amount-lg,.total-amount-md,.total-amount-sm {
  display: block;
  margin-bottom: 0;
  line-height: 1.3;
  color: #495463
}

.total-amount {
  font-size: 1.4em;
  font-weight: 400
}

.total-amount-lead {
  font-size: 1.6em;
  font-weight: 400;
  color: #00d285
}

.total-amount-lg {
  font-size: 1.4em;
  color: #00d285
}

.total-amount-md {
  font-size: 1em
}

.total-amount-sm {
  font-size: 0.9em;
  font-weight: 500
}

.total-amount-sm+.total-title-xs {
  display: block;
  margin-top: -2px
}

.total-note {
  font-size: 12px;
  color: #758698
}

.total-note span {
  color: #495463;
  font-weight: 500
}

.total-action {
  position: relative;
  padding: 6px 12px
}

.total-action .toggle-tigger {
  color: #758698
}

.total-action .toggle-tigger .ti {
  vertical-align: middle
}

.has-action {
  display: flex;
  justify-content: space-between;
  align-items: center
}

@media (min-width: 375px) {
  .total-wg {
      display:flex;
      flex-wrap: wrap
  }

  .total-wg li {
      width: 50%
  }

  .total-title-xs,.total-note {
      font-size: 0.87em
  }
}

@media (min-width: 768px) {
  .total-wg li {
      width:25%
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .total-wg li {
      width:50%
  }
}

.text-block p.small {
  margin-bottom: 5px
}

.text-title-xs {
  font-weight: 500;
  margin-bottom: 6px;
  color: #342d6e
}

.token-info {
  padding: 22px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.token-info.bdr-tl {
  border-top: 1px solid #e6effb
}

.token-info-icon {
  width: 46px;
  height: auto;
  margin-top: 2px
}

.token-info-head {
  margin-bottom: 0
}

.token-info-list {
  padding-bottom: 20px
}

.token-info-list li {
  color: #00d285
}

.token-info-list li span {
  color: #495463;
  min-width: 110px;
  display: inline-block
}

.token-rate:not(:last-child),.token-bonus:not(:last-child),.token-rate-wrap:not(:last-child) {
  margin-bottom: 18px
}

.token-sales .card-title-sm {
  padding-top: 2px
}

.token-sales .sap {
  margin: 4px 0 2px
}

.token-balance {
  margin: 1px 0 5px
}

.token-balance.token-balance-with-icon {
  margin-top: 5px
}

.token-balance:not(:last-child) {
  margin-bottom: 20px
}

.token-balance-with-icon {
  display: flex;
  align-items: center
}

.token-balance-icon {
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: rgba(255,255,255,0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px
}

.token-balance-icon img {
  width: 24px;
  line-height: 50px
}

.token-balance-list {
  display: flex
}

.token-balance-list li {
  padding-right: 6px;
  min-width: 84px
}

.token-balance-list.item-2 li {
  max-width: 50%;
  min-width: 110px
}

.token-balance .lead {
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1;
  letter-spacing: -0.02em
}

.token-balance .lead span {
  font-weight: 500;
  font-size: .6em;
  letter-spacing: 0.04em
}

.card-token .token-balance .lead {
  color: #fff
}

.token-balance-s2 .lead {
  font-size: 1.1em
}

.token-balance-s2 .lead span,.token-balance-s2 .sub {
  display: block;
  line-height: .9;
  font-size: 11px
}

.token-balance-s3 .lead {
  font-size: 1rem
}

.token-balance-s3 .lead span,.token-balance-s3 .sub {
  display: block;
  line-height: .9;
  font-size: 12px;
  color: #758698
}

.card-token .token-balance-s3 .lead span,.card-token .token-balance-s3 .sub {
  color: rgba(255,255,255,0.7)
}

.token-balance-s3 .card-sub-title {
  margin-bottom: 0
}

.token-balance-s4 .lead {
  font-size: 1.62rem;
  font-weight: 400
}

.token-transaction table {
  margin-top: -8px;
  margin-bottom: -5px
}

.token-sales .countdown-clock:last-child,.token-sale-graph .chart-tokensale:last-child {
  margin-bottom: 5px
}

.token-calc {
  display: flex;
  padding-bottom: 10px
}

.token-pay-amount {
  position: relative;
  align-self: center;
  width: 170px
}

.token-pay-currency {
  position: absolute;
  right: 0;
  top: 9px;
  transform: translate(0);
  z-index: 1;
  padding: 0 10px;
  border-left: 1px solid #d2dde9
}

.token-pay-currency .input-hint {
  position: static;
  border: 0;
  padding: 2px;
  font-size: .8em
}

.token-received {
  display: inline-flex;
  align-items: center
}

.token-eq-sign {
  color: #758698;
  padding: 0 10px;
  font-size: 20px
}

.token-amount {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold
}

.token-symbol {
  font-size: 12px
}

.token-buy {
  margin: 20px 0 5px
}

.token-currency-choose,.token-contribute,.token-overview-wrap {
  margin-top: 20px
}

.token-currency-choose:not(:last-child),.token-contribute:not(:last-child),.token-overview-wrap:not(:last-child) {
  margin-bottom: 20px
}

.token-bonus-ui {
  padding: 1px 0
}

.token-overview {
  border-radius: 4px;
  padding: 0 20px;
  border: 2px solid #e6effb;
  overflow: hidden
}

.token-overview-title {
  font-size: 12px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  text-transform: uppercase
}

.token-overview-value {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: #342d6e;
  display: block
}

.token-overview-wrap+.card-head {
  margin-top: 35px
}

.token-bonus,.token-total {
  position: relative;
  padding: 20px 0
}

.token-bonus-current {
  display: flex;
  justify-content: space-between;
  align-items: flex-end
}

.token-bonus-date {
  text-align: right;
  font-style: italic;
  color: #758698;
  font-weight: 400;
  line-height: 1.3;
  font-size: .8em;
  margin: 0;
  padding-bottom: 2px
}

.token-bonus:after {
  position: absolute;
  bottom: 0;
  left: -30px;
  width: calc(100% + 60px);
  height: 1px;
  background: #e6effb;
  content: ''
}

.token-bonus-sale:after {
  display: none
}

.token-cta {
  border: 1px dashed #d2dde9;
  border-radius: 4px;
  padding: 35px
}

@media (max-width: 575px) {
  .token-bonus-amount {
      padding-top:0;
      margin-top: -5px
  }
}

@media (min-width: 576px) {
  .token-info {
      padding:28px 0
  }

  .token-bonus-sale:after {
      display: block
  }

  .token-balance {
      margin: 5px 0
  }

  .token-balance:not(.token-balance-with-icon) {
      margin-top: 1px
  }

  .token-balance:not(:last-child) {
      margin-bottom: 25px
  }

  .token-balance-s3:not(:last-child) {
      margin-bottom: 14px
  }

  .token-rate:not(:last-child),.token-bonus:not(:last-child) {
      margin-bottom: 20px
  }

  .token-sales .card-title-sm {
      padding-top: 0
  }

  .token-sales .sap {
      margin: 1px 0 0
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .token-balance-s2 .lead {
      font-size:0.9em
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .token-rate-wrap .token-rate {
      margin:0
  }

  .token-bonus-date {
      text-align: left;
      width: 70%
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .token-calculator {
      position:relative
  }

  .token-calculator .card-innr {
      padding-right: 180px
  }

  .token-calculator .token-buy {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0
  }

  .token-calculator .token-buy .btn {
      min-width: 130px
  }
}

@media (min-width: 768px) {
  .token-info.bdr-tl {
      border-left:1px solid #e6effb;
      border-top: 0
  }

  .token-bonus:after {
      display: none
  }
}

@media (min-width: 992px) {
  .token-buy {
      margin-top:25px
  }

  .token-cta {
      margin: 0 30px;
      padding: 35px 20px
  }
}

.bonus-bar {
  font-size: 9px;
  font-weight: 400;
  color: #6e81a9;
  background: #eef2f8;
  border-radius: 3px;
  height: 16px;
  width: 100%;
  margin: 20px 0;
  display: flex
}

.bonus-base {
  width: 20%;
  background: #00d285;
  border-radius: 2px;
  height: 8px;
  margin: 4px 2px 4px 4px;
  position: relative
}

.bonus-base-title {
  color: #758698;
  position: absolute;
  left: -4px;
  bottom: 100%;
  line-height: 1;
  margin-bottom: 10px
}

.bonus-base-amount {
  position: absolute;
  right: 0;
  top: 100%;
  line-height: 1;
  margin-top: 10px
}

.bonus-base-percent {
  position: absolute;
  right: 0;
  bottom: 100%;
  line-height: 1;
  margin-bottom: 10px
}

.bonus-base+.bonus-extra {
  width: 80%;
  margin: 4px 4px 4px 2px
}

.bonus-extra {
  display: flex;
  width: 100%;
  background: rgba(73,84,99,0.1);
  border-radius: 2px;
  height: 8px;
  margin: 4px
}

.with-base-bonus .bonus-extra {
  width: 80%;
  margin: 4px 4px 4px 2px
}

.bonus-extra-item {
  position: relative
}

.bonus-extra-item:after {
  position: absolute;
  content: '';
  right: 0;
  top: -4px;
  width: 1px;
  height: 16px;
  background: rgba(73,84,99,0.1)
}

.bonus-extra-item:before {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00d285;
  opacity: 0
}

.bonus-extra-item.active:before {
  opacity: 1
}

.bonus-extra-item:first-child:before {
  border-radius: 2px 0 0 2px
}

.bonus-extra-item:last-child:before {
  border-radius: 0 2px 2px 0
}

.bonus-extra-item:last-child:after {
  display: none
}

.bonus-extra-item:last-child .bonus-extra-amount,.bonus-extra-item:last-child .bonus-extra-percent {
  left: auto;
  right: -4px
}

.bonus-extra-amount {
  position: absolute;
  left: 100%;
  top: 100%;
  line-height: 1;
  margin-top: 10px;
  white-space: nowrap
}

.bonus-extra-percent {
  position: absolute;
  left: 100%;
  bottom: 100%;
  line-height: 1;
  margin-bottom: 10px
}

@media (min-width: 576px) {
  .bonus-bar {
      font-size:11px
  }
}

.pay-option-label {
  width: 100%;
  background: #e6effb;
  border: 2px solid #e6effb;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all .4s ease;
  text-align: center;
  margin-bottom: 15px
}

.pay-option-label:after {
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #00d285;
  content: '';
  top: 0;
  right: -5px;
  transform: translate(-50%, -50%) scale(0);
  transition: all .4s ease
}

.pay-option-check {
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0
}

.pay-option-check:checked ~ label {
  border-color: #00d285
}

.pay-option-check:checked ~ label:after {
  transform: translate(-50%, -50%) scale(1)
}

.pay-list {
  display: flex;
  flex-wrap: wrap
}

.pay-item {
  width: 100%
}

.pay-check {
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0
}

.pay-check-label {
  position: relative;
  padding: 15px 50px 15px 46px;
  border: 1px solid #d2dde9;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;
  width: 100%;
  height: calc(100% - 8px);
  transition: all .4s ease;
  display: flex;
  align-items: center
}

.pay-check-label:before {
  position: absolute;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #d2dde9;
  content: '';
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .4s ease
}

.pay-check-label:after {
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #00d285;
  content: '';
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .4s ease
}

.pay-check-text {
  font-weight: 700;
  font-size: 13px;
  color: #495463
}

.pay-check-img {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  height: 24px
}

.pay-check.error ~ .error {
  position: absolute;
  right: 8px;
  top: -26px;
  bottom: auto;
  background: #ff6868 !important;
  color: #fff !important;
  font-size: 11px;
  line-height: 18px;
  padding: 2px 10px;
  border-radius: 2px;
  transform: translate(0, 0)
}

.pay-check.error ~ .error:after {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid #ff6868;
  border-bottom: 5px solid transparent;
  bottom: -5px;
  right: 6px
}

.pay-check.error ~ .error:before {
  display: none
}

.pay-check:checked ~ label {
  border-color: #00d285
}

.pay-check:checked ~ label:before {
  border-color: #00d285
}

.pay-check:checked ~ label:after {
  opacity: 1
}

.pay-amount {
  display: block
}

.pay-icon {
  padding-right: 10px;
  font-size: 22px;
  line-height: 30px;
  width: 30px;
  text-align: center
}

.pay-icon.cf-ltc {
  font-size: 18px
}

.pay-icon.ikon {
  font-size: 30px
}

.pay-icon.ikon:before {
  margin-left: -5px;
  margin-right: -5px
}

.pay-title {
  display: inline-flex;
  align-items: center
}

.pay-cur {
  font-size: 14px;
  line-height: 30px;
  font-weight: 700
}

.pay-info-list li {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase
}

.pay-info-list li span {
  color: #758698
}

.pay-status .ti {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 82px;
  text-align: center;
  border: 2px solid;
  border-radius: 50%;
  font-size: 36px
}

.pay-status-success .ti {
  border-color: #00d285;
  color: #00d285
}

.pay-status-warning .ti {
  border-color: #ffc100;
  color: #ffc100
}

.pay-status-error .ti {
  border-color: #ff6868;
  color: #ff6868
}

.pay-button {
  width: 100%;
  padding: 10px 0
}

.pay-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 11px 0
}

.pay-button:first-child {
  padding-left: 0
}

.pay-button:last-child {
  padding-right: 0
}

.pay-button-sap {
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: #758698;
  text-transform: uppercase
}

.pay-notes {
  margin-top: 14px;
  padding-top: 30px;
  border-top: 1px solid #eef2f8;
  line-height: 1.5;
  padding-bottom: 5px
}

@media (min-width: 480px) {
  .pay-option-label {
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px
  }
}

@media (min-width: 620px) {
  .pay-item {
      width:50%
  }

  .pay-item:only-child {
      width: 100%
  }

  .pay-item:last-child:not(:nth-child(even)) {
      width: 100%
  }
}

@media (min-width: 768px) {
  .pay-button {
      width:auto;
      padding: 10px 20px
  }

  .pay-button:first-child {
      padding-left: 0
  }

  .pay-button:last-child {
      padding-right: 0
  }

  .pay-button-sap {
      width: auto
  }
}

.simplebar-scrollbar:before {
  background: #dbe4ed;
  transition: all .2s linear
}

.chat-wrap {
  position: relative;
  display: flex;
  height: calc(100vh - 265px);
  overflow: hidden
}

.chat-wrap .dropdown-content {
  box-shadow: 0px 0 35px 0px rgba(0,0,0,0.05)
}

.chat-wrap .dropdown-content-top-left {
  top: 0
}

.chat-wrap .simplebar-track,.chat-wrap .simplebar-scrollbar {
  visibility: hidden !important
}

.chat-wrap .simplebar-content {
  display: flex;
  flex-direction: column
}

.chat-wrap .simplebar-scroll-content {
  padding-right: 0 !important;
  margin-bottom: 0 !important
}

.chat-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: rgba(0,0,0,0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .4s
}

.chat-wrap.contact-active:after,.chat-wrap.information-active:after {
  opacity: 1;
  visibility: visible
}

.chat-avatar {
  flex-shrink: 0
}

.chat-avatar img {
  width: 36px;
  border-radius: 6px;
  border: 2px solid #fff
}

.chat-avatar-xs img {
  width: 18px
}

.chat-avatar-sm img {
  width: 24px
}

.chat-avatar-lg img {
  width: 40px
}

.chat-avatar.circle img {
  border-radius: 50%
}

.chat-avatar-group {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid #fff
}

.circle>.chat-avatar-group {
  border-radius: 50%
}

.chat-avatar-group:before,.chat-avatar-group:after {
  position: absolute;
  content: '';
  background-color: #fff;
  left: 50%;
  z-index: 1
}

.chat-avatar-group:before {
  height: 100%;
  width: 1px
}

.chat-avatar-group:after {
  top: 50%;
  width: 50%;
  height: 1px
}

.chat-avatar-group img {
  border-radius: 0 !important;
  border: none
}

.chat-avatar-group img:not(:first-child) {
  position: absolute;
  width: 20px;
  right: 0
}

.chat-avatar-group img:nth-child(2) {
  top: 0
}

.chat-avatar-group img:nth-child(3) {
  bottom: 0
}

.chat-name {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #495463
}

.chat-status {
  position: relative
}

.chat-status:after {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  border: 2px solid #fff;
  content: '';
  background: #758698
}

.chat-status-idle:after {
  background: #ffc100
}

.chat-status-active:after {
  background: #00d285
}

.chat-status.circle:after {
  top: 2px;
  right: 2px
}

.chat-time {
  font-size: 12px;
  color: #758698
}

.chat-time .icon {
  font-size: 11px;
  color: #b9d2f2
}

.chat-time .icon:not(:first-of-type) {
  margin-left: -5px
}

.chat-time .icon+span {
  margin-left: 2px
}

.chat-time span+.icon:first-of-type {
  margin-left: 5px
}

.chat-seen .icon {
  color: #00d285
}

.chat-lock .icon {
  color: #495463;
  margin-right: 10px
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden
}

.chat-attachment:first-child {
  border-radius: 4px 0 0 0
}

.self .chat-attachment:first-child {
  border-radius: 0 4px 0 0
}

.chat-attachment:last-child {
  border-radius: 0 4px 4px 0
}

.self .chat-attachment:last-child {
  border-radius: 4px 0 0 4px
}

.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: '';
  opacity: .4;
  transition: all .4s
}

.self .chat-attachment:before {
  opacity: .7;
  background: #00d285
}

.chat-attachment:hover:before {
  opacity: 0.6
}

.self .chat-attachment:hover:before {
  opacity: .9
}

.chat-attachment-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all .4s
}

.chat-attachment-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255,255,255,0.2);
  text-align: center
}

.chat-attachment-download:hover {
  color: #495463;
  background: #fff
}

.self .chat-attachment-download:hover {
  color: #00d285
}

.chat-attachment:hover .chat-attachment-caption {
  opacity: 0
}

.chat-attachment:hover .chat-attachment-download {
  opacity: 1
}

.chat-attachment-list {
  display: flex;
  margin: -5px
}

.chat-attachment-list li {
  width: 33.33%;
  padding: 5px
}

.chat-attachment-item {
  border: 5px solid rgba(230,239,251,0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center
}

.chat-users {
  display: none;
  align-items: center
}

.chat-users>* {
  padding: 0 10px
}

.chat-users-stack {
  display: flex;
  flex-direction: row-reverse
}

.chat-users-stack .chat-avatar:not(:first-child) {
  margin-right: -12px
}

.chat-users-search {
  display: flex;
  margin: -5px
}

.chat-users-search>div {
  padding: 5px
}

.chat-users-add {
  position: relative
}

.chat-contacts {
  position: absolute;
  left: -100%;
  top: 0;
  width: 350px;
  max-width: 85%;
  flex-shrink: 0;
  transition: all .4s;
  z-index: 1;
  background: #fff;
  height: 100%
}

.chat-contacts.active {
  left: 0
}

.chat-contacts-tools {
  padding: 20px;
  position: relative;
  overflow: hidden
}

.chat-contacts-tools-long {
  transition: all .4s
}

.chat-contacts-tools-short {
  transition: all .4s;
  position: absolute;
  top: 20px;
  opacity: 0
}

.chat-contacts-heading {
  background: #d2dde9;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.chat-contacts-heading .toggle-tigger {
  color: #758698;
  position: relative;
  top: 2px
}

.chat-contacts-title {
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  text-transform: uppercase;
  white-space: nowrap
}

.chat-contacts-title span {
  color: #758698
}

.chat-contacts-list {
  height: 100%;
  width: 350px;
  max-width: 100%
}

.chat-contacts-wrap {
  height: calc(100% - 117px);
  overflow: hidden;
  position: relative
}

.chat-contacts-wrap:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  content: '';
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 100%)
}

.chat-contacts-wrap .simplebar-content {
  padding-bottom: 0 !important
}

.chat-contacts-item {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  min-height: 96px;
  transition: background .4s
}

.chat-contacts-item:not(:last-child) {
  border-bottom: 1px solid #e6effb
}

.chat-contacts-item:hover,.chat-contacts-item.current,.chat-contacts-item.active {
  background: #f7fafd
}

.chat-contacts-item.unseen p {
  font-weight: 500;
  color: #292f37
}

.chat-contacts-content {
  padding-left: 10px;
  transition: all .4s
}

.chat-contacts-content .chat-name {
  margin-bottom: 3px
}

.chat-contacts-content p {
  color: #758698;
  font-size: 12px;
  line-height: 1.34;
  max-width: 85%;
  margin-bottom: 0;
  overflow: hidden;
  height: 18px
}

.chat-contacts-badges {
  display: flex;
  align-items: center;
  margin: 0 -3px;
  margin-bottom: 2px
}

.chat-contacts-badges li {
  padding: 0 3px;
  display: inline-flex
}

.chat-contacts-info {
  justify-content: space-between;
  align-items: center
}

.chat-contacts-texts {
  position: relative
}

.chat-contacts-texts .badge {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

.chat-messages {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.chat-messages-head {
  position: relative;
  padding: 14px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d2dde9
}

.chat-messages-name {
  font-weight: 500;
  display: inline-flex;
  align-items: center
}

.chat-messages-name .icon {
  margin-left: 7px
}

.chat-messages-name-ellipsis {
  width: 80px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.chat-messages-tools {
  display: flex
}

.chat-messages-tools>li {
  padding: 0 0;
  display: inline-flex
}

.chat-messages-tools>li>a {
  display: inline-flex;
  color: #495463;
  border-radius: 50%;
  padding: 7px
}

.chat-messages-tools>li>a.active {
  color: #00d285
}

.chat-messages-tools>li>a.show-information.active {
  color: #00d285;
  background: #e6effb
}

.chat-messages-search {
  position: absolute;
  top: 100%;
  left: 30px;
  right: 30px;
  bottom: -20px;
  z-index: 4;
  padding: 10px 0 0 0;
  margin-top: 1px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .4s
}

.chat-messages-search.active {
  transform: translateY(10px);
  opacity: 1;
  visibility: visible
}

.chat-messages-body {
  height: calc(100% - 165px)
}

.chat-messages-body .simplebar-content {
  padding-top: 15px;
  padding-bottom: 15px
}

.chat-messages-list {
  padding: 15px 12px 0
}

.chat-messages-item {
  display: flex;
  align-items: flex-end;
  padding: 5px 0
}

.chat-messages-item.self {
  flex-direction: row-reverse
}

.chat-messages-sap {
  position: relative;
  width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 0
}

.chat-messages-sap span {
  display: inline-block;
  padding: 0 20px;
  background: #fff;
  position: relative;
  z-index: 5;
  color: #758698;
  font-size: 13px
}

.chat-messages-sap:before {
  position: absolute;
  top: 50%;
  height: 1px;
  left: 0;
  right: 0;
  background: #e6effb;
  content: '';
  transform: translateY(-50%)
}

.chat-messages-content {
  margin: 0 15px;
  flex-grow: 1
}

.chat-messages-bubble {
  position: relative;
  padding: 16px 20px;
  background: #f7fafd;
  margin: 4px 0;
  display: inline-block;
  border-radius: 4px
}

.chat-messages-body .chat-messages-bubble {
  border-radius: 4px 4px 4px 0;
  clear: both;
  float: left
}

.chat-messages-body .self .chat-messages-bubble {
  text-align: right;
  float: right;
  background: #00d285;
  color: #fff;
  border-radius: 4px 4px 0 4px
}

.chat-messages-bubble p {
  margin-bottom: 8px
}

.chat-messages-bubble p:last-of-type {
  margin-bottom: 0
}

.chat-messages-bubble:hover .chat-messages-actions {
  opacity: 1
}

.chat-messages-attachments {
  padding: 4px 0;
  display: flex;
  width: 100%;
  margin: 0 -1px
}

.chat-messages-attachments>div {
  margin: 0 1px
}

.self .chat-messages-attachments {
  flex-direction: row-reverse
}

.chat-messages-actions {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .4s;
  z-index: 2
}

.self .chat-messages-actions {
  left: auto;
  right: 100%
}

.chat-messages-actions>a {
  padding: 0 20px;
  color: #495463
}

.chat-messages-actions>a:hover {
  color: #00d285
}

.chat-messages-badges {
  padding: 4px 0 2px;
  display: flex;
  margin: 0 -5px
}

.chat-messages-badges>div,.chat-messages-badges>li {
  padding: 0 5px
}

.chat-messages-info {
  display: flex;
  margin: 0 -8px;
  padding-top: 2px;
  clear: both;
  flex-wrap: wrap
}

.self .chat-messages-info {
  flex-direction: row-reverse
}

.chat-messages-info li {
  font-size: 12px;
  padding: 0 8px;
  position: relative
}

.chat-messages-info li:not(:last-child):after {
  position: absolute;
  right: 0;
  top: 50%;
  content: '';
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%)
}

.self .chat-messages-info li:not(:last-child):after {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%)
}

.chat-messages-info li a {
  color: #758698
}

.chat-messages-info li a:hover {
  color: #00d285
}

.chat-messages-info-name {
  width: 100%
}

.chat-messages-info-name:after {
  display: none
}

.chat-messages-field {
  padding: 0 12px 12px;
  margin-top: auto;
  display: flex;
  align-items: center
}

.chat-messages-field .toggle-mobile-content {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%)
}

.chat-messages-input {
  position: relative;
  flex-grow: 1;
  margin-right: 8px
}

.chat-messages-insert {
  margin: 0 -10px;
  padding: 0 5px;
  background: #fff
}

.chat-messages-insert li {
  padding: 8px 10px
}

.chat-messages-icon {
  display: inline-flex
}

.chat-messages-icon a {
  display: inline-flex
}

.chat-information {
  width: 350px;
  max-width: 100%;
  padding: 0 30px;
  flex-shrink: 0;
  height: 100%;
  overflow-y: scroll
}

.chat-information-wrap {
  position: absolute;
  right: -100%;
  top: 0;
  transition: all .4s;
  width: 350px;
  max-width: 85%;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  background: #fff;
  z-index: 1;
  padding: 25px 0
}

.chat-information-wrap.active {
  right: 0
}

.chat-information .accordion-content {
  padding-right: 0 !important
}

.chat-information .accordion-heading {
  text-transform: uppercase;
  color: #495463;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 16px;
  letter-spacing: 0.03em
}

.chat-information .accordion-heading span {
  color: #758698;
  display: inline-block;
  margin-left: 4px
}

.chat-details-item {
  margin-bottom: 15px
}

.chat-details-title {
  font-weight: 12px;
  color: #758698;
  margin-bottom: 8px
}

.chat-details-info {
  display: flex;
  align-items: center
}

.chat-details-info .chat-name {
  margin-left: 8px
}

.chat-details-drop {
  margin-left: auto;
  position: relative;
  display: inline-flex
}

.chat-details-drop>a {
  display: inline-flex;
  color: #758698
}

.chat-details-drop .dropdown-content {
  top: -5px
}

.chat-member-list {
  margin-left: -10px;
  margin-right: -10px;
  height: 165px;
  margin-top: 15px
}

.chat-member-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 10px
}

.chat-member-item .chat-name {
  margin-left: 5px;
  color: #758698
}

.chat-member-item>* {
  position: relative;
  z-index: 1
}

.chat-member-item:before {
  position: absolute;
  content: '';
  background: rgba(230,239,251,0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 0;
  transition: all .4s
}

.chat-member-item:hover:before,.chat-member-item:hover .chat-member-action {
  opacity: 1
}

.chat-member-item:hover .chat-name {
  color: #495463
}

.chat-member-action,.chat-member-position {
  margin-left: auto
}

.chat-member-action {
  position: relative;
  opacity: 0;
  transition: all .4s
}

.chat-member-action a {
  position: relative;
  color: #758698;
  top: 2px
}

.chat-member-action .dropdown-content {
  margin-top: -3px
}

.chat-member-position {
  color: #758698;
  font-size: 11px
}

.chat-add-short {
  position: absolute;
  top: 20px;
  left: 20px;
  opacity: 0;
  transition: all .4s
}

.btn-long {
  display: none
}

@media (min-width: 480px) {
  .chat-contacts-info {
      display:flex
  }

  .chat-contacts-content p {
      max-width: 75%;
      height: auto
  }

  .btn-short {
      display: none
  }

  .btn-long {
      display: block
  }
}

@media (min-width: 576px) {
  .chat-messages-head {
      padding:14px 30px
  }

  .chat-messages-list {
      padding: 15px 30px 0
  }

  .chat-messages-name-ellipsis {
      width: auto;
      max-width: 220px
  }

  .chat-messages-info-name {
      width: auto
  }

  .chat-messages-info-name:after {
      display: block
  }

  .chat-messages-body .chat-messages-bubble {
      max-width: 85%
  }

  .chat-messages-input {
      margin-right: 20px
  }

  .chat-messages-field {
      padding: 0 30px 30px
  }

  .chat-messages-field .toggle-mobile-content {
      transform: translateX(0)
  }

  .chat-messages-insert {
      display: flex
  }
}

@media (min-width: 992px) {
  .chat-wrap {
      overflow:visible
  }

  .chat-wrap:after {
      display: none !important
  }

  .chat-contacts {
      position: static
  }

  .chat-contacts.short {
      width: 80px
  }

  .chat-contacts-list {
      min-width: 350px
  }

  .chat-contacts-tools-long {
      opacity: 1
  }

  .short .chat-contacts-tools-long {
      opacity: 0
  }

  .chat-contacts-tools-short {
      opacity: 0
  }

  .short .chat-contacts-tools-short {
      opacity: 1
  }

  .chat-contacts-heading {
      justify-content: space-between
  }

  .short .chat-contacts-heading {
      justify-content: center
  }

  .short .chat-contacts-title {
      display: none
  }

  .short .chat-contacts-content {
      opacity: 0
  }

  .chat-users {
      margin: 0 -10px
  }

  .chat-users-search {
      transition: all .4s
  }

  .short .chat-users-search {
      opacity: 0
  }

  .short .chat-add-short {
      opacity: 1
  }

  .chat-information {
      min-width: 350px
  }

  .chat-information-wrap {
      position: static;
      width: 0;
      right: 0
  }

  .chat-information-wrap.active {
      width: 350px
  }

  .chat-users {
      display: flex
  }

  .chat-messages {
      border-left: 1px solid #d2dde9;
      border-right: 1px solid #d2dde9
  }

  .chat-messages-icon {
      display: none
  }
}

.timeline {
  position: relative;
  padding: 15px 0
}

.timeline-wrap {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 300px
}

.timeline-wrap .timeline-innr {
  overflow-x: hidden;
  height: 100%;
  position: absolute;
  padding-right: 20px;
  padding-bottom: 30px
}

.timeline-wrap.loaded .timeline-innr {
  padding-bottom: 0
}

.timeline-load {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 40px 0 0 0;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 65%, #fff 100%)
}

.timeline-load .link {
  display: block;
  text-transform: uppercase;
  font-weight: 400;
  color: #758698
}

.timeline-load .link:hover {
  color: #00d285
}

.timeline-line {
  position: absolute;
  height: 100%;
  width: 2px;
  border-radius: 2px;
  background: #e3eaf1;
  top: 0;
  left: 90px
}

.timeline-item {
  display: flex
}

.timeline-item.hidden {
  display: none
}

.timeline-time {
  width: 90px;
  flex-shrink: 0;
  font-weight: 500;
  margin: 10px 0;
  position: relative;
  align-self: flex-start
}

.timeline-time:after {
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #00d285;
  content: '';
  top: 5px;
  right: -8px
}

.light>.timeline-time:after {
  background: #fff;
  border-color: #e3eaf1
}

.secondary>.timeline-time:after {
  background: #342d6e;
  border-color: #fff
}

.success>.timeline-time:after {
  background: #00d285;
  border-color: #fff
}

.warning>.timeline-time:after {
  background: #ffc100;
  border-color: #fff
}

.danger>.timeline-time:after {
  background: #ff6868;
  border-color: #fff
}

.timeline-time span {
  display: block;
  font-size: 12px;
  line-height: 1;
  color: #758698;
  font-weight: 400
}

.timeline-content {
  flex-grow: 1;
  margin: 10px 0 10px 25px
}

.timeline-content p,.timeline-content-title {
  margin-bottom: 0
}

.timeline-content-info {
  font-size: 12px;
  display: block;
  padding-top: 8px;
  color: #758698
}

a+.timeline-content-info {
  padding-top: 2px
}

p+.timeline-content-info {
  padding-top: 5px
}

.stage-card {
  position: relative;
  border-radius: 4px;
  border: 1px solid #d2dde9;
  transition: all .4s
}

.stage-card:hover {
  box-shadow: 0 3px 18px 0px rgba(0,0,0,0.09)
}

.stage-card-add {
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  border-style: dashed;
  height: 100%;
  padding: 180px 0
}

.stage-card-add a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.stage-card-add .ti {
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  padding: 13px 0;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  border: 1px solid #d2dde9;
  display: block;
  color: #758698
}

.stage-card-add span {
  display: block;
  font-size: 16px;
  padding-top: 20px;
  color: #758698;
  font-weight: 500;
  font-family: "Roboto",sans-serif
}

.stage-item-actived {
  border-width: 2px;
  border-color: #1babfe
}

.stage-head {
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start
}

.stage-head .toggle-tigger {
  display: inline-block;
  padding: 5px;
  color: #758698
}

.stage-action {
  position: relative;
  margin-top: -1px;
  margin-right: -5px
}

.stage-title h6 {
  text-transform: uppercase;
  color: #9fabb7;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  font-weight: 600;
  display: inline-flex;
  align-items: center
}

.stage-title h6 .badge {
  margin-left: 10px
}

.stage-title h4 {
  color: #495463;
  margin-bottom: 0
}

.stage-info {
  padding: 20px;
  border-bottom: 1px solid #e6effb
}

.stage-info-status {
  display: flex;
  align-items: center
}

.stage-info-txt {
  margin-left: 20px
}

.stage-info-txt:first-child {
  margin-left: 0
}

.stage-info-txt h6 {
  margin-bottom: 3px;
  color: #9fabb7
}

.stage-info-txt>span {
  display: block;
  color: #495463
}

.stage-info-txt>span small {
  font-size: .8em;
  color: #00d285;
  margin-left: 2px
}

.stage-info-total {
  color: #00d285 !important;
  margin-bottom: 2px
}

.stage-info-count {
  font-size: 12px;
  color: #758698
}

.stage-info-count span {
  color: #495463;
  font-weight: 700
}

.stage-info-number {
  color: #495463;
  margin-bottom: 0;
  font-size: 1.25em
}

.stage-info-number small {
  font-size: .6em;
  color: #00d285;
  margin-left: 4px
}

.stage-date {
  padding: 20px
}

.stage-date h4 {
  font-size: 1.2em;
  margin-bottom: 0;
  color: #495463
}

.stage-date h6 {
  margin-bottom: 4px;
  color: #9fabb7
}

.stage-tire {
  padding: 12px 20px;
  border-bottom: 1px solid #e6effb
}

.stage-tire-title {
  padding: 10px 0;
  display: flex;
  align-items: center
}

.stage-tire-title .h5 {
  font-size: 0.9rem;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  color: #495463;
  letter-spacing: 0.02em;
  margin-bottom: 0
}

.stage-tire-title .h5+p {
  padding-top: 2px;
  color: #758698;
  font-size: 0.8rem
}

.stage-tire-title.collapse-icon-right:after {
  right: 14px
}

.stage-tire-subtitle {
  text-transform: uppercase;
  font-weight: 500;
  color: #6e81a9;
  letter-spacing: 0.1em;
  margin-bottom: 0
}

.stage-tire-group:first-child {
  margin-top: -15px
}

.stage-tire-item {
  padding: 10px 0;
  border-bottom: 1px solid #e6effb
}

.stage-tire-item:last-child {
  border-bottom: none
}

.stage-tire-group .stage-tire-item {
  padding: 7px 0
}

.stage-tire-item .switch-content {
  margin-top: 10px
}

@media (min-width: 576px) {
  .stage-head {
      padding:25px 25px 0
  }

  .stage-info,.stage-date {
      padding: 25px
  }
}

.progress-circle {
  position: relative;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  text-align: center;
  text-transform: uppercase
}

.progress-soon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 5px solid #c7ced5
}

.progress-txt {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #00d285;
  line-height: 1.3;
  font-weight: 400
}

.progress-status {
  font-size: 12px;
  color: #758698;
  line-height: 1.3;
  display: block
}

.payment-card {
  position: relative;
  border-radius: 4px;
  border: 1px solid #d2dde9;
  transition: all .4s;
  overflow: hidden
}

.payment-card:hover {
  box-shadow: 0 3px 18px 0px rgba(0,0,0,0.09)
}

.payment-head {
  position: relative;
  background-color: #f8fbff;
  border-bottom: 1px solid #e6effb
}

.payment-logo {
  height: 100px;
  text-align: center
}

.payment-logo img {
  height: 100%
}

.payment-body {
  text-align: center;
  padding: 31px 35px 37px
}

.payment-footer {
  display: flex;
  align-items: center;
  border-top: 1px solid #e6effb;
  padding: 30px 25px
}

.payment-action {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%)
}

.payment-action .toggle-tigger {
  color: #758698;
  display: inline-block;
  padding: 5px 8px 0
}

.payment-title {
  text-transform: uppercase;
  font-weight: 600;
  color: #495463;
  letter-spacing: 0.1em;
  margin-bottom: 2px
}

.payment-text {
  color: #758698
}

.payment-status {
  padding-top: 23px
}

.payment-status-icon {
  font-size: 18px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid;
  display: inline-block;
  color: #fff
}

.payment-status-connect .payment-status-icon {
  background: transparent;
  color: #00d285;
  border-color: #00d285
}

.payment-status-connected .payment-status-icon {
  background: #00d285;
  border-color: #00d285
}

.payment-status-disabled .payment-status-icon {
  background: #ff6868;
  border-color: #ff6868
}

.payment-status-text {
  padding-top: 7px;
  color: #758698;
  font-weight: 400
}

.payment-status-connected .payment-status-text {
  color: #00d285
}

.payment-status-disabled .payment-status-text {
  color: #ff6868
}

.payment-id {
  font-size: 14px;
  color: #495463;
  margin-left: auto
}

.payment-id-title {
  color: #6e81a9
}

.payment-method {
  color: #758698
}

.payment-not-conected {
  text-align: center;
  color: #6e81a9;
  width: 100%
}

.payment-wallet {
  padding: 0 18px 12px;
  border-top: 1px solid #e6effb
}

.payment-wallet-head {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px
}

.payment-wallet-head .fake-class {
  position: relative
}

.payment-wallet-head .switch-toggle-link {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%
}

.payment-wallet-head>.input-item {
  padding-bottom: 0
}

.payment-wallet-title {
  margin-bottom: 0;
  color: #495463;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin-top: 1px
}

.payment-wallet-des {
  padding-top: 2px;
  color: #758698;
  font-size: 0.8rem
}

.payment-badge {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%)
}

.payment-badge .badge-xs {
  padding: 2px 6px 1px
}

.payment-label {
  position: absolute;
  color: #fff;
  background: #bc69fb;
  font-size: 11px;
  line-height: 15px;
  padding: 30px 22px 7px 20px;
  height: 51px;
  width: 100px;
  transform: rotate(-45deg);
  left: -34px;
  top: -9px;
  text-transform: uppercase;
  text-align: center
}

.payment-label .label-icon {
  font-size: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -5px
}

.switch-content {
  display: none
}

@media (min-width: 480px) {
  .payment-wallet {
      padding:0 42px 12px
  }
}

.ucap {
  text-transform: uppercase
}

.cap {
  text-transform: capitalize
}

.font-bold {
  font-weight: 700
}

.font-semibold {
  font-weight: 600
}

.font-mid {
  font-weight: 500
}

.font-light {
  font-weight: 300
}

.fs-8 {
  font-size: 8px
}

.fs-9 {
  font-size: 9px
}

.fs-10 {
  font-size: 10px
}

.fs-11 {
  font-size: 11px
}

.fs-12 {
  font-size: 12px
}

.fs-13 {
  font-size: 13px
}

.fs-14 {
  font-size: 14px
}

.fs-15 {
  font-size: 15px
}

.fs-16 {
  font-size: 16px
}

.fs-17 {
  font-size: 17px
}

.fs-18 {
  font-size: 18px
}

.fs-19 {
  font-size: 19px
}

.fs-20 {
  font-size: 20px
}

.fs-21 {
  font-size: 21px
}

.fs-22 {
  font-size: 22px
}

.fs-23 {
  font-size: 23px
}

.fs-24 {
  font-size: 24px
}

.lh-1 {
  line-height: 1
}

.lh-1-1 {
  line-height: 1.1
}

.lh-1-2 {
  line-height: 1.2
}

.lh-1-3 {
  line-height: 1.3
}

.lh-1-4 {
  line-height: 1.4
}

.lh-1-5 {
  line-height: 1.5
}

.pd-0,.nopd {
  padding: 0px
}

.npl {
  padding-left: 0px
}

.npr {
  padding-right: 0px
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-y-hidden {
  overflow-y: hidden
}

.height-auto {
  height: auto !important
}

.sap {
  width: 100%;
  border-bottom: 1px solid #e6effb
}

.sap-light {
  border-bottom-color: #f3f7fd
}

.sap.sap-gap {
  margin-top: 30px;
  margin-bottom: 30px
}

.sap.sap-gap-sm {
  margin-top: 20px;
  margin-bottom: 20px
}

.sap.sap-gap-lg {
  margin-top: 45px;
  margin-bottom: 45px
}

.gaps-0-5x {
  height: 5px
}

.gaps-1-5x {
  height: 15px
}

.gaps-2-5x {
  height: 25px
}

.gaps-3-5x {
  height: 35px
}

.gaps-4-5x {
  height: 45px
}

.gaps-1x {
  height: 10px
}

.gaps-2x {
  height: 20px
}

.gaps-3x {
  height: 30px
}

.gaps-4x {
  height: 40px
}

.gaps-5x {
  height: 50px
}

.gaps-6x {
  height: 60px
}

.gaps-7x {
  height: 70px
}

.gaps-8x {
  height: 80px
}

.gaps-9x {
  height: 90px
}

.gaps-10x {
  height: 100px
}

.gaps-11x {
  height: 110px
}

.gaps-12x {
  height: 120px
}

.gaps-13x {
  height: 130px
}

.gaps-14x {
  height: 140px
}

.gaps-15x {
  height: 150px
}

.gaps-16x {
  height: 160px
}

.gaps-17x {
  height: 170px
}

.gaps-18x {
  height: 180px
}

.gaps-19x {
  height: 190px
}

.gaps-20x {
  height: 200px
}

.gaps-21x {
  height: 210px
}

.gaps-22x {
  height: 220px
}

.gaps-23x {
  height: 230px
}

.gaps-24x {
  height: 240px
}

.gaps-25x {
  height: 250px
}

.gaps-26x {
  height: 260px
}

.gaps-27x {
  height: 270px
}

.gaps-28x {
  height: 280px
}

.gaps-29x {
  height: 290px
}

.gaps-30x {
  height: 300px
}

.pd-0-5x {
  padding: 5px
}

.pd-1x {
  padding: 10px
}

.pd-1-5x {
  padding: 15px
}

.pd-2x {
  padding: 20px
}

.pd-2-5x {
  padding: 25px
}

.pd-3x {
  padding: 30px
}

.pd-3-5x {
  padding: 35px
}

.pd-4x {
  padding: 40px
}

.pd-4-5x {
  padding: 45px
}

.pd-5x {
  padding: 50px
}

.pd-5-5x {
  padding: 55px
}

.pd-6x {
  padding: 60px
}

.pd-6-5x {
  padding: 65px
}

.pd-7x {
  padding: 70px
}

.pd-7-5x {
  padding: 75px
}

.pd-8x {
  padding: 80px
}

.pd-8-5x {
  padding: 85px
}

.pd-9x {
  padding: 90px
}

.pd-9-5x {
  padding: 95px
}

.pd-10x {
  padding: 100px
}

.pd-10-5x {
  padding: 105px
}

.pd-11x {
  padding: 110px
}

.pd-11-5x {
  padding: 115px
}

.pd-12x {
  padding: 120px
}

.pd-12-5x {
  padding: 125px
}

.pd-13x {
  padding: 130px
}

.pd-13-5x {
  padding: 135px
}

.pd-14x {
  padding: 140px
}

.pd-14-5x {
  padding: 145px
}

.pd-15x {
  padding: 150px
}

.pd-15-5x {
  padding: 155px
}

.pd-16x {
  padding: 160px
}

.pd-16-5x {
  padding: 165px
}

.pd-17x {
  padding: 170px
}

.pd-17-5x {
  padding: 175px
}

.pd-18x {
  padding: 180px
}

.pd-18-5x {
  padding: 185px
}

.pd-19x {
  padding: 190px
}

.pd-19-5x {
  padding: 195px
}

.pd-20x {
  padding: 200px
}

.pd-20-5x {
  padding: 205px
}

.pdl-0-5x {
  padding-left: 5px
}

.pdl-1x {
  padding-left: 10px
}

.pdl-1-5x {
  padding-left: 15px
}

.pdl-2x {
  padding-left: 20px
}

.pdl-2-5x {
  padding-left: 25px
}

.pdl-3x {
  padding-left: 30px
}

.pdl-3-5x {
  padding-left: 35px
}

.pdl-4x {
  padding-left: 40px
}

.pdl-4-5x {
  padding-left: 45px
}

.pdl-5x {
  padding-left: 50px
}

.pdl-5-5x {
  padding-left: 55px
}

.pdl-6x {
  padding-left: 60px
}

.pdl-6-5x {
  padding-left: 65px
}

.pdl-7x {
  padding-left: 70px
}

.pdl-7-5x {
  padding-left: 75px
}

.pdl-8x {
  padding-left: 80px
}

.pdl-8-5x {
  padding-left: 85px
}

.pdl-9x {
  padding-left: 90px
}

.pdl-9-5x {
  padding-left: 95px
}

.pdl-10x {
  padding-left: 100px
}

.pdl-10-5x {
  padding-left: 105px
}

.pdl-11x {
  padding-left: 110px
}

.pdl-11-5x {
  padding-left: 115px
}

.pdl-12x {
  padding-left: 120px
}

.pdl-12-5x {
  padding-left: 125px
}

.pdl-13x {
  padding-left: 130px
}

.pdl-13-5x {
  padding-left: 135px
}

.pdl-14x {
  padding-left: 140px
}

.pdl-14-5x {
  padding-left: 145px
}

.pdl-15x {
  padding-left: 150px
}

.pdl-15-5x {
  padding-left: 155px
}

.pdl-16x {
  padding-left: 160px
}

.pdl-16-5x {
  padding-left: 165px
}

.pdl-17x {
  padding-left: 170px
}

.pdl-17-5x {
  padding-left: 175px
}

.pdl-18x {
  padding-left: 180px
}

.pdl-18-5x {
  padding-left: 185px
}

.pdl-19x {
  padding-left: 190px
}

.pdl-19-5x {
  padding-left: 195px
}

.pdl-20x {
  padding-left: 200px
}

.pdl-20-5x {
  padding-left: 205px
}

.pdr-0-5x {
  padding-right: 5px
}

.pdr-1x {
  padding-right: 10px
}

.pdr-1-5x {
  padding-right: 15px
}

.pdr-2x {
  padding-right: 20px
}

.pdr-2-5x {
  padding-right: 25px
}

.pdr-3x {
  padding-right: 30px
}

.pdr-3-5x {
  padding-right: 35px
}

.pdr-4x {
  padding-right: 40px
}

.pdr-4-5x {
  padding-right: 45px
}

.pdr-5x {
  padding-right: 50px
}

.pdr-5-5x {
  padding-right: 55px
}

.pdr-6x {
  padding-right: 60px
}

.pdr-6-5x {
  padding-right: 65px
}

.pdr-7x {
  padding-right: 70px
}

.pdr-7-5x {
  padding-right: 75px
}

.pdr-8x {
  padding-right: 80px
}

.pdr-8-5x {
  padding-right: 85px
}

.pdr-9x {
  padding-right: 90px
}

.pdr-9-5x {
  padding-right: 95px
}

.pdr-10x {
  padding-right: 100px
}

.pdr-10-5x {
  padding-right: 105px
}

.pdr-11x {
  padding-right: 110px
}

.pdr-11-5x {
  padding-right: 115px
}

.pdr-12x {
  padding-right: 120px
}

.pdr-12-5x {
  padding-right: 125px
}

.pdr-13x {
  padding-right: 130px
}

.pdr-13-5x {
  padding-right: 135px
}

.pdr-14x {
  padding-right: 140px
}

.pdr-14-5x {
  padding-right: 145px
}

.pdr-15x {
  padding-right: 150px
}

.pdr-15-5x {
  padding-right: 155px
}

.pdr-16x {
  padding-right: 160px
}

.pdr-16-5x {
  padding-right: 165px
}

.pdr-17x {
  padding-right: 170px
}

.pdr-17-5x {
  padding-right: 175px
}

.pdr-18x {
  padding-right: 180px
}

.pdr-18-5x {
  padding-right: 185px
}

.pdr-19x {
  padding-right: 190px
}

.pdr-19-5x {
  padding-right: 195px
}

.pdr-20x {
  padding-right: 200px
}

.pdr-20-5x {
  padding-right: 205px
}

.pdt-0-5x {
  padding-top: 5px
}

.pdt-1x {
  padding-top: 10px
}

.pdt-1-5x {
  padding-top: 15px
}

.pdt-2x {
  padding-top: 20px
}

.pdt-2-5x {
  padding-top: 25px
}

.pdt-3x {
  padding-top: 30px
}

.pdt-3-5x {
  padding-top: 35px
}

.pdt-4x {
  padding-top: 40px
}

.pdt-4-5x {
  padding-top: 45px
}

.pdt-5x {
  padding-top: 50px
}

.pdt-5-5x {
  padding-top: 55px
}

.pdt-6x {
  padding-top: 60px
}

.pdt-6-5x {
  padding-top: 65px
}

.pdt-7x {
  padding-top: 70px
}

.pdt-7-5x {
  padding-top: 75px
}

.pdt-8x {
  padding-top: 80px
}

.pdt-8-5x {
  padding-top: 85px
}

.pdt-9x {
  padding-top: 90px
}

.pdt-9-5x {
  padding-top: 95px
}

.pdt-10x {
  padding-top: 100px
}

.pdt-10-5x {
  padding-top: 105px
}

.pdt-11x {
  padding-top: 110px
}

.pdt-11-5x {
  padding-top: 115px
}

.pdt-12x {
  padding-top: 120px
}

.pdt-12-5x {
  padding-top: 125px
}

.pdt-13x {
  padding-top: 130px
}

.pdt-13-5x {
  padding-top: 135px
}

.pdt-14x {
  padding-top: 140px
}

.pdt-14-5x {
  padding-top: 145px
}

.pdt-15x {
  padding-top: 150px
}

.pdt-15-5x {
  padding-top: 155px
}

.pdt-16x {
  padding-top: 160px
}

.pdt-16-5x {
  padding-top: 165px
}

.pdt-17x {
  padding-top: 170px
}

.pdt-17-5x {
  padding-top: 175px
}

.pdt-18x {
  padding-top: 180px
}

.pdt-18-5x {
  padding-top: 185px
}

.pdt-19x {
  padding-top: 190px
}

.pdt-19-5x {
  padding-top: 195px
}

.pdt-20x {
  padding-top: 200px
}

.pdt-20-5x {
  padding-top: 205px
}

.pdb-0-5x {
  padding-bottom: 5px
}

.pdb-1x {
  padding-bottom: 10px
}

.pdb-1-5x {
  padding-bottom: 15px
}

.pdb-2x {
  padding-bottom: 20px
}

.pdb-2-5x {
  padding-bottom: 25px
}

.pdb-3x {
  padding-bottom: 30px
}

.pdb-3-5x {
  padding-bottom: 35px
}

.pdb-4x {
  padding-bottom: 40px
}

.pdb-4-5x {
  padding-bottom: 45px
}

.pdb-5x {
  padding-bottom: 50px
}

.pdb-5-5x {
  padding-bottom: 55px
}

.pdb-6x {
  padding-bottom: 60px
}

.pdb-6-5x {
  padding-bottom: 65px
}

.pdb-7x {
  padding-bottom: 70px
}

.pdb-7-5x {
  padding-bottom: 75px
}

.pdb-8x {
  padding-bottom: 80px
}

.pdb-8-5x {
  padding-bottom: 85px
}

.pdb-9x {
  padding-bottom: 90px
}

.pdb-9-5x {
  padding-bottom: 95px
}

.pdb-10x {
  padding-bottom: 100px
}

.pdb-10-5x {
  padding-bottom: 105px
}

.pdb-11x {
  padding-bottom: 110px
}

.pdb-11-5x {
  padding-bottom: 115px
}

.pdb-12x {
  padding-bottom: 120px
}

.pdb-12-5x {
  padding-bottom: 125px
}

.pdb-13x {
  padding-bottom: 130px
}

.pdb-13-5x {
  padding-bottom: 135px
}

.pdb-14x {
  padding-bottom: 140px
}

.pdb-14-5x {
  padding-bottom: 145px
}

.pdb-15x {
  padding-bottom: 150px
}

.pdb-15-5x {
  padding-bottom: 155px
}

.pdb-16x {
  padding-bottom: 160px
}

.pdb-16-5x {
  padding-bottom: 165px
}

.pdb-17x {
  padding-bottom: 170px
}

.pdb-17-5x {
  padding-bottom: 175px
}

.pdb-18x {
  padding-bottom: 180px
}

.pdb-18-5x {
  padding-bottom: 185px
}

.pdb-19x {
  padding-bottom: 190px
}

.pdb-19-5x {
  padding-bottom: 195px
}

.pdb-20x {
  padding-bottom: 200px
}

.pdb-20-5x {
  padding-bottom: 205px
}

.mlr-auto {
  margin-left: auto;
  margin-right: auto
}

.mg-0-5x {
  padding: 5px !important
}

.mg-1x {
  padding: 10px
}

.mg-1-5x {
  padding: 15px
}

.mg-2x {
  padding: 20px
}

.mg-2-5x {
  padding: 25px
}

.mg-3x {
  padding: 30px
}

.mg-3-5x {
  padding: 35px
}

.mg-4x {
  padding: 40px
}

.mg-4-5x {
  padding: 45px
}

.mg-5x {
  padding: 50px
}

.mg-5-5x {
  padding: 55px
}

.mg-6x {
  padding: 60px
}

.mg-6-5x {
  padding: 65px
}

.mg-7x {
  padding: 70px
}

.mg-7-5x {
  padding: 75px
}

.mg-8x {
  padding: 80px
}

.mg-8-5x {
  padding: 85px
}

.mg-9x {
  padding: 90px
}

.mg-9-5x {
  padding: 95px
}

.mg-10x {
  padding: 100px
}

.mg-10-5x {
  padding: 105px
}

.mg-11x {
  padding: 110px
}

.mg-11-5x {
  padding: 115px
}

.mg-12x {
  padding: 120px
}

.mg-12-5x {
  padding: 125px
}

.mg-13x {
  padding: 130px
}

.mg-13-5x {
  padding: 135px
}

.mg-14x {
  padding: 140px
}

.mg-14-5x {
  padding: 145px
}

.mg-15x {
  padding: 150px
}

.mg-15-5x {
  padding: 155px
}

.mg-16x {
  padding: 160px
}

.mg-16-5x {
  padding: 165px
}

.mg-17x {
  padding: 170px
}

.mg-17-5x {
  padding: 175px
}

.mg-18x {
  padding: 180px
}

.mg-18-5x {
  padding: 185px
}

.mg-19x {
  padding: 190px
}

.mg-19-5x {
  padding: 195px
}

.mg-20x {
  padding: 200px
}

.mg-20-5x {
  padding: 205px
}

.mgl-0-5x {
  margin-left: 5px !important
}

.mgl-1x {
  margin-left: 10px
}

.mgl-1-5x {
  margin-left: 15px
}

.mgl-2x {
  margin-left: 20px
}

.mgl-2-5x {
  margin-left: 25px
}

.mgl-3x {
  margin-left: 30px
}

.mgl-3-5x {
  margin-left: 35px
}

.mgl-4x {
  margin-left: 40px
}

.mgl-4-5x {
  margin-left: 45px
}

.mgl-5x {
  margin-left: 50px
}

.mgl-5-5x {
  margin-left: 55px
}

.mgl-6x {
  margin-left: 60px
}

.mgl-6-5x {
  margin-left: 65px
}

.mgl-7x {
  margin-left: 70px
}

.mgl-7-5x {
  margin-left: 75px
}

.mgl-8x {
  margin-left: 80px
}

.mgl-8-5x {
  margin-left: 85px
}

.mgl-9x {
  margin-left: 90px
}

.mgl-9-5x {
  margin-left: 95px
}

.mgl-10x {
  margin-left: 100px
}

.mgl-10-5x {
  margin-left: 105px
}

.mgl-11x {
  margin-left: 110px
}

.mgl-11-5x {
  margin-left: 115px
}

.mgl-12x {
  margin-left: 120px
}

.mgl-12-5x {
  margin-left: 125px
}

.mgl-13x {
  margin-left: 130px
}

.mgl-13-5x {
  margin-left: 135px
}

.mgl-14x {
  margin-left: 140px
}

.mgl-14-5x {
  margin-left: 145px
}

.mgl-15x {
  margin-left: 150px
}

.mgl-15-5x {
  margin-left: 155px
}

.mgl-16x {
  margin-left: 160px
}

.mgl-16-5x {
  margin-left: 165px
}

.mgl-17x {
  margin-left: 170px
}

.mgl-17-5x {
  margin-left: 175px
}

.mgl-18x {
  margin-left: 180px
}

.mgl-18-5x {
  margin-left: 185px
}

.mgl-19x {
  margin-left: 190px
}

.mgl-19-5x {
  margin-left: 195px
}

.mgl-20x {
  margin-left: 200px
}

.mgl-20-5x {
  margin-left: 205px
}

.mgr-0-5x {
  margin-right: 5px !important
}

.mgr-1x {
  margin-right: 10px
}

.mgr-1-5x {
  margin-right: 15px
}

.mgr-2x {
  margin-right: 20px
}

.mgr-2-5x {
  margin-right: 25px
}

.mgr-3x {
  margin-right: 30px
}

.mgr-3-5x {
  margin-right: 35px
}

.mgr-4x {
  margin-right: 40px
}

.mgr-4-5x {
  margin-right: 45px
}

.mgr-5x {
  margin-right: 50px
}

.mgr-5-5x {
  margin-right: 55px
}

.mgr-6x {
  margin-right: 60px
}

.mgr-6-5x {
  margin-right: 65px
}

.mgr-7x {
  margin-right: 70px
}

.mgr-7-5x {
  margin-right: 75px
}

.mgr-8x {
  margin-right: 80px
}

.mgr-8-5x {
  margin-right: 85px
}

.mgr-9x {
  margin-right: 90px
}

.mgr-9-5x {
  margin-right: 95px
}

.mgr-10x {
  margin-right: 100px
}

.mgr-10-5x {
  margin-right: 105px
}

.mgr-11x {
  margin-right: 110px
}

.mgr-11-5x {
  margin-right: 115px
}

.mgr-12x {
  margin-right: 120px
}

.mgr-12-5x {
  margin-right: 125px
}

.mgr-13x {
  margin-right: 130px
}

.mgr-13-5x {
  margin-right: 135px
}

.mgr-14x {
  margin-right: 140px
}

.mgr-14-5x {
  margin-right: 145px
}

.mgr-15x {
  margin-right: 150px
}

.mgr-15-5x {
  margin-right: 155px
}

.mgr-16x {
  margin-right: 160px
}

.mgr-16-5x {
  margin-right: 165px
}

.mgr-17x {
  margin-right: 170px
}

.mgr-17-5x {
  margin-right: 175px
}

.mgr-18x {
  margin-right: 180px
}

.mgr-18-5x {
  margin-right: 185px
}

.mgr-19x {
  margin-right: 190px
}

.mgr-19-5x {
  margin-right: 195px
}

.mgr-20x {
  margin-right: 200px
}

.mgr-20-5x {
  margin-right: 205px
}

.mgt-0-5x {
  margin-top: 5px !important
}

.mgt-1x {
  margin-top: 10px
}

.mgt-1-5x {
  margin-top: 15px
}

.mgt-2x {
  margin-top: 20px
}

.mgt-2-5x {
  margin-top: 25px
}

.mgt-3x {
  margin-top: 30px
}

.mgt-3-5x {
  margin-top: 35px
}

.mgt-4x {
  margin-top: 40px
}

.mgt-4-5x {
  margin-top: 45px
}

.mgt-5x {
  margin-top: 50px
}

.mgt-5-5x {
  margin-top: 55px
}

.mgt-6x {
  margin-top: 60px
}

.mgt-6-5x {
  margin-top: 65px
}

.mgt-7x {
  margin-top: 70px
}

.mgt-7-5x {
  margin-top: 75px
}

.mgt-8x {
  margin-top: 80px
}

.mgt-8-5x {
  margin-top: 85px
}

.mgt-9x {
  margin-top: 90px
}

.mgt-9-5x {
  margin-top: 95px
}

.mgt-10x {
  margin-top: 100px
}

.mgt-10-5x {
  margin-top: 105px
}

.mgt-11x {
  margin-top: 110px
}

.mgt-11-5x {
  margin-top: 115px
}

.mgt-12x {
  margin-top: 120px
}

.mgt-12-5x {
  margin-top: 125px
}

.mgt-13x {
  margin-top: 130px
}

.mgt-13-5x {
  margin-top: 135px
}

.mgt-14x {
  margin-top: 140px
}

.mgt-14-5x {
  margin-top: 145px
}

.mgt-15x {
  margin-top: 150px
}

.mgt-15-5x {
  margin-top: 155px
}

.mgt-16x {
  margin-top: 160px
}

.mgt-16-5x {
  margin-top: 165px
}

.mgt-17x {
  margin-top: 170px
}

.mgt-17-5x {
  margin-top: 175px
}

.mgt-18x {
  margin-top: 180px
}

.mgt-18-5x {
  margin-top: 185px
}

.mgt-19x {
  margin-top: 190px
}

.mgt-19-5x {
  margin-top: 195px
}

.mgt-20x {
  margin-top: 200px
}

.mgt-20-5x {
  margin-top: 205px
}

.mgb-0-5x {
  margin-bottom: 5px !important
}

.mgb-1x {
  margin-bottom: 10px
}

.mgb-1-5x {
  margin-bottom: 15px
}

.mgb-2x {
  margin-bottom: 20px
}

.mgb-2-5x {
  margin-bottom: 25px
}

.mgb-3x {
  margin-bottom: 30px
}

.mgb-3-5x {
  margin-bottom: 35px
}

.mgb-4x {
  margin-bottom: 40px
}

.mgb-4-5x {
  margin-bottom: 45px
}

.mgb-5x {
  margin-bottom: 50px
}

.mgb-5-5x {
  margin-bottom: 55px
}

.mgb-6x {
  margin-bottom: 60px
}

.mgb-6-5x {
  margin-bottom: 65px
}

.mgb-7x {
  margin-bottom: 70px
}

.mgb-7-5x {
  margin-bottom: 75px
}

.mgb-8x {
  margin-bottom: 80px
}

.mgb-8-5x {
  margin-bottom: 85px
}

.mgb-9x {
  margin-bottom: 90px
}

.mgb-9-5x {
  margin-bottom: 95px
}

.mgb-10x {
  margin-bottom: 100px
}

.mgb-10-5x {
  margin-bottom: 105px
}

.mgb-11x {
  margin-bottom: 110px
}

.mgb-11-5x {
  margin-bottom: 115px
}

.mgb-12x {
  margin-bottom: 120px
}

.mgb-12-5x {
  margin-bottom: 125px
}

.mgb-13x {
  margin-bottom: 130px
}

.mgb-13-5x {
  margin-bottom: 135px
}

.mgb-14x {
  margin-bottom: 140px
}

.mgb-14-5x {
  margin-bottom: 145px
}

.mgb-15x {
  margin-bottom: 150px
}

.mgb-15-5x {
  margin-bottom: 155px
}

.mgb-16x {
  margin-bottom: 160px
}

.mgb-16-5x {
  margin-bottom: 165px
}

.mgb-17x {
  margin-bottom: 170px
}

.mgb-17-5x {
  margin-bottom: 175px
}

.mgb-18x {
  margin-bottom: 180px
}

.mgb-18-5x {
  margin-bottom: 185px
}

.mgb-19x {
  margin-bottom: 190px
}

.mgb-19-5x {
  margin-bottom: 195px
}

.mgb-20x {
  margin-bottom: 200px
}

.mgb-20-5x {
  margin-bottom: 205px
}

.mgml-0-5x {
  margin-left: -5px !important
}

.mgml-1x {
  margin-left: -10px
}

.mgml-1-5x {
  margin-left: -15px
}

.mgml-2x {
  margin-left: -20px
}

.mgml-2-5x {
  margin-left: -25px
}

.mgml-3x {
  margin-left: -30px
}

.mgml-3-5x {
  margin-left: -35px
}

.mgml-4x {
  margin-left: -40px
}

.mgml-4-5x {
  margin-left: -45px
}

.mgml-5x {
  margin-left: -50px
}

.mgml-5-5x {
  margin-left: -55px
}

.mgml-6x {
  margin-left: -60px
}

.mgml-6-5x {
  margin-left: -65px
}

.mgml-7x {
  margin-left: -70px
}

.mgml-7-5x {
  margin-left: -75px
}

.mgml-8x {
  margin-left: -80px
}

.mgml-8-5x {
  margin-left: -85px
}

.mgml-9x {
  margin-left: -90px
}

.mgml-9-5x {
  margin-left: -95px
}

.mgml-10x {
  margin-left: -100px
}

.mgml-10-5x {
  margin-left: -105px
}

.mgml-11x {
  margin-left: -110px
}

.mgml-11-5x {
  margin-left: -115px
}

.mgml-12x {
  margin-left: -120px
}

.mgml-12-5x {
  margin-left: -125px
}

.mgml-13x {
  margin-left: -130px
}

.mgml-13-5x {
  margin-left: -135px
}

.mgml-14x {
  margin-left: -140px
}

.mgml-14-5x {
  margin-left: -145px
}

.mgml-15x {
  margin-left: -150px
}

.mgml-15-5x {
  margin-left: -155px
}

.mgml-16x {
  margin-left: -160px
}

.mgml-16-5x {
  margin-left: -165px
}

.mgml-17x {
  margin-left: -170px
}

.mgml-17-5x {
  margin-left: -175px
}

.mgml-18x {
  margin-left: -180px
}

.mgml-18-5x {
  margin-left: -185px
}

.mgml-19x {
  margin-left: -190px
}

.mgml-19-5x {
  margin-left: -195px
}

.mgml-20x {
  margin-left: -200px
}

.mgml-20-5x {
  margin-left: -205px
}

.mgmr-0-5x {
  margin-right: -5px !important
}

.mgmr-1x {
  margin-right: -10px
}

.mgmr-1-5x {
  margin-right: -15px
}

.mgmr-2x {
  margin-right: -20px
}

.mgmr-2-5x {
  margin-right: -25px
}

.mgmr-3x {
  margin-right: -30px
}

.mgmr-3-5x {
  margin-right: -35px
}

.mgmr-4x {
  margin-right: -40px
}

.mgmr-4-5x {
  margin-right: -45px
}

.mgmr-5x {
  margin-right: -50px
}

.mgmr-5-5x {
  margin-right: -55px
}

.mgmr-6x {
  margin-right: -60px
}

.mgmr-6-5x {
  margin-right: -65px
}

.mgmr-7x {
  margin-right: -70px
}

.mgmr-7-5x {
  margin-right: -75px
}

.mgmr-8x {
  margin-right: -80px
}

.mgmr-8-5x {
  margin-right: -85px
}

.mgmr-9x {
  margin-right: -90px
}

.mgmr-9-5x {
  margin-right: -95px
}

.mgmr-10x {
  margin-right: -100px
}

.mgmr-10-5x {
  margin-right: -105px
}

.mgmr-11x {
  margin-right: -110px
}

.mgmr-11-5x {
  margin-right: -115px
}

.mgmr-12x {
  margin-right: -120px
}

.mgmr-12-5x {
  margin-right: -125px
}

.mgmr-13x {
  margin-right: -130px
}

.mgmr-13-5x {
  margin-right: -135px
}

.mgmr-14x {
  margin-right: -140px
}

.mgmr-14-5x {
  margin-right: -145px
}

.mgmr-15x {
  margin-right: -150px
}

.mgmr-15-5x {
  margin-right: -155px
}

.mgmr-16x {
  margin-right: -160px
}

.mgmr-16-5x {
  margin-right: -165px
}

.mgmr-17x {
  margin-right: -170px
}

.mgmr-17-5x {
  margin-right: -175px
}

.mgmr-18x {
  margin-right: -180px
}

.mgmr-18-5x {
  margin-right: -185px
}

.mgmr-19x {
  margin-right: -190px
}

.mgmr-19-5x {
  margin-right: -195px
}

.mgmr-20x {
  margin-right: -200px
}

.mgmr-20-5x {
  margin-right: -205px
}

.mgmt-0-5x {
  margin-top: -5px !important
}

.mgmt-1x {
  margin-top: -10px
}

.mgmt-1-5x {
  margin-top: -15px
}

.mgmt-2x {
  margin-top: -20px
}

.mgmt-2-5x {
  margin-top: -25px
}

.mgmt-3x {
  margin-top: -30px
}

.mgmt-3-5x {
  margin-top: -35px
}

.mgmt-4x {
  margin-top: -40px
}

.mgmt-4-5x {
  margin-top: -45px
}

.mgmt-5x {
  margin-top: -50px
}

.mgmt-5-5x {
  margin-top: -55px
}

.mgmt-6x {
  margin-top: -60px
}

.mgmt-6-5x {
  margin-top: -65px
}

.mgmt-7x {
  margin-top: -70px
}

.mgmt-7-5x {
  margin-top: -75px
}

.mgmt-8x {
  margin-top: -80px
}

.mgmt-8-5x {
  margin-top: -85px
}

.mgmt-9x {
  margin-top: -90px
}

.mgmt-9-5x {
  margin-top: -95px
}

.mgmt-10x {
  margin-top: -100px
}

.mgmt-10-5x {
  margin-top: -105px
}

.mgmt-11x {
  margin-top: -110px
}

.mgmt-11-5x {
  margin-top: -115px
}

.mgmt-12x {
  margin-top: -120px
}

.mgmt-12-5x {
  margin-top: -125px
}

.mgmt-13x {
  margin-top: -130px
}

.mgmt-13-5x {
  margin-top: -135px
}

.mgmt-14x {
  margin-top: -140px
}

.mgmt-14-5x {
  margin-top: -145px
}

.mgmt-15x {
  margin-top: -150px
}

.mgmt-15-5x {
  margin-top: -155px
}

.mgmt-16x {
  margin-top: -160px
}

.mgmt-16-5x {
  margin-top: -165px
}

.mgmt-17x {
  margin-top: -170px
}

.mgmt-17-5x {
  margin-top: -175px
}

.mgmt-18x {
  margin-top: -180px
}

.mgmt-18-5x {
  margin-top: -185px
}

.mgmt-19x {
  margin-top: -190px
}

.mgmt-19-5x {
  margin-top: -195px
}

.mgmt-20x {
  margin-top: -200px
}

.mgmt-20-5x {
  margin-top: -205px
}

.mgmb-0-5x {
  margin-bottom: -5px !important
}

.mgmb-1x {
  margin-bottom: -10px
}

.mgmb-1-5x {
  margin-bottom: -15px
}

.mgmb-2x {
  margin-bottom: -20px
}

.mgmb-2-5x {
  margin-bottom: -25px
}

.mgmb-3x {
  margin-bottom: -30px
}

.mgmb-3-5x {
  margin-bottom: -35px
}

.mgmb-4x {
  margin-bottom: -40px
}

.mgmb-4-5x {
  margin-bottom: -45px
}

.mgmb-5x {
  margin-bottom: -50px
}

.mgmb-5-5x {
  margin-bottom: -55px
}

.mgmb-6x {
  margin-bottom: -60px
}

.mgmb-6-5x {
  margin-bottom: -65px
}

.mgmb-7x {
  margin-bottom: -70px
}

.mgmb-7-5x {
  margin-bottom: -75px
}

.mgmb-8x {
  margin-bottom: -80px
}

.mgmb-8-5x {
  margin-bottom: -85px
}

.mgmb-9x {
  margin-bottom: -90px
}

.mgmb-9-5x {
  margin-bottom: -95px
}

.mgmb-10x {
  margin-bottom: -100px
}

.mgmb-10-5x {
  margin-bottom: -105px
}

.mgmb-11x {
  margin-bottom: -110px
}

.mgmb-11-5x {
  margin-bottom: -115px
}

.mgmb-12x {
  margin-bottom: -120px
}

.mgmb-12-5x {
  margin-bottom: -125px
}

.mgmb-13x {
  margin-bottom: -130px
}

.mgmb-13-5x {
  margin-bottom: -135px
}

.mgmb-14x {
  margin-bottom: -140px
}

.mgmb-14-5x {
  margin-bottom: -145px
}

.mgmb-15x {
  margin-bottom: -150px
}

.mgmb-15-5x {
  margin-bottom: -155px
}

.mgmb-16x {
  margin-bottom: -160px
}

.mgmb-16-5x {
  margin-bottom: -165px
}

.mgmb-17x {
  margin-bottom: -170px
}

.mgmb-17-5x {
  margin-bottom: -175px
}

.mgmb-18x {
  margin-bottom: -180px
}

.mgmb-18-5x {
  margin-bottom: -185px
}

.mgmb-19x {
  margin-bottom: -190px
}

.mgmb-19-5x {
  margin-bottom: -195px
}

.mgmb-20x {
  margin-bottom: -200px
}

.mgmb-20-5x {
  margin-bottom: -205px
}

.wauto {
  width: auto
}

.w-100px,.w-110px,.w-120px,.w-130px,.w-140px,.w-150px {
  flex-grow: 0;
  flex-shrink: 0
}

.w-80px {
  min-width: 80px
}

.w-100px {
  min-width: 100px
}

.w-110px {
  min-width: 110px
}

.w-120px {
  min-width: 120px
}

.w-130px {
  min-width: 130px
}

.w-140px {
  min-width: 140px
}

.w-150px {
  min-width: 150px
}

@media (min-width: 576px) {
  .w-sm-auto {
      width:auto !important
  }
}

@media (min-width: 768px) {
  .w-md-auto {
      width:auto !important
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
      width:auto !important
  }
}

@media (min-width: 1200px) {
  .w-xl-auto {
      width:auto !important
  }
}

.wide-xs {
  width: 410px
}

.wide-sm {
  width: 510px
}

.wide-md {
  width: 690px
}

.wide-lg {
  width: 910px
}

.wide-max-xs {
  max-width: 410px
}

.wide-max-sm {
  max-width: 510px
}

.wide-max-md {
  max-width: 690px
}

.wide-max-lg {
  max-width: 910px
}

.wide-auto {
  margin-left: auto;
  margin-right: auto
}

.wide-5 {
  width: 50px
}

.wide-6 {
  width: 60px
}

.wide-7 {
  width: 70px
}

.wide-8 {
  width: 80px
}

.wide-9 {
  width: 90px
}

.wide-10 {
  width: 100px
}

.wide-11 {
  width: 110px
}

.wide-12 {
  width: 120px
}

.wide-13 {
  width: 130px
}

.wide-14 {
  width: 140px
}

.wide-15 {
  width: 150px
}

.wide-16 {
  width: 160px
}

.wide-17 {
  width: 170px
}

.wide-18 {
  width: 180px
}

.wide-19 {
  width: 190px
}

.wide-20 {
  width: 200px
}

.hauto {
  height: auto
}

.height-100 {
  height: 100%
}

.vh100 {
  height: 100vh
}

.no-shadow {
  box-shadow: none !important
}

.shadow-soft {
  box-shadow: 0px 3px 18px 0px rgba(78,92,110,0.3) !important
}

.level-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.level-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important
}

.hint {
  font-size: .9rem;
  color: #758698
}

.hint-sm {
  font-size: 12px
}

.hint-md {
  font-size: 1.1rem
}

.hint-lg {
  font-size: 1.25rem
}

.hint-primary {
  color: #9b91fe
}

.hint-secondary {
  color: #6b61be
}

.hint-info {
  color: #39b6fe
}

.hint-warning {
  color: #ffc81f
}

.hint-danger {
  color: #ff8787
}

.hint-success {
  color: #00f198
}

.guttar-1px {
  margin-left: -.5px !important;
  margin-right: -.5px !important
}

.guttar-1px>li,.guttar-1px>div {
  padding-left: .5px !important;
  padding-right: .5px !important
}

.guttar-2px {
  margin-left: -1px !important;
  margin-right: -1px !important
}

.guttar-2px>li,.guttar-2px>div {
  padding-left: 1px !important;
  padding-right: 1px !important
}

.guttar-3px {
  margin-left: -1.5px !important;
  margin-right: -1.5px !important
}

.guttar-3px>li,.guttar-3px>div {
  padding-left: 1.5px !important;
  padding-right: 1.5px !important
}

.guttar-4px {
  margin-left: -2px !important;
  margin-right: -2px !important
}

.guttar-4px>li,.guttar-4px>div {
  padding-left: 2px !important;
  padding-right: 2px !important
}

.guttar-5px {
  margin-left: -2.5px !important;
  margin-right: -2.5px !important
}

.guttar-5px>li,.guttar-5px>div {
  padding-left: 2.5px !important;
  padding-right: 2.5px !important
}

.guttar-6px {
  margin-left: -3px !important;
  margin-right: -3px !important
}

.guttar-6px>li,.guttar-6px>div {
  padding-left: 3px !important;
  padding-right: 3px !important
}

.guttar-7px {
  margin-left: -3.5px !important;
  margin-right: -3.5px !important
}

.guttar-7px>li,.guttar-7px>div {
  padding-left: 3.5px !important;
  padding-right: 3.5px !important
}

.guttar-8px {
  margin-left: -4px !important;
  margin-right: -4px !important
}

.guttar-8px>li,.guttar-8px>div {
  padding-left: 4px !important;
  padding-right: 4px !important
}

.guttar-9px {
  margin-left: -4.5px !important;
  margin-right: -4.5px !important
}

.guttar-9px>li,.guttar-9px>div {
  padding-left: 4.5px !important;
  padding-right: 4.5px !important
}

.guttar-10px {
  margin-left: -5px !important;
  margin-right: -5px !important
}

.guttar-10px>li,.guttar-10px>div {
  padding-left: 5px !important;
  padding-right: 5px !important
}

.guttar-11px {
  margin-left: -5.5px !important;
  margin-right: -5.5px !important
}

.guttar-11px>li,.guttar-11px>div {
  padding-left: 5.5px !important;
  padding-right: 5.5px !important
}

.guttar-12px {
  margin-left: -6px !important;
  margin-right: -6px !important
}

.guttar-12px>li,.guttar-12px>div {
  padding-left: 6px !important;
  padding-right: 6px !important
}

.guttar-13px {
  margin-left: -6.5px !important;
  margin-right: -6.5px !important
}

.guttar-13px>li,.guttar-13px>div {
  padding-left: 6.5px !important;
  padding-right: 6.5px !important
}

.guttar-14px {
  margin-left: -7px !important;
  margin-right: -7px !important
}

.guttar-14px>li,.guttar-14px>div {
  padding-left: 7px !important;
  padding-right: 7px !important
}

.guttar-15px {
  margin-left: -7.5px !important;
  margin-right: -7.5px !important
}

.guttar-15px>li,.guttar-15px>div {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important
}

.guttar-16px {
  margin-left: -8px !important;
  margin-right: -8px !important
}

.guttar-16px>li,.guttar-16px>div {
  padding-left: 8px !important;
  padding-right: 8px !important
}

.guttar-17px {
  margin-left: -8.5px !important;
  margin-right: -8.5px !important
}

.guttar-17px>li,.guttar-17px>div {
  padding-left: 8.5px !important;
  padding-right: 8.5px !important
}

.guttar-18px {
  margin-left: -9px !important;
  margin-right: -9px !important
}

.guttar-18px>li,.guttar-18px>div {
  padding-left: 9px !important;
  padding-right: 9px !important
}

.guttar-19px {
  margin-left: -9.5px !important;
  margin-right: -9.5px !important
}

.guttar-19px>li,.guttar-19px>div {
  padding-left: 9.5px !important;
  padding-right: 9.5px !important
}

.guttar-20px {
  margin-left: -10px !important;
  margin-right: -10px !important
}

.guttar-20px>li,.guttar-20px>div {
  padding-left: 10px !important;
  padding-right: 10px !important
}

.guttar-21px {
  margin-left: -10.5px !important;
  margin-right: -10.5px !important
}

.guttar-21px>li,.guttar-21px>div {
  padding-left: 10.5px !important;
  padding-right: 10.5px !important
}

.guttar-22px {
  margin-left: -11px !important;
  margin-right: -11px !important
}

.guttar-22px>li,.guttar-22px>div {
  padding-left: 11px !important;
  padding-right: 11px !important
}

.guttar-23px {
  margin-left: -11.5px !important;
  margin-right: -11.5px !important
}

.guttar-23px>li,.guttar-23px>div {
  padding-left: 11.5px !important;
  padding-right: 11.5px !important
}

.guttar-24px {
  margin-left: -12px !important;
  margin-right: -12px !important
}

.guttar-24px>li,.guttar-24px>div {
  padding-left: 12px !important;
  padding-right: 12px !important
}

.guttar-25px {
  margin-left: -12.5px !important;
  margin-right: -12.5px !important
}

.guttar-25px>li,.guttar-25px>div {
  padding-left: 12.5px !important;
  padding-right: 12.5px !important
}

.guttar-26px {
  margin-left: -13px !important;
  margin-right: -13px !important
}

.guttar-26px>li,.guttar-26px>div {
  padding-left: 13px !important;
  padding-right: 13px !important
}

.guttar-27px {
  margin-left: -13.5px !important;
  margin-right: -13.5px !important
}

.guttar-27px>li,.guttar-27px>div {
  padding-left: 13.5px !important;
  padding-right: 13.5px !important
}

.guttar-28px {
  margin-left: -14px !important;
  margin-right: -14px !important
}

.guttar-28px>li,.guttar-28px>div {
  padding-left: 14px !important;
  padding-right: 14px !important
}

.guttar-29px {
  margin-left: -14.5px !important;
  margin-right: -14.5px !important
}

.guttar-29px>li,.guttar-29px>div {
  padding-left: 14.5px !important;
  padding-right: 14.5px !important
}

.guttar-30px {
  margin-left: -15px !important;
  margin-right: -15px !important
}

.guttar-30px>li,.guttar-30px>div {
  padding-left: 15px !important;
  padding-right: 15px !important
}

.guttar-31px {
  margin-left: -15.5px !important;
  margin-right: -15.5px !important
}

.guttar-31px>li,.guttar-31px>div {
  padding-left: 15.5px !important;
  padding-right: 15.5px !important
}

.guttar-32px {
  margin-left: -16px !important;
  margin-right: -16px !important
}

.guttar-32px>li,.guttar-32px>div {
  padding-left: 16px !important;
  padding-right: 16px !important
}

.guttar-33px {
  margin-left: -16.5px !important;
  margin-right: -16.5px !important
}

.guttar-33px>li,.guttar-33px>div {
  padding-left: 16.5px !important;
  padding-right: 16.5px !important
}

.guttar-34px {
  margin-left: -17px !important;
  margin-right: -17px !important
}

.guttar-34px>li,.guttar-34px>div {
  padding-left: 17px !important;
  padding-right: 17px !important
}

.guttar-35px {
  margin-left: -17.5px !important;
  margin-right: -17.5px !important
}

.guttar-35px>li,.guttar-35px>div {
  padding-left: 17.5px !important;
  padding-right: 17.5px !important
}

.guttar-36px {
  margin-left: -18px !important;
  margin-right: -18px !important
}

.guttar-36px>li,.guttar-36px>div {
  padding-left: 18px !important;
  padding-right: 18px !important
}

.guttar-37px {
  margin-left: -18.5px !important;
  margin-right: -18.5px !important
}

.guttar-37px>li,.guttar-37px>div {
  padding-left: 18.5px !important;
  padding-right: 18.5px !important
}

.guttar-38px {
  margin-left: -19px !important;
  margin-right: -19px !important
}

.guttar-38px>li,.guttar-38px>div {
  padding-left: 19px !important;
  padding-right: 19px !important
}

.guttar-39px {
  margin-left: -19.5px !important;
  margin-right: -19.5px !important
}

.guttar-39px>li,.guttar-39px>div {
  padding-left: 19.5px !important;
  padding-right: 19.5px !important
}

.guttar-40px {
  margin-left: -20px !important;
  margin-right: -20px !important
}

.guttar-40px>li,.guttar-40px>div {
  padding-left: 20px !important;
  padding-right: 20px !important
}

.guttar-41px {
  margin-left: -20.5px !important;
  margin-right: -20.5px !important
}

.guttar-41px>li,.guttar-41px>div {
  padding-left: 20.5px !important;
  padding-right: 20.5px !important
}

.guttar-42px {
  margin-left: -21px !important;
  margin-right: -21px !important
}

.guttar-42px>li,.guttar-42px>div {
  padding-left: 21px !important;
  padding-right: 21px !important
}

.guttar-43px {
  margin-left: -21.5px !important;
  margin-right: -21.5px !important
}

.guttar-43px>li,.guttar-43px>div {
  padding-left: 21.5px !important;
  padding-right: 21.5px !important
}

.guttar-44px {
  margin-left: -22px !important;
  margin-right: -22px !important
}

.guttar-44px>li,.guttar-44px>div {
  padding-left: 22px !important;
  padding-right: 22px !important
}

.guttar-45px {
  margin-left: -22.5px !important;
  margin-right: -22.5px !important
}

.guttar-45px>li,.guttar-45px>div {
  padding-left: 22.5px !important;
  padding-right: 22.5px !important
}

.guttar-46px {
  margin-left: -23px !important;
  margin-right: -23px !important
}

.guttar-46px>li,.guttar-46px>div {
  padding-left: 23px !important;
  padding-right: 23px !important
}

.guttar-47px {
  margin-left: -23.5px !important;
  margin-right: -23.5px !important
}

.guttar-47px>li,.guttar-47px>div {
  padding-left: 23.5px !important;
  padding-right: 23.5px !important
}

.guttar-48px {
  margin-left: -24px !important;
  margin-right: -24px !important
}

.guttar-48px>li,.guttar-48px>div {
  padding-left: 24px !important;
  padding-right: 24px !important
}

.guttar-49px {
  margin-left: -24.5px !important;
  margin-right: -24.5px !important
}

.guttar-49px>li,.guttar-49px>div {
  padding-left: 24.5px !important;
  padding-right: 24.5px !important
}

.guttar-50px {
  margin-left: -25px !important;
  margin-right: -25px !important
}

.guttar-50px>li,.guttar-50px>div {
  padding-left: 25px !important;
  padding-right: 25px !important
}

.guttar-vr-1px {
  margin-top: -.5px !important;
  margin-bottom: -.5px !important
}

.guttar-vr-1px>li,.guttar-vr-1px>div {
  padding-top: .5px !important;
  padding-bottom: .5px !important
}

.guttar-vr-2px {
  margin-top: -1px !important;
  margin-bottom: -1px !important
}

.guttar-vr-2px>li,.guttar-vr-2px>div {
  padding-top: 1px !important;
  padding-bottom: 1px !important
}

.guttar-vr-3px {
  margin-top: -1.5px !important;
  margin-bottom: -1.5px !important
}

.guttar-vr-3px>li,.guttar-vr-3px>div {
  padding-top: 1.5px !important;
  padding-bottom: 1.5px !important
}

.guttar-vr-4px {
  margin-top: -2px !important;
  margin-bottom: -2px !important
}

.guttar-vr-4px>li,.guttar-vr-4px>div {
  padding-top: 2px !important;
  padding-bottom: 2px !important
}

.guttar-vr-5px {
  margin-top: -2.5px !important;
  margin-bottom: -2.5px !important
}

.guttar-vr-5px>li,.guttar-vr-5px>div {
  padding-top: 2.5px !important;
  padding-bottom: 2.5px !important
}

.guttar-vr-6px {
  margin-top: -3px !important;
  margin-bottom: -3px !important
}

.guttar-vr-6px>li,.guttar-vr-6px>div {
  padding-top: 3px !important;
  padding-bottom: 3px !important
}

.guttar-vr-7px {
  margin-top: -3.5px !important;
  margin-bottom: -3.5px !important
}

.guttar-vr-7px>li,.guttar-vr-7px>div {
  padding-top: 3.5px !important;
  padding-bottom: 3.5px !important
}

.guttar-vr-8px {
  margin-top: -4px !important;
  margin-bottom: -4px !important
}

.guttar-vr-8px>li,.guttar-vr-8px>div {
  padding-top: 4px !important;
  padding-bottom: 4px !important
}

.guttar-vr-9px {
  margin-top: -4.5px !important;
  margin-bottom: -4.5px !important
}

.guttar-vr-9px>li,.guttar-vr-9px>div {
  padding-top: 4.5px !important;
  padding-bottom: 4.5px !important
}

.guttar-vr-10px {
  margin-top: -5px !important;
  margin-bottom: -5px !important
}

.guttar-vr-10px>li,.guttar-vr-10px>div {
  padding-top: 5px !important;
  padding-bottom: 5px !important
}

.guttar-vr-11px {
  margin-top: -5.5px !important;
  margin-bottom: -5.5px !important
}

.guttar-vr-11px>li,.guttar-vr-11px>div {
  padding-top: 5.5px !important;
  padding-bottom: 5.5px !important
}

.guttar-vr-12px {
  margin-top: -6px !important;
  margin-bottom: -6px !important
}

.guttar-vr-12px>li,.guttar-vr-12px>div {
  padding-top: 6px !important;
  padding-bottom: 6px !important
}

.guttar-vr-13px {
  margin-top: -6.5px !important;
  margin-bottom: -6.5px !important
}

.guttar-vr-13px>li,.guttar-vr-13px>div {
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important
}

.guttar-vr-14px {
  margin-top: -7px !important;
  margin-bottom: -7px !important
}

.guttar-vr-14px>li,.guttar-vr-14px>div {
  padding-top: 7px !important;
  padding-bottom: 7px !important
}

.guttar-vr-15px {
  margin-top: -7.5px !important;
  margin-bottom: -7.5px !important
}

.guttar-vr-15px>li,.guttar-vr-15px>div {
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important
}

.guttar-vr-16px {
  margin-top: -8px !important;
  margin-bottom: -8px !important
}

.guttar-vr-16px>li,.guttar-vr-16px>div {
  padding-top: 8px !important;
  padding-bottom: 8px !important
}

.guttar-vr-17px {
  margin-top: -8.5px !important;
  margin-bottom: -8.5px !important
}

.guttar-vr-17px>li,.guttar-vr-17px>div {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important
}

.guttar-vr-18px {
  margin-top: -9px !important;
  margin-bottom: -9px !important
}

.guttar-vr-18px>li,.guttar-vr-18px>div {
  padding-top: 9px !important;
  padding-bottom: 9px !important
}

.guttar-vr-19px {
  margin-top: -9.5px !important;
  margin-bottom: -9.5px !important
}

.guttar-vr-19px>li,.guttar-vr-19px>div {
  padding-top: 9.5px !important;
  padding-bottom: 9.5px !important
}

.guttar-vr-20px {
  margin-top: -10px !important;
  margin-bottom: -10px !important
}

.guttar-vr-20px>li,.guttar-vr-20px>div {
  padding-top: 10px !important;
  padding-bottom: 10px !important
}

.guttar-vr-21px {
  margin-top: -10.5px !important;
  margin-bottom: -10.5px !important
}

.guttar-vr-21px>li,.guttar-vr-21px>div {
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important
}

.guttar-vr-22px {
  margin-top: -11px !important;
  margin-bottom: -11px !important
}

.guttar-vr-22px>li,.guttar-vr-22px>div {
  padding-top: 11px !important;
  padding-bottom: 11px !important
}

.guttar-vr-23px {
  margin-top: -11.5px !important;
  margin-bottom: -11.5px !important
}

.guttar-vr-23px>li,.guttar-vr-23px>div {
  padding-top: 11.5px !important;
  padding-bottom: 11.5px !important
}

.guttar-vr-24px {
  margin-top: -12px !important;
  margin-bottom: -12px !important
}

.guttar-vr-24px>li,.guttar-vr-24px>div {
  padding-top: 12px !important;
  padding-bottom: 12px !important
}

.guttar-vr-25px {
  margin-top: -12.5px !important;
  margin-bottom: -12.5px !important
}

.guttar-vr-25px>li,.guttar-vr-25px>div {
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important
}

.guttar-vr-26px {
  margin-top: -13px !important;
  margin-bottom: -13px !important
}

.guttar-vr-26px>li,.guttar-vr-26px>div {
  padding-top: 13px !important;
  padding-bottom: 13px !important
}

.guttar-vr-27px {
  margin-top: -13.5px !important;
  margin-bottom: -13.5px !important
}

.guttar-vr-27px>li,.guttar-vr-27px>div {
  padding-top: 13.5px !important;
  padding-bottom: 13.5px !important
}

.guttar-vr-28px {
  margin-top: -14px !important;
  margin-bottom: -14px !important
}

.guttar-vr-28px>li,.guttar-vr-28px>div {
  padding-top: 14px !important;
  padding-bottom: 14px !important
}

.guttar-vr-29px {
  margin-top: -14.5px !important;
  margin-bottom: -14.5px !important
}

.guttar-vr-29px>li,.guttar-vr-29px>div {
  padding-top: 14.5px !important;
  padding-bottom: 14.5px !important
}

.guttar-vr-30px {
  margin-top: -15px !important;
  margin-bottom: -15px !important
}

.guttar-vr-30px>li,.guttar-vr-30px>div {
  padding-top: 15px !important;
  padding-bottom: 15px !important
}

.guttar-vr-31px {
  margin-top: -15.5px !important;
  margin-bottom: -15.5px !important
}

.guttar-vr-31px>li,.guttar-vr-31px>div {
  padding-top: 15.5px !important;
  padding-bottom: 15.5px !important
}

.guttar-vr-32px {
  margin-top: -16px !important;
  margin-bottom: -16px !important
}

.guttar-vr-32px>li,.guttar-vr-32px>div {
  padding-top: 16px !important;
  padding-bottom: 16px !important
}

.guttar-vr-33px {
  margin-top: -16.5px !important;
  margin-bottom: -16.5px !important
}

.guttar-vr-33px>li,.guttar-vr-33px>div {
  padding-top: 16.5px !important;
  padding-bottom: 16.5px !important
}

.guttar-vr-34px {
  margin-top: -17px !important;
  margin-bottom: -17px !important
}

.guttar-vr-34px>li,.guttar-vr-34px>div {
  padding-top: 17px !important;
  padding-bottom: 17px !important
}

.guttar-vr-35px {
  margin-top: -17.5px !important;
  margin-bottom: -17.5px !important
}

.guttar-vr-35px>li,.guttar-vr-35px>div {
  padding-top: 17.5px !important;
  padding-bottom: 17.5px !important
}

.guttar-vr-36px {
  margin-top: -18px !important;
  margin-bottom: -18px !important
}

.guttar-vr-36px>li,.guttar-vr-36px>div {
  padding-top: 18px !important;
  padding-bottom: 18px !important
}

.guttar-vr-37px {
  margin-top: -18.5px !important;
  margin-bottom: -18.5px !important
}

.guttar-vr-37px>li,.guttar-vr-37px>div {
  padding-top: 18.5px !important;
  padding-bottom: 18.5px !important
}

.guttar-vr-38px {
  margin-top: -19px !important;
  margin-bottom: -19px !important
}

.guttar-vr-38px>li,.guttar-vr-38px>div {
  padding-top: 19px !important;
  padding-bottom: 19px !important
}

.guttar-vr-39px {
  margin-top: -19.5px !important;
  margin-bottom: -19.5px !important
}

.guttar-vr-39px>li,.guttar-vr-39px>div {
  padding-top: 19.5px !important;
  padding-bottom: 19.5px !important
}

.guttar-vr-40px {
  margin-top: -20px !important;
  margin-bottom: -20px !important
}

.guttar-vr-40px>li,.guttar-vr-40px>div {
  padding-top: 20px !important;
  padding-bottom: 20px !important
}

.guttar-vr-41px {
  margin-top: -20.5px !important;
  margin-bottom: -20.5px !important
}

.guttar-vr-41px>li,.guttar-vr-41px>div {
  padding-top: 20.5px !important;
  padding-bottom: 20.5px !important
}

.guttar-vr-42px {
  margin-top: -21px !important;
  margin-bottom: -21px !important
}

.guttar-vr-42px>li,.guttar-vr-42px>div {
  padding-top: 21px !important;
  padding-bottom: 21px !important
}

.guttar-vr-43px {
  margin-top: -21.5px !important;
  margin-bottom: -21.5px !important
}

.guttar-vr-43px>li,.guttar-vr-43px>div {
  padding-top: 21.5px !important;
  padding-bottom: 21.5px !important
}

.guttar-vr-44px {
  margin-top: -22px !important;
  margin-bottom: -22px !important
}

.guttar-vr-44px>li,.guttar-vr-44px>div {
  padding-top: 22px !important;
  padding-bottom: 22px !important
}

.guttar-vr-45px {
  margin-top: -22.5px !important;
  margin-bottom: -22.5px !important
}

.guttar-vr-45px>li,.guttar-vr-45px>div {
  padding-top: 22.5px !important;
  padding-bottom: 22.5px !important
}

.guttar-vr-46px {
  margin-top: -23px !important;
  margin-bottom: -23px !important
}

.guttar-vr-46px>li,.guttar-vr-46px>div {
  padding-top: 23px !important;
  padding-bottom: 23px !important
}

.guttar-vr-47px {
  margin-top: -23.5px !important;
  margin-bottom: -23.5px !important
}

.guttar-vr-47px>li,.guttar-vr-47px>div {
  padding-top: 23.5px !important;
  padding-bottom: 23.5px !important
}

.guttar-vr-48px {
  margin-top: -24px !important;
  margin-bottom: -24px !important
}

.guttar-vr-48px>li,.guttar-vr-48px>div {
  padding-top: 24px !important;
  padding-bottom: 24px !important
}

.guttar-vr-49px {
  margin-top: -24.5px !important;
  margin-bottom: -24.5px !important
}

.guttar-vr-49px>li,.guttar-vr-49px>div {
  padding-top: 24.5px !important;
  padding-bottom: 24.5px !important
}

.guttar-vr-50px {
  margin-top: -25px !important;
  margin-bottom: -25px !important
}

.guttar-vr-50px>li,.guttar-vr-50px>div {
  padding-top: 25px !important;
  padding-bottom: 25px !important
}

@media (min-width: 410px) and (max-width: 575px) {
  .col-mb {
      flex-basis:0;
      flex-grow: 1;
      max-width: 100%
  }

  .col-mb-1 {
      max-width: 8.3333333333%;
      flex: 0 0 8.3333333333%
  }

  .col-mb-2 {
      max-width: 16.6666666667%;
      flex: 0 0 16.6666666667%
  }

  .col-mb-3 {
      max-width: 25%;
      flex: 0 0 25%
  }

  .col-mb-4 {
      max-width: 33.3333333333%;
      flex: 0 0 33.3333333333%
  }

  .col-mb-5 {
      max-width: 41.6666666667%;
      flex: 0 0 41.6666666667%
  }

  .col-mb-6 {
      max-width: 50%;
      flex: 0 0 50%
  }

  .col-mb-7 {
      max-width: 58.3333333333%;
      flex: 0 0 58.3333333333%
  }

  .col-mb-8 {
      max-width: 66.6666666667%;
      flex: 0 0 66.6666666667%
  }

  .col-mb-9 {
      max-width: 75%;
      flex: 0 0 75%
  }

  .col-mb-10 {
      max-width: 83.3333333333%;
      flex: 0 0 83.3333333333%
  }

  .col-mb-11 {
      max-width: 91.6666666667%;
      flex: 0 0 91.6666666667%
  }

  .col-mb-12 {
      max-width: 100%;
      flex: 0 0 100%
  }

  .justify-content-mb-start {
      justify-content: flex-start !important
  }

  .justify-content-mb-center {
      justify-content: center !important
  }

  .justify-content-mb-end {
      justify-content: flex-end !important
  }

  .justify-content-mb-around {
      justify-content: space-around !important
  }

  .justify-content-mb-bewteen {
      justify-content: space-between !important
  }

  .align-items-mb-start {
      align-items: flex-start !important
  }

  .align-items-mb-center {
      align-items: center !important
  }

  .align-items-mb-end {
      align-items: flex-end !important
  }

  .align-items-mb-around {
      align-items: space-around !important
  }

  .align-items-mb-bewteen {
      align-items: space-between !important
  }
}

.disabled-addon,.disabled-block {
  opacity: .4;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none
}

.topbar {
  background: #242650;
  position: relative;
  z-index: 1003
}

.topbar.has-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0
}

.is-sticky {
  position: sticky;
  top: 0;
  z-index: 1003;
}

.topbar-wrap {
  background: #fff;
  margin-bottom: 16px
}

.topbar-logo {
  padding: 13px 0
}

.topbar-logo img {
  height: 30px
}

.topbar-nav {
  display: flex;
  align-items: center;
  margin: 0 -10px
}

.topbar-nav-item {
  display: inline-flex;
  align-items: center;
  padding: 11px 0;
  margin: 0 10px
}

.topbar-nav-item .dropdown-content {
  right: -8px
}

@media (max-width: 991px) {
  .topbar .container {
      min-width:100% !important
  }
}

.navbar {
  position: fixed;
  /* left: -290px; */
  top: 0;
  height: 100vh;
  width: 260px;
  z-index: 2;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 4px 35px 0px rgba(0,0,0,0.1);
  transition: all .5s;
  /* display: none; */
  padding: 0
}

.navbar-innr {
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.navbar.active {
  left: 0
}

.navbar-mobile {
  display: block;
  overflow-y: auto
}

.navbar-menu {
  margin-bottom: 20px;
  padding-top: 70px
}

.navbar-menu>li {
  position: relative
}

.navbar-menu>li>a {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 32px;
  padding: 10px 0;
  position: relative;
  color: #00d285
}

.navbar-menu>li>a:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  opacity: 0;
  width: 100%;
  content: '';
  background: #00d285;
  transition: all .3s
}

.navbar-menu>li>a:hover,.navbar-menu>li>a:focus,.navbar-menu>li>a:active {
  color: #00d285
}

.navbar-menu>li .ikon {
  font-size: 28px;
  margin-right: 4px;
  margin-left: -3px
}

.navbar-menu>li .menu-icon {
  font-size: 24px;
  margin-right: 10px
}

.navbar-menu>li.active a:before {
  opacity: 1
}

.navbar-menu>li:hover a:before,.navbar-menu>li:focus a:before,.navbar-menu>li:active a:before {
  opacity: 1
}

.navbar-dropdown {
  position: relative;
  min-width: 215px;
  background: #fff;
  padding: 10px 0;
  display: none
}

.navbar-dropdown li a {
  padding: 10px 25px 10px 34px;
  line-height: 20px;
  display: block;
  position: relative;
  color: #00d285
}

.navbar-dropdown li a .badge {
  padding: 0 7px;
  min-width: 0;
  margin-left: 6px;
  font-size: 10px;
  border-radius: 2px;
  text-transform: uppercase
}

.navbar-dropdown li.active>a,.navbar-dropdown li.current>a,.navbar-dropdown li:hover>a {
  color: #2b265c
}

.navbar-dropdown .navbar-dropdown {
  padding: 0
}

.navbar-dropdown .navbar-dropdown li a {
  padding: 10px 25px 10px 50px
}

.navbar-dropdown .navbar-dropdown li a:before {
  left: 34px
}

.navbar-dropdown .navbar-dropdown:after {
  display: none
}

.navbar-dropdown:after {
  position: absolute;
  left: 0;
  top: -2px;
  height: 2px;
  width: 100%;
  content: '';
  background: #00d285
}

.navbar-dropdown .has-dropdown>a:after {
  right: 15px
}

.navbar-btns {
  margin-bottom: 30px
}

.has-dropdown>a {
  padding-right: 20px !important;
  position: relative
}

.has-dropdown>a:after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  content: '\e64b';
  font-family: 'themify';
  font-size: 9px;
  font-weight: 700;
  transition: all .4s ease
}

.has-dropdown.current>a:after {
  transform: translateY(-50%) rotate(-180deg)
}

@media (min-width: 576px) {
  .topbar {
      padding-left:20px;
      padding-right: 20px
  }

  .topbar-wrap {
      margin-bottom: 30px
  }
}

@media (min-width: 992px) {
  .topbar-wrap {
      margin-bottom:50px
  }

  .topbar-logo img {
      height: 40px
  }

  .topbar-nav-item {
      padding: 17px 0
  }

  .navbar {
      position: relative;
      left: 0;
      top: 0;
      display: block;
      min-height: auto;
      width: 100%;
      padding: 0;
      transition: all 0s;
      height: auto
  }

  .navbar-innr {
      display: flex
  }

  .navbar-menu {
      display: flex;
      margin: 0 -9px;
      padding-top: 0
  }

  .navbar-menu>li {
      padding: 0 9px
  }

  .navbar-menu>li a {
      padding: 12px 0
  }

  .navbar-menu li {
      position: relative
  }

  .navbar-menu li:hover>.navbar-dropdown {
      visibility: visible;
      opacity: 1;
      z-index: 29
  }

  .navbar-btns {
      display: flex;
      margin: 0 -10px
  }

  .navbar-btns li {
      padding: 0 10px
  }

  .navbar-dropdown {
      position: absolute;
      left: 15px;
      top: 100%;
      background: #fff;
      box-shadow: 0px 4px 35px 0px rgba(0,0,0,0.1);
      visibility: hidden;
      opacity: 0;
      transition: all .3s ease;
      display: block !important;
      z-index: 9
  }

  .navbar-dropdown li a {
      font-size: 13px;
      padding: 10px 25px
  }

  .navbar-dropdown li a:before {
      display: none
  }

  .navbar-dropdown .navbar-dropdown {
      left: 100%;
      top: -10px;
      padding: 10px 0
  }

  .navbar-dropdown .navbar-dropdown li a {
      padding: 10px 25px
  }

  .navbar-dropdown .has-dropdown>a:after {
      transform: translateY(-50%) rotate(-90deg)
  }

  .has-dropdown>a:after {
      right: 0
  }
}

@media (min-width: 1200px) {
  .navbar-menu {
      margin:0 -14px
  }

  .navbar-menu>li {
      padding: 0 14px
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .navbar-menu li:last-child:not(:nth-child(1)) .navbar-dropdown,.navbar-menu li:last-child:not(:nth-child(2)) .navbar-dropdown,.navbar-menu li:last-child:not(:nth-child(3)) .navbar-dropdown,.navbar-menu li:last-child:not(:nth-child(4)) .navbar-dropdown {
      left:auto;
      right: 9px
  }

  .navbar-menu li:last-child:not(:nth-child(1)) .navbar-dropdown .navbar-dropdown,.navbar-menu li:last-child:not(:nth-child(2)) .navbar-dropdown .navbar-dropdown,.navbar-menu li:last-child:not(:nth-child(3)) .navbar-dropdown .navbar-dropdown,.navbar-menu li:last-child:not(:nth-child(4)) .navbar-dropdown .navbar-dropdown {
      left: auto;
      right: 100%
  }

  .navbar-menu li:last-child:not(:nth-child(1)) .navbar-dropdown .has-dropdown>a:after,.navbar-menu li:last-child:not(:nth-child(2)) .navbar-dropdown .has-dropdown>a:after,.navbar-menu li:last-child:not(:nth-child(3)) .navbar-dropdown .has-dropdown>a:after,.navbar-menu li:last-child:not(:nth-child(4)) .navbar-dropdown .has-dropdown>a:after {
      transform: translateY(-50%) rotate(90deg)
  }

  .navbar-menu li a {
      font-size: 13px
  }
}

.toggle-nav {
  width: 32px;
  height: 32px;
  display: block;
  transition: all .4s
}

.toggle-nav {
  left: 10px
}

.toggle-nav.active .toggle-line {
  width: 30px
}

.toggle-nav.active .toggle-line:nth-last-of-type(1) {
  transform-origin: bottom left;
  transform: rotate(-45deg);
  bottom: -1px
}

.toggle-nav.active .toggle-line:nth-last-of-type(2) {
  opacity: 0
}

.toggle-nav.active .toggle-line:nth-last-of-type(3) {
  opacity: 0
}

.toggle-nav.active .toggle-line:nth-last-of-type(4) {
  transform-origin: top left;
  transform: rotate(45deg);
  top: -1px
}

.toggle-line {
  position: relative;
  display: block;
  width: 28px;
  height: 1px;
  line-height: 0;
  background: #fff;
  margin: 5.5px 0;
  transition: all .4s
}

.toggle-line:nth-last-of-type(2) {
  width: 70%
}

.toggle-line:nth-last-of-type(3) {
  width: 85%
}

.page-header {
  padding: 10px 0 20px
}

.page-header-kyc {
  padding-top: 14px;
  padding-bottom: 25px
}

.page-header-kyc div[class*=col-] {
  padding-left: 30px;
  padding-right: 30px
}

.page-header-kyc .page-title {
  font-weight: 400
}

.page-header-kyc p {
  font-size: 1em
}

.page-title {
  color: #342d6e;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-bottom: 7px
}

.page-user {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column
}

.page-ath-wrap {
  display: flex;
  min-height: 100vh
}

.page-ath-alt .page-ath-wrap {
  background-color: transparent
}

.page-ath-content {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.page-ath-alt .page-ath-content {
  width: 100% !important;
  background-color: transparent;
  justify-content: center
}

.page-ath-heading {
  font-size: 1.8em;
  font-weight: 300;
  letter-spacing: -0.025em;
  color: #342d6e;
  line-height: 1.2;
  padding-bottom: 15px
}

.page-ath-heading small {
  display: block;
  font-weight: 400;
  font-size: 0.66em;
  color: #495463;
  letter-spacing: normal;
  margin-top: 10px
}

.page-ath-heading span {
  display: block;
  font-weight: 400;
  font-size: 0.61em;
  color: #495463;
  letter-spacing: normal;
  line-height: 1.62;
  padding-top: 15px
}

.page-ath-form,.page-ath-header,.page-ath-footer,.page-ath-text {
  width: 440px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px
}

.page-ath-form {
  padding-top: 40px;
  padding-bottom: 50px
}

.page-ath-alt .page-ath-form {
  background: #fff;
  border-radius: 6px;
  padding: 35px 40px;
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.05)
}

.page-ath-header {
  padding-top: 40px
}

.page-ath-alt .page-ath-header {
  padding-bottom: 30px
}

.page-ath-footer {
  padding-bottom: 30px
}

.page-ath-alt .page-ath-footer {
  padding-top: 20px
}

.page-ath-alt .page-ath-header,.page-ath-alt .page-ath-footer {
  text-align: center
}

.page-ath-alt .page-ath-header .footer-links,.page-ath-alt .page-ath-footer .footer-links {
  justify-content: center
}

.page-ath-logo img {
  max-height: 100px;
  max-width: 220px
}

.page-ath-gfx {
  background-size: cover;
  background-position: 50% 50%;
  display: none
}

.page-ath-alt .page-ath-gfx {
  display: none !important
}

.page-ath-alt.bg-secondary {
  background-color: #242650 !important
}

.page-ath-alt.bg-secondary .copyright-text,.page-ath-alt.bg-secondary .footer-links li,.page-ath-alt.bg-secondary .footer-links a {
  color: #b9d2f2
}

.page-ath-alt.bg-secondary .footer-links a:hover {
  text-decoration: underline;
  color: #fff
}

.page-ath-alt.bg-secondary .socials li a {
  color: #d2dde9;
  border-color: #d2dde9
}

.page-ath-alt.bg-secondary .socials li a:hover {
  background-color: #d2dde9;
  border-color: #d2dde9;
  color: #342d6e
}

.page-intro {
  position: relative;
  background: #fff
}

.page-intro-side {
  padding: 50px 25px;
  background-color: #fff;
  transition: all .4s
}

.page-intro-title {
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 25px
}

.page-intro-title span {
  display: block
}

.page-intro-title small {
  font-weight: 500;
  border-top: 1px solid #d2dde9;
  padding-top: 12px;
  margin-top: 12px;
  display: inline-block;
  letter-spacing: 0.1em
}

.page-intro-notice {
  margin-top: 35px
}

.page-intro-notice a {
  color: currentColor;
  text-decoration: underline
}

.page-intro-notice a:hover {
  color: currentColor;
  text-decoration: none;
  box-shadow: none
}

.page-intro-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #342d6e;
  border-bottom: 1px solid rgba(118,104,254,0.25)
}

.page-intro-nav+div {
  margin-top: 76px
}

.page-intro-nav .btn {
  padding: 6px 15px
}

.page-intro-logo {
  max-width: 150px;
  max-height: 80px
}

.page-intro .btn-lg {
  padding: 15px 20px;
  min-width: 220px;
  font-size: 16px
}

@media (min-width: 576px) {
  .page-header {
      padding:15px 0 45px
  }

  .page-header-kyc .page-title {
      font-weight: 300;
      margin-bottom: 12px
  }

  .page-header-kyc p {
      font-size: 1.2em
  }

  .page-title {
      font-size: 2.57em
  }

  .page-ath-heading {
      padding-bottom: 23px;
      font-size: 2.8em
  }

  .page-ath-heading small {
      font-size: 0.46em
  }

  .page-ath-heading span {
      font-size: 0.41em
  }

  .page-intro-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 50vh
  }

  .page-intro .text-block {
      padding: 30px 0
  }

  .page-intro-nav .btn {
      padding: 10px 25px
  }

  .page-intro-logo {
      max-width: 180px;
      max-height: 90px
  }
}

@media (min-width: 768px) {
  .page-ath-content {
      width:50%
  }

  .page-ath-gfx {
      width: 50%;
      display: flex;
      align-items: center
  }
}

@media (min-width: 992px) {
  .page-header {
      padding:15px 0 60px
  }

  .page-intro-split {
      display: flex;
      flex-wrap: wrap
  }

  .page-intro-side {
      padding: 50px;
      width: 50%;
      min-height: 100vh
  }

  .page-intro-title {
      font-size: 1.75rem
  }

  .page-intro-nav {
      position: absolute;
      width: 100%;
      padding: 30px 45px 0;
      background-color: transparent;
      border-bottom-color: transparent
  }

  .page-intro-nav+div {
      margin-top: 0
  }

  .page-intro-nav .btn {
      min-width: 150px
  }

  .page-intro-notice {
      position: absolute;
      left: 50%;
      bottom: 6vh;
      transform: translateX(-50%);
      border-radius: 25px;
      padding: 10px 25px;
      color: #fff !important;
      transition: background .3s;
      background: rgba(118,104,254,0.85)
  }

  .page-intro-notice:hover {
      background: rgba(95,79,254,0.95)
  }
}

@media (min-width: 1200px) {
  .page-ath-content {
      width:38%
  }

  .page-ath-gfx {
      width: 62%
  }
}

.content-area .card-head {
  padding-bottom: 10px
}

@media (min-width: 768px) {
  .content-area-mh {
      min-height:675px
  }
}

.sidebar-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px
}

.sidebar-nav li {
  padding: 0 12px;
  width: 50%;
  flex-shrink: 0
}

.sidebar-nav li a {
  display: flex;
  align-items: center;
  line-height: 28px;
  color: #495463;
  padding: 4px 0;
  font-size: 12px
}

.sidebar-nav li a:hover,.sidebar-nav li a.active {
  color: #00d285
}

.sidebar-nav li a .ikon,.sidebar-nav li a .ti,.sidebar-nav li a .icon {
  font-size: 24px;
  margin-right: 4px;
  color: #00d285
}

.sidebar-nav li a .ikon {
  font-size: 22px
}

.sidebar-nav li.active>a {
  color: #00d285
}

@media (min-width: 375px) {
  .sidebar-nav li {
      width:auto
  }
}

@media (min-width: 576px) {
  .sidebar-nav {
      margin:0 -20px
  }

  .sidebar-nav li {
      padding: 0 20px
  }

  .sidebar-nav li a {
      font-size: inherit
  }

  .sidebar-nav li a .ikon,.sidebar-nav li a .ti,.sidebar-nav li a .icon {
      font-size: 30px;
      margin-right: 6px
  }

  .sidebar-nav li a .ikon {
      font-size: 28px
  }
}

@media (min-width: 992px) {
  .sidebar-nav {
      display:block
  }
}

.navbar {
  box-shadow: 0 1px 3px rgba(24,30,43,0.07)
}

.token-balance-icon img {
  width: 32px
}

/*! Bootstrap Overide */
.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle {
  background-color: #311cfd;
  border-color: #311cfd
}

.btn-dark:not(:disabled):not(.disabled).active,.btn-dark:not(:disabled):not(.disabled):active,.show>.btn-dark.dropdown-toggle {
  background-color: #8397ae;
  border-color: #8397ae
}

.btn-success:not(:disabled):not(.disabled).active,.btn-success:not(:disabled):not(.disabled):active,.show>.btn-success.dropdown-toggle {
  background-color: #009f65;
  border-color: #009f65
}

.btn-light:not(:disabled):not(.disabled).active,.btn-light:not(:disabled):not(.disabled):active,.show>.btn-light.dropdown-toggle {
  background-color: #52606e;
  border-color: #52606e;
  color: #fff
}

.btn-dark:not(:disabled):not(.disabled).active,.btn-dark:not(:disabled):not(.disabled):active,.show>.btn-dark.dropdown-toggle {
  background-color: #333b46;
  border-color: #333b46
}

.btn-secondary:not(:disabled):not(.disabled).active,.btn-secondary:not(:disabled):not(.disabled):active,.show>.btn-secondary.dropdown-toggle {
  background-color: #231e4a;
  border-color: #231e4a
}

.btn-warning:not(:disabled):not(.disabled).active,.btn-warning:not(:disabled):not(.disabled):active,.show>.btn-warning.dropdown-toggle {
  background-color: #cc9a00;
  border-color: #cc9a00;
  color: #fff
}

.btn-danger:not(:disabled):not(.disabled).active,.btn-danger:not(:disabled):not(.disabled):active,.show>.btn-danger.dropdown-toggle {
  background-color: #ff1c1c;
  border-color: #ff1c1c
}

.demo-element .guttar-vr-5px+.hr {
  margin-bottom: 30px
}

@media (max-width: 575px) {
  .demo-element .btn {
      min-width:110px
  }
}

.ant-message{
  z-index: 999999;
}

.copy-wallet{
  font-size: 14px !important;
  font-weight: 600;
  padding-left: 20px;
}

.disabled{
  pointer-events: none !important;
}
